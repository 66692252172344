import React, { useState } from "react";
import { useEffect } from "react";

import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../../../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CaesarAILogo from  "../../CaesarAILogo 2.svg"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import { Download } from "@mui/icons-material";
export default function CaesarAIVideos(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const navigate =  useNavigate();
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const [getVideo,setGetVideo] = useState(true)
    const [message,setMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [playlistLink,setPlaylistLink] = useState(`https://www.youtube.com/playlist?list=${searchParams.get("list")}`)
    const [videofeed,setVideoFeed] = useState([])
    const [sendingmessage,setSendingMessage] = useState(false)
    const [revert,setRevert] = useState(false)
    const [downloading,setDownloading] = useState(false)
    const [currentDownload,setCurrentDownload] = useState(0);
    const get_video_links = async () =>{
        const response = await axios.get(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getplaylistvideos?url=${playlistLink}`)
        let videos = response.data.result
        //console.log(videos)
        setVideoFeed(videos)
    }
    const cleanfilename = (filename) =>{
        return filename.replace(/([^a-z0-9 ]+)/gi, '-');

    }
    const downloadallvideos = async () =>{
        //console.log(videofeed)
        
        
        if (getVideo === true){
            videofeed.map((video,ind) =>{
                savefile(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getvideo?url=${video.link}`,cleanfilename(video.title),".mp4",ind,videofeed.length)
            })

        }
        else{
            videofeed.map((video,ind) =>{
                savefile(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getaudio?url=${video.link}`,cleanfilename(video.title),".mp3",ind,videofeed.length)
            })

        }
        //

    }
    const downloadvideo = async (video) =>{
        
        
        
        
        if (getVideo === true){
            savefile(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getvideo?url=${video.link}`,cleanfilename(video.title),".mp4",1,videofeed.length)

        }
        else{
            savefile(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getaudio?url=${video.link}`,cleanfilename(video.title),".mp3",1,videofeed.length)

        }
        //

    }
    const savefile = (url,title,ext=".mp4",ind,length) => {
        let skillName = title + ext
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
        let urlCreator = window.URL || window.webkitURL;
        let videoUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement('a');
        tag.href = videoUrl;
        tag.target = '_blank';
        tag.download = skillName.includes(ext) ? skillName : skillName + ext;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        };

        xhr.onreadystatechange = (e) => {
            
        if (ind === length-1){
        setCurrentDownload(0)
        setDownloading(false)
        }
        else{
            setCurrentDownload(ind)
        }
        }
        xhr.onerror = (err) => {};
        xhr.send();
        
        

 
    };
    useEffect(() =>{
        get_video_links()
    },[revert])




 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
    





            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"80px":"30px"}}>
               <h1 style={{color:"white"}}>CaesarAI</h1>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px",gap:"20px"}}>
                <div onClick={() =>{setGetVideo(true)}} style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:getVideo === true ? "blue": "white",borderRadius:"5px", width: maxRowBased ? "300px": "128px",height:"50px", cursor:"pointer"}}>
                    <a style={{color:getVideo === true ? "white":"black"}} >Video</a>
                </div>
                <div  onClick={() =>{setGetVideo(false)}} style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:getVideo === true ? "white": "blue",borderRadius:"5px", width: maxRowBased ? "300px": "128px",height:"50px", cursor:"pointer"}}>
                    <a  style={{color:getVideo === true ? "black":"white"}}>Audio</a>
                </div>
                
               
            </div>
            {videofeed.length > 0 &&
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px",gap:"20px",flexDirection:"column"}}>
                {downloading === false ? <a  onClick={() =>{setDownloading(true);downloadallvideos()}} style={{color:"white",cursor:"pointer"}}>Download All</a>: <p style={{color:"white"}}>Downloading...{currentDownload}/{videofeed.length}</p>}
                 {videofeed.map((video,ind) =>{return(
                    <div style={{display:"flex"}}>
                    <a onClick={() =>{setMessage(video.link);setSendingMessage(true);setVideoFeed([])}} style={{cursor:"pointer"}}>
                       <div  style={{display:"flex",gap:"10px",width:maxRowBased ? "1000px":"300px",flexDirection: maxRowBased ? "row": "column"}}>
                        <img style={{borderRadius:"5px"}} src={video.thumbnails[0].url}></img>
                        <div style={{color:"white"}}>
                            <p >{video.title}</p>
                            <div style={{display:"flex",gap:"5px"}}>
                                <a href={video.channel.link}><img style={{width:"30px",height:"30px",borderRadius:"100px"}} src={video.thumbnails[0].url}></img></a>
                                <a href={video.channel.link}><p style={{position:"relative",top:"5px",color:"white",textDecoration: "underline"}}>{video.channel.name}</p></a>
                                <p></p>    
                            </div> 
                        </div>
                    </div>
                    </a>
                    <div key={ind} style={{color:"white",position:"relative",top:"5px"}}><a onClick={() =>{downloadvideo(video)}} style={{cursor:"pointer"}}><Download/></a></div>
                    </div>


                 )})}
                
            </div>
            }
            {sendingmessage === true &&
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
            <iframe title="Youtube Media" style={{borderRadius:"10px",width:maxRowBased ? "1300px":"300px",height:maxRowBased ? "1000px":"500px"}} 
            src={
                getVideo === true ?
                `https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getvideo?url=${message}`
                :
                `https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getaudio?url=${message}`
            } >

            </iframe>
            <div style={{backgroundColor:"#1e1e1e" ,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",marginBottom:"auto"}}>
                        <strong><a onClick={() =>{setMessage("");setSendingMessage(false);setRevert(true)}} style={{color:"white"}}><CancelIcon/></a></strong>
            </div>
            </div>}



            



        </div>
    )
}