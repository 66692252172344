
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function HomerModel({modelPath}) {
  const { nodes, materials } = useGLTF(modelPath);
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere.geometry}
        material={nodes.Sphere.material}
        position={[-4.007, 7.24, 3.501]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere001.geometry}
        material={nodes.Sphere001.material}
        position={[-4.822, 7.251, 5.004]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.008"]}
        position={[-3.975, 5.33, 4.525]}
        rotation={[-0.253, -0.492, 1.482]}
        scale={[1, 0.999, 0.994]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={materials["Material.001"]}
        position={[-7.741, 5.359, 2.771]}
        scale={3.994}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere003.geometry}
        material={materials["Material.009"]}
        position={[-4.022, 2.951, 4.447]}
        rotation={[-0.011, -0.403, 0.016]}
        scale={[0.945, 1.859, 1.98]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere004.geometry}
        material={materials["Material.007"]}
        position={[-2.679, 5.36, 5.176]}
        rotation={[-0.007, -0.507, 1.574]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere002.geometry}
        material={materials["Material.002"]}
        position={[-7.746, 9.927, 2.771]}
        rotation={[-3.13, 0, 0]}
        scale={[3.994, 1.005, 3.994]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Torus.geometry}
        material={materials["Material.005"]}
        position={[-7.523, 10.791, 2.967]}
        rotation={[0, 1.034, -1.344]}
        scale={[1.04, 1.587, 0.411]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Torus001.geometry}
        material={materials["Material.006"]}
        position={[-8.517, 10.662, 2.343]}
        rotation={[0, 1.034, -1.344]}
        scale={[1.04, 1.587, 0.411]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere005.geometry}
        material={materials["Material.003"]}
        position={[-3.032, 7.304, 3.909]}
        scale={[0.225, 0.3, 0.255]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sphere006.geometry}
        material={materials["Material.004"]}
        position={[-3.89, 7.247, 5.59]}
        scale={[0.225, 0.3, 0.255]}
      />
    </group>
  );
}

useGLTF.preload("/homer.glb");
