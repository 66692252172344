import { moviekeys } from "../moviekeys";
import axios from "axios";
import { browserName } from "react-device-detect";
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import { useEffect, useState } from "react";

export default function Episode({episodeid,number,setVideo,setCurrentEpisode}){
    const [cookie_key,setCookieKey] = useState(`${episodeid}`)
    const [haswatchedcookie,setHasWatchedCookie] = useState(read_cookie(cookie_key))
    const getepisode = async () =>{
        const response = await axios.get(`https://caesaraiconsumet.fly.dev/anime/gogoanime/watch/${episodeid}?server=vidstreaming`);
        let result = response.data
        let video = result.sources.filter((source) =>{return(source.quality === "1080p")})[0]
        bake_cookie(cookie_key, 'true');
        //delete_cookie(cookie_key)"
        setHasWatchedCookie("true")
        setVideo(video.url)
        setCurrentEpisode(number)


    }

    //console.log(film.original_language)
    //console.log(haswatchedcookie,"hi")
    return(
        <div onClick={() =>{getepisode()}} style={{display:"flex",border:"1px solid black",backgroundColor:haswatchedcookie === "true" ? "blue":"white",opacity:haswatchedcookie === "true" ? "0.2":"1",borderRadius:"5px",height:"30px",width:"30px",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
            <a style={{color:haswatchedcookie === "true" ? "white":"black"}}>{number}</a>

            
        </div>
    )
}