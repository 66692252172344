import React from "react";
import { Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MailIcon from '@mui/icons-material/Mail';
import GitHubIcon from '@mui/icons-material/GitHub';
import { LinkedIn } from "@mui/icons-material";
import DescriptionIcon from '@mui/icons-material/Description';
import useMediaQuery from "./useMedia";
import { maxRowBasedquery } from "./mediamax";
//import HeaderStyles from "../components/HeaderStyles";
//let styles = new HeaderStyles(); 


const contactoptions = [["Email","amari.lawal05@gmail.com"],["Github","amarilawal"],["Linkedin","amarilinkedin"],["CV","AmariCV"]] // ,"linkedin.com/in/amari-lawal-49823a1b4
class HeaderStylesContact{
    constructor(maxRowBased){
        this.appBar = {backgroundColor: "#1e1e1e",height:"100%", width: maxRowBased ? "100%" : "100%"};//700px
        this.headertitle = {textAlign:"center",fontSize:"70px",marginBottom:"0px"}
        this.mailicon = {color:"black",marginTop:"20px",width:"80px",height:"80px"}
        this.cardcenteringrow = {display:"flex",justifyContent:"center", flexDirection: "row"} // {display:"flex",justifyContent:"center"}
        this.cardrow = {display: "flex",justifyContent:"center"}// {marginTop:"100px",marginLeft:"150px"}
        this.cardrowphone = {display: maxRowBased ? "flex": "auto",flexDirection: maxRowBased ? "row" : "column",justifyContent: maxRowBased ? "center": "auto", gap: maxRowBased ? "5%" : "auto"}
        this.textcolorblack = {color:"black"}
        this.card = {display: "flex",width: '18rem',color:"white",height:"500px",margin: "6px"}
    
    }
}
function ContactMe(){
    const maxRowBased = useMediaQuery(maxRowBasedquery);
    let styles = new HeaderStylesContact(maxRowBased);
    // <AppBar position="static" style={Object.assign({},styles.appBar,{height:"750px"})}>
    return(
    <div style={styles.appBar}>
    <h2 id= "contactme" style={Object.assign({},styles.headertitle,{fontSize:"50px"})}><font color="white">Contact</font><font color="3296C0"> Me</font></h2>
    <div style={styles.cardrowphone} >
        {contactoptions.map((option,idx) => <ContactCards options={[option,idx]} styles = {styles} maxRowBased = {maxRowBased}/>)}
    </div>
    </div>
    )
}



const RenderImage = (props) => {
    switch (props.option) {
    case "Email" : 
    return(
        <MailIcon style={props.styles.mailicon}/>
    )
    
    case "Github": 
        return(
            <a href="https://github.com/CaesarCloudSync" target="_blank" rel="noreferrer"> 
            <GitHubIcon style={props.styles.mailicon}/>
            </a>
        )
    
    case "Linkedin": 
        return(
            <a href="https://linkedin.com/in/amari-lawal49823a1b4" target="_blank" rel="noreferrer"> 
            <LinkedIn style={props.styles.mailicon}/>
            </a>
        )
    case "CV": 
        return(
            <a href="https://github.com/Amari-Lawal/Amari-Lawal-Portfolio/blob/main/Amari%20Hussey%20Lawal%20CV.pdf" target="_blank" rel="noreferrer"> 
            <DescriptionIcon style={props.styles.mailicon}/>
            </a>
        )
    default:
        return(<p>Card does not exist</p>)
}
}
const RendText = (props) => {
    switch (props.cardvalues[0]) {
    case "Email": 
        return(
            <p>
            {props.cardvalues[1]}
            </p>
            )
    
    case "Github": 
        return(
            <p>
            <a href="https://github.com/CaesarCloudSync" target="_blank" rel="noreferrer" style={{color:"black",textDecoration:"none"}}>
            {props.cardvalues[1]}
            </a>
            </p>)
 
    case "Linkedin":
        return(
            <p>
            <a href="https://linkedin.com/in/amari-lawal49823a1b4" target="_blank" rel="noreferrer" style={{color:"black",textDecoration:"none"}}>
            {props.cardvalues[1]}
            </a>
            </p>)
    case "CV":
        return(
            <p>
            <a href="https://github.com/Amari-Lawal/Amari-Lawal-Portfolio/blob/main/Amari%20Hussey%20Lawal%20CV.pdf" target="_blank" rel="noreferrer" style={{color:"black",textDecoration:"none"}}>
            {props.cardvalues[1]}
            </a>
            </p>)
    default:
        return(<p>Card does not exist</p>)
    
}
        
    
}

function ContactCards(props){
    
    const cardname = contactoptions[props.options[1]][0]
    const cardlink = contactoptions[props.options[1]][1]
    //const cardvalue = contactoptions[props.options[1]][1]
    //console.log(contactoptions[props.options[1]])
    return(
        <div>
        { props.maxRowBased ? 
        <Card bg={"white"} style={Object.assign({},props.styles.card,{height:"200px"})}>
        <div style={props.styles.cardcenteringrow}>
            <RenderImage option={cardname} styles = {props.styles} />
        </div>
        
        <Card.Body>
        <div style={props.styles.cardcenteringrow}>
            <Card.Title style={props.styles.textcolorblack}>{cardname}</Card.Title>
        </div>
        <div style={props.styles.cardcenteringrow}>
            <Card.Text style={props.styles.textcolorblack}>
            <RendText cardvalues={[cardname,cardlink]}/>
            </Card.Text>
            </div>
        </Card.Body>
        </Card>

        :
        <div  style={props.styles.cardrow} >
        <Card bg={"white"} style={Object.assign({},props.styles.card,{height:"200px"})}>
        <div style={props.styles.cardcenteringrow}>
            <RenderImage option={cardname} styles = {props.styles} />
        </div>
        
        <Card.Body>
        <div style={props.styles.cardcenteringrow}>
            <Card.Title style={props.styles.textcolorblack}>{cardname}</Card.Title>
        </div>
        <div style={props.styles.cardcenteringrow}>
            <Card.Text style={props.styles.textcolorblack}>
            <RendText cardvalues={[cardname,cardlink]}/>
            </Card.Text>
            </div>
        </Card.Body>
        </Card>
        </div>
        
        }
        </div>
    
    )
}
export default ContactMe;