import { useLocation } from "react-router-dom"
import HeaderBar from "./headerbarmedia"
export default function ALPaid(){
    const location = useLocation()
    const statevalue = location.state
    const statebool = (statevalue === null) ? false : true // false if token doesnot exist
    const altutordata = (statevalue !== null) ? statevalue.altutor : ""
    console.log(altutordata) 

    return(
        <div style={{backgroundColor:"#1e1e1e",height:window.innerHeight}}>
        <HeaderBar checkaltutor={true}/>
            <div>
                
            </div>
            <div style={{position:"relative",top:"10%"}}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
                <h1 style={{color:"white"}}>ALTutor Booked!</h1>
                </div>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
                    <div style={{width:"500px",height:"250px",backgroundColor:"white",borderRadius:"10px",padding:"25px"}}>
                    <p style={{position:"relative",top:"8%"}}>{altutordata.clientdetails.studentname}'s session with Amari has been confirmed.</p>
                    <p style={{position:"relative",top:"10%"}}>An email has been sent to {altutordata.clientdetails.guardianemail} confirming your booking with booking details.</p>
                    <p style={{position:"relative",top:"15%"}}>30 minutes before the session a reminder email will be sent to {altutordata.clientdetails.guardianemail} and {altutordata.clientdetails.studentemail} for the session.</p>
                    
                    </div>
                </div>
                <div style={{position:"relative",top:"20px"}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <div style={{width:"500px",height:"200px",backgroundColor:"white",borderRadius:"10px",padding:"25px"}}>
                        <p >Your scheduled times:</p>
                        <ul style={{position:"relative"}}>
                        {altutordata.scheduleddates.dates.map((x,ind) => {return(<li style={{listStyle:"disc"}}>{`${x.slice(8,10)}/${x.slice(5,7)}/${x.slice(0,4)} ${altutordata.scheduleddates.times[ind]}`}</li>)})}
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}