import * as React from 'react';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom"
// CSS Styling
//import HeaderStyles from './HeaderStyles';
import "bootstrap/dist/css/bootstrap.min.css";
import './scrollbar.css'

//let styles = new HeaderStyles();
class HeaderStylesItems{
  constructor(){
    this.navtextcont = {display:"flex",justifyContent: "center",rowGap: "10px", marginTop:"10px"} //,marginRight:"auto"
    //this.navlink = {flex: 1,color:"white",width: "30px",height: "30px",margin: "2px",textAlign: "center",fontSize: "20px",marginRight:"auto"}
    this.navlink = {flex: 1,textAlign: "center",margin: "0.25em",padding: "0.25em",textDecoration:"none",color:"white",fontSize: "15px",marginRight:"auto"}


    this.navIcons = {alignItems: "center"}
    this.itemMargin = {marginRight: "20px"}
  }
}
function Items(page){
  let styles = new HeaderStylesItems();
    if (page === "Frameworks"){
      //var size = text.split(" ");
      //,{marginRight:"55px"}
      return(
      <div style={styles.navtextcont}>
        <div>
        <Button href="#frameworks" key={page}  style={Object.assign({}, styles.navlink,styles.itemMargin)}>{page}</Button>
        </div>

      </div>


      )
    }
    if (page === "AmariFinanceGraphs"){
      //var size = text.split(" ");
      //,{marginRight:"55px"}
      return(
      <div style={styles.navtextcont}>
        <div>
        <Button href="amarifinancegraphs"  target='_blank' rel="noreferrer"  key={page}  style={Object.assign({}, styles.navlink)}>{page}</Button>
        </div>

      </div>


      )
    }
    if (page === "QATimetables"){
      //var size = text.split(" ");
      //,{marginRight:"55px"}
      return(
      <div style={styles.navtextcont}>
        <div>
        <Button href="qatimetables"  target='_blank' rel="noreferrer"  key={page}  style={Object.assign({}, styles.navlink)}>{page}</Button>
        </div>

      </div>


      )
    }
    if (page === "CV"){
      //var size = text.split(" ");
      //,{marginRight:"55px"}
      return(
      <div style={styles.navtextcont}>
        <div>
        <Button href="https://github.com/Amari-Lawal/Amari-Lawal-Portfolio/blob/main/Amari%20Hussey%20Lawal%20CV.pdf"  target='_blank' rel="noreferrer"  key={page}  style={Object.assign({}, styles.navlink)}>{page}</Button>
        </div>

      </div>


      )
    }
    if (page === "Projects"){
      //styles.navlink.marginRight = "10px";
      //styles.itemMargin.marginRight = "40px";
      //console.log(styles.itemMargin);
      return(
        <div style={styles.navtextcont}>
          <div>
            <Button href="#project" key={page} style={Object.assign({}, styles.navlink,styles.itemMargin)}>{page}</Button>
          </div>
        </div>
      )
    }
    if (page === "Skills"){
      styles.itemMargin.marginRight = "10px";
      return(
        <div style={styles.navtextcont}>
          <div>
          <Button href="#skills" key={page} style={Object.assign({}, styles.navlink,styles.itemMargin)}>{page}</Button>
          </div>   

        </div>
      )}
      if (page === "Contact Me"){
        styles.itemMargin.marginRight = "50px";
        return(
          <div>
            <div style={styles.navtextcont}>
              <div>
              <Button href="#contactme" key={page} style={Object.assign({}, styles.navlink,styles.itemMargin)}>{page}</Button>
              </div>
            </div>
          </div>
        )
      }

  }
export default Items;


// Icon Implementation
/*
import CodeIcon from '@mui/icons-material/Code';

import ComputerIcon from '@mui/icons-material/Computer';
import ArticleIcon from '@mui/icons-material/Article';
import Link from '@mui/material/Link';
import ContactMailIcon from '@mui/icons-material/ContactMail';

<div>
<ComputerIcon style={{width:"20px",color:"white",marginTop:"3px"}}></ComputerIcon> 
</div> 
<div>
  <CodeIcon style={styles.navlink}></CodeIcon >
</div>

<div>
  <ArticleIcon style={styles.navlink}></ArticleIcon>
</div>
<div>
<ContactMailIcon style={styles.navlink}></ContactMailIcon>
</div>
*/