import React, { useState } from "react";
import { useEffect } from "react";
import logo from '../imgs/Amari-logo.svg'
import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
export default function ManageCaesarAIEngineWorld(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const maxRowBased = useMediaQuery(maxRowBasedquery)

    const [modelnamesindb,setModelNamesInDB] = useState([]);
    const [qrcodeurl,setQRCodeURL] = useState("");
    const [nummodels,setNumModels] = useState(-1);
    const [dark,setDark] = useState("");
    const [light,setLight] = useState("");
    const [qrcode,setQRCode] = useState("");
    const [scale,setScale] = useState("");
    const [modelPaths,setModelPaths] = useState(["https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=Logitech Keyboard.glb","https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=homer.glb"]); //= 
    const getallmodelname = async () =>{
        const response = await axios.get("https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getallmodelnames")
        const result = response.data
        if ("error" in result){
            alert("no models")
        }
        else{
            
            setNumModels(result.modelnames.length)
            const modelnames = result.modelnames.map((model) => {return(model.filename)})
            setModelNamesInDB(modelnames)
            
        }
    }
    const createqrcode = async (e) =>{
        e.preventDefault();
        const darkcolor = dark === "" ? "black" : dark
        const lightcolor = light === "" ? "white" : light
        const scalesize = scale === "" ? 5 : parseInt(scale)
        const response = await axios.post("https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/createqrcode",{"url":qrcodeurl,"usebase64":"true","dark":darkcolor,"light":lightcolor,"box_size":scalesize})
        const result = response.data
        if ("error" in result){
            alert(result.error)
        }
        else{
            setQRCode(result.qrcode)
        }
    }
    //const 
    useEffect(() =>{
        getallmodelname()
    },[])
 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <div style={{position:"relative",top:"30px",left:"7.5%",width:"55%"}}>
                <a style={{cursor:"pointer"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <h1 style={{color:"white"}}>CaesarAIEngineWorld</h1>
            </div>
            
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px"}}>
                <div style={{backgroundColor:"white",borderRadius:"10px"}}>
                    <div style={{padding:"10px"}}>
                    <div >
                        <p>Models available:</p>
                        <p>PSA: Top value was last added.</p>
                        
                        {nummodels !== 0 ?
                        <div style={{display:"flex",flexDirection:"column"}}>
                        {modelnamesindb.map((modelpath,index) =>{return(
                        <div>
                            {maxRowBased ? 
                            <li key={index}><a href={`/caesaraiengineworldmodel?h=${modelpath}`} target="_blank" rel="noopener noreferrer" style={{cursor:"pointer",color:"black"}}>{modelpath}</a></li>
                            :
                            <li key={index}><a href={`/caesaraiengineworldmodel?h=${modelpath}`} style={{cursor:"pointer",color:"black"}}>{modelpath}</a></li>}
                        </div>
                        
                        )})}

                        {maxRowBased ? 
                        <a href="https://gltf.pmnd.rs/" target="_blank" rel="noopener noreferrer">Generate Blender Model</a>
                        :
                        <a href="https://gltf.pmnd.rs/" >Generate Blender Model</a>
                        }
                        </div>
                        :
                        <div>
                            {maxRowBased ? 
                            <a href="https://gltf.pmnd.rs/" target="_blank" rel="noopener noreferrer">Generate Blender Model</a>
                            :
                            <a href="https://gltf.pmnd.rs/" >Generate Blender Model</a>}

                        </div>}
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <input onChange={(e) =>{setQRCodeURL(e.target.value)}} placeholder="Enter URL For QR Code:" value={qrcodeurl}></input>
                            <input onChange={(e) =>{setDark(e.target.value)}} placeholder="Enter Dark Color:" value={dark}></input>
                            <input onChange={(e) =>{setLight(e.target.value)}} placeholder="Enter Light Color:" value={light}></input>
                            <input onChange={(e) =>{setScale(e.target.value)}} placeholder="Enter Scale:" value={scale}></input>
                        </div>
                        <button onClick={(e) =>{createqrcode(e)}} >Submit</button>
                        
                        
                    </div>
                    </div>
                </div>
                {qrcode !== "" && <img style={{borderRadius:"10px"}} src={qrcode}></img>}
            </div>

        </div>
    )
}