import { useState } from "react"
export default function AmariWidgetIcon(props){

    return(
        <div className="AmariWidgetButton" style={{padding:"20px",backgroundColor:"#3296c0",borderTopRightRadius:"5px",borderBottomRightRadius:"5px",width:props.widgetwidth,height:props.widgetheight}}>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",gap:"13px"}}>
          <div style={{width:"40px",height:"10px",backgroundColor:"white",borderRadius:"2px"}}></div>
          <div style={{width:"40px",height:"10px",backgroundColor:"white",borderRadius:"2px"}}></div>
          <div style={{width:"40px",height:"10px",backgroundColor:"white",borderRadius:"2px"}}></div>
        </div>
      </div>

    )
}