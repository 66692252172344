import { useLocation } from "react-router"
import AmariMovies from "./AmariMovies";
import { useState } from "react";
export default function AuthAmariMovies(){
    let location = useLocation();
    const [authed,setAuthed] = useState(location.state === null ? false: location.state.authed === "true" ? true : false)
    return(
        <div>
            <AmariMovies/>

        </div>

    )
}

/*
            {authed === true ? 
            
            :
            <div><h1>Amari Movies Unauthorized</h1></div>} */