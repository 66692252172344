import Cards from './cardcomponentmedia';
import React from 'react';
import useMediaQuery from './useMedia';
import {maxRowBasedquery} from './mediamax';
//import HeaderStyles from './HeaderStyles';
//let styles = new HeaderStyles();
const cardtitles = ['Data Science', 'Web Development', 'Machine Learning','Data Visualization'];

class HeaderStylesFramework{
    constructor(maxRowBased){
        this.appBar2 = {backgroundColor: "#FFFFFF",height:"100%",width: maxRowBased ? "100%" : "100%"};//700px
        this.headertitletextcont = {display:"flex",justifyContent:"center",alignItems:"center",width: maxRowBased ? "100%" : "60%",height: maxRowBased ? "auto" : "25%", marginLeft: maxRowBased ? "0px" : "4.5em"};
        this.headertitle = {alignItems:"center",textAlign:"center",fontSize:"70px",marginBottom:"-20px"}
        this.subheadingcont = {alignItems:"center",width: "500px",margin: "auto",height:"40px",marginTop: "30px"}
        this.subheading = {textAlign:"center",fontSize:"20px",display: "block"}
        this.cardcontainer = {display:"flex",marginBottom:"155px",marginTop:"60px",flexDirection: maxRowBased ? 'row' : 'column',alignItems:"center"}
        this.card = {width: '18rem',color:"white",height:"600px",margin: "6px"};//,marginTop:"50px",,marginLeft:"200px"
        this.cardImg = {marginLeft:"160px",width: "90px", height: "90px"}
        this.cardtext = {fontSize:"15px"}
        
        this.numpypandaslogocont = {alignSelf: "center"}
        this.numpypandaslogo = {width: "200px", height: "200px"}

        this.reactflasklogocont = {alignSelf: "center", marginRight: maxRowBased ? "60px" : "50px"}
        this.reactflasklogo = {width: "250px", height: "250px"}
        
        this.tensorflowscikitlogocont  = {alignSelf: "center"}
        this.tensorflowscikitlogo = {width: "200px", height: "200px"}
        
        this.matplotliblogocont = {alignSelf: "center"}
        this.matplotliblogo = {width: "190px", height: "190px"}
        
    }
}

function FrameworkComponent(){
const maxRowBased = useMediaQuery(maxRowBasedquery);
let styles = new HeaderStylesFramework(maxRowBased);
// <AppBar position="static" style={styles.appBar2}>
return(
<div style={styles.appBar2}>
<div style={styles.headertitletextcont}>
    <h2 id="frameworks" style={Object.assign({},styles.headertitle,{fontSize:"50px"})}><font color="black">Framework</font><font color="3296C0"> Techniques</font></h2>
</div>
<div style={Object.assign({},styles.headertitletextcont,{marginBottom: maxRowBased ? "auto" : "12em"})}>
    <div style ={Object.assign({},styles.subheadingcont,{width:"600px"})}>
        <p style={styles.subheading}> 
        <font color="808080">
        Frameworks that I have practiced ranging from machine learning, data science and web develepemont.
        Differing between several programming languages and disciplines.
        </font>
        </p>
    </div>
</div>

{/*Cards*/}
<div style={styles.cardcontainer}>
{cardtitles.map((cardtitle) => (Cards(cardtitle,styles)))}
</div>

</div>
)}

export default FrameworkComponent;