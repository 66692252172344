import axios from "axios"

import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import { moviekeys } from "../moviekeys";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { browserName, browserVersion } 
from "react-device-detect"
import { useState,useEffect } from "react";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
export default function SeriesCard({film}){
    const navigate = useNavigate()
    const location = useLocation()
    const media = useMediaQuery(maxRowBasedquery)
    const [isonwishlist,setIsOnWishList]= useState(false)
    const getvideo = async () =>{


        //console.log(film.name)
        navigate({
            pathname: '/amarianimeseasons',
            search: `?series=${film.name.replaceAll(" ","-",)}`,
          },{state:film});
            

    }

    const checkwishlist = async () =>{
        //console.log(film.id)
        const response = await axios.get(`https://amarimovieswishlist-qqbn26mgpa-nw.a.run.app/checkwishlist?themoviedbid=${film.id}`)
        let result = response.data
        //console.log(result)
        if (result.result === "true"){
            //console.log("hi")
            setIsOnWishList(true)
        }
        else{
            setIsOnWishList(false)
        }
    }
    const addtowishlist = async () =>{
  
        const response = await axios.post(`https://amarimovieswishlist-qqbn26mgpa-nw.a.run.app/addwishlist?themoviedbid=${film.id}`,{"movie":film.name,"themoviedbid":film.id,"type":"tv"})
        let result = response.data

        if ("message" in result){
                setIsOnWishList(true)
        }
    }
    const removefromwishlist = async () =>{
        const response = await axios.delete(`https://amarimovieswishlist-qqbn26mgpa-nw.a.run.app/deletefromwishlist?themoviedbid=${film.id}`)
        let result = response.data
        if ("message" in result){
                setIsOnWishList(false)
        }

    }
    useEffect(()=>{
        checkwishlist()
    },[])

    return(
        <div>
        <div style={{display:"flex",flexDirection:"column"}}>
            
            <img onClick={() =>{getvideo()}} src={`https://image.tmdb.org/t/p/original/${film.poster_path}`} style={{width:media ? "300px" :"150px",height:media ? "400px" :"250px",cursor:"pointer",borderRadius:"5px"}}></img>
            <div style={{width:media ? "300px" :"100px",marginTop:media ? "0px":"30px"}}>
                <h3 style={{color:"white",fontSize:media ? "auto":"12px"}}>{film.name}</h3>
                <p style={{color:"white",fontSize:media ? "auto":"12px"}}>Vote Count: {film.vote_count}</p>
                
                <p style={{color:"white",fontSize:media ? "auto":"12px"}}>Release Date: {film.first_air_date}</p>
                <p style={{color:"white",fontSize:media ? "auto":"12px"}}>Rating: {film.vote_average.toFixed(2)}</p>
                {isonwishlist === false ? 
                <a onClick={() =>{addtowishlist()}} style={{cursor:"pointer"}}><FavoriteBorderIcon style={{color:"white"}}/></a>
                :
                <a onClick={() =>{removefromwishlist()}} style={{cursor:"pointer"}}><FavoriteIcon style={{color:"white"}}/></a>
                }
            </div>

        </div>
    
    </div>
    )
}