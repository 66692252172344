import { useLocation } from "react-router"
import AmariFinanceGraphs from "./amarifinancegraphs";
import { useState } from "react";
export default function AuthAmariFinanceGraphs(){
    let location = useLocation();
    const [authed,setAuthed] = useState(location.state === null ? false: location.state.authed === "true" ? true : false)
    return(
        <div>
            {authed === true ? 
            <AmariFinanceGraphs/>
            :
            <div><h1>Amari FinanceGraphs Unauthorized</h1></div>}
        </div>

    )
}