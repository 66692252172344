export const formatPromptText = (session) =>{
    //console.log(session)
    return(
    session.replaceAll("**","").replaceAll("*","*-").split("*").map((text) =>{return(<div><p style={{color:"white"}}>{text.split("```").map((textinner) =>{
        //console.log(textinner.match("/([0-9]\.)|([0-9] \.)/") ,textinner)
        return(<div><br></br><p>
            {textinner.split("\n").map((textinnerinner) =>{return(<div><br></br><p>{textinnerinner}</p></div>)})

            }</p></div>)}
        )}
        </p><br></br></div>)})
    )
}