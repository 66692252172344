import React from 'react';
import useMediaQuery from './useMedia';
import {maxRowBasedquery} from './mediamax';
import pythonlogo from './imgs/skills/pythonlogo.svg'
import reactlogo from './imgs/skills/reactlogo.svg'
import flasklogoskills from './imgs/skills/flasklogo.svg'
import tensorflowlogo from './imgs/skills/tensorflowlogo.svg'
import herokulogo from './imgs/skills/herokulogo.svg'
import azurelogo from './imgs/skills/azurelogo.svg'
import linuxlogo from './imgs/skills/linuxlogo.svg'
import seleniumlogo from './imgs/skills/seleniumlogo.svg'
import mongodblogo from './imgs/skills/mongodblogo.svg'
import html5logo from './imgs/skills/html5logo.svg'
import csslogo from './imgs/skills/csslogo.svg'
import gitlogo from './imgs/skills/gitlogo.svg'
import typelogo from './imgs/skills/typescriptlogo.svg'
import dockerlogo from './imgs/skills/dockerlogo.svg'
//import nodelogo from './imgs/skills/NodeJs.svg'
import javscriptlogo from './imgs/skills/javascriptlogo.svg'
//let styles = new HeaderStyles();
class HeaderStylesSkills{
    constructor(maxRowBased){
        this.appBar2 = {backgroundColor: "#FFFFFF",height: maxRowBased ? "750px" : "100%",width: maxRowBased ? "100%" : "80%"};
        this.headertitle = {textAlign:"center",fontSize:"70px",marginBottom:"-20px"};
        this.appBarskillrow = {display: "flex",justifyContent:"center",marginTop:"40px"} // ,marginLeft:maxRowBased ? "0px" : "100px"
        this.projectstitle = {color:"white"}
        this.appBarskillcolumn = {flex:1}
        this.pythonlogo = {width: maxRowBased ? "200px" : "150px", height: maxRowBased ? "200px" : "150px"} 
        //this.herokulogo = {marginLeft: maxRowBased ? "auto" : "50px"}
        this.firstrowlogos = {width: maxRowBased ? "200px": "150px", height: maxRowBased ? "200px": "150px",marginLeft: maxRowBased ? "50px" : "0px"} //,marginLeft:"-100px"
        this.csslogo = {width: maxRowBased ? "250px": "150px", height: maxRowBased ? "250px": "150px",marginLeft: maxRowBased ? "50px" : "0px"}
        this.mongodblogo = {width: maxRowBased ? "200px": "150px", height: maxRowBased ? "200px": "150px"}
        this.azurelogo = {width: maxRowBased ? "400px": "200px", height:maxRowBased ? "200px" : "150px",marginLeft: maxRowBased ? "-20px" : "0px",marginRight: maxRowBased ? "-80px" : "0px"}
        this.linuxlogo = {width: maxRowBased ? "200px" : "150px", height: maxRowBased ? "200px": "150px",marginLeft:"0px"} 
        // Bug May be around here
        this.appBarskillrowphone = {display: "flex",justifyContent:"center",marginTop:"40px", alignItems: maxRowBased ? "auto" : "center", width: maxRowBased ? "auto" : "80%"}
    }
}

function Skills(){
    const maxRowBased = useMediaQuery(maxRowBasedquery);
    let styles = new HeaderStylesSkills(maxRowBased);
    return(
    <div style={styles.appBar2}>
     
    <h2 id= "skills" style={Object.assign({},styles.headertitle,{fontSize:"50px"})}><font color="3296C0">My</font><font color="black"> Skills</font></h2>
    { maxRowBased ?
    <div className='appBar2row' style={styles.appBarskillrow}>
      <div className="appBar2column" style={styles.appBar2column}>
        <img src={pythonlogo} style={styles.pythonlogo} alt=''></img>
        <img src={reactlogo} style={styles.firstrowlogos} alt=''></img>
        <img src={html5logo} style={styles.firstrowlogos} alt=''></img>
        <img src={csslogo} style={styles.csslogo} alt=''></img>
        <img src={javscriptlogo} style={styles.firstrowlogos} alt=''></img>
        <img src={flasklogoskills} style={styles.firstrowlogos} alt=''></img>
        <img src={mongodblogo} style={styles.mongodblogo} alt=''></img>
        <img src={tensorflowlogo} style={styles.firstrowlogos} alt=''></img>
        <img src={herokulogo} style={styles.firstrowlogos} alt=''></img>
        <img src={azurelogo} style={styles.azurelogo} alt=''></img>
        <img src={gitlogo} style={styles.firstrowlogos} alt=''></img>
        <img src={seleniumlogo} style={styles.firstrowlogos} alt=''></img>
        
        <img src={linuxlogo} style={styles.linuxlogo} alt=''></img>
        <img src={typelogo} style={styles.firstrowlogos} alt=''></img>
  
        <img src={dockerlogo} style={styles.firstrowlogos} alt=''></img>
        
      </div>

    </div>
    
    : 

    <div style={{marginLeft: "40%"}}>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={pythonlogo} style={styles.pythonlogo} alt=''></img>
        <img src={reactlogo} style={styles.firstrowlogos} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={html5logo} style={styles.firstrowlogos} alt=''></img>
        <img src={csslogo} style={styles.csslogo} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={javscriptlogo} style={styles.firstrowlogos} alt=''></img>
        <img src={flasklogoskills} style={styles.firstrowlogos} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={mongodblogo} style={styles.mongodblogo} alt=''></img>
        <img src={tensorflowlogo} style={styles.firstrowlogos} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={herokulogo} style={Object.assign({},styles.firstrowlogos,styles.herokulogo)} alt=''></img>
        <img src={azurelogo} style={styles.azurelogo} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={gitlogo} style={styles.firstrowlogos} alt=''></img>
        <img src={seleniumlogo} style={styles.firstrowlogos} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={linuxlogo} style={styles.linuxlogo} alt=''></img>
        <img src={typelogo} style={styles.firstrowlogos} alt=''></img>
    </div>
    <div className='appBar2row' style={styles.appBarskillrowphone}>
        <img src={dockerlogo} style={styles.firstrowlogos} alt=''></img>
    </div>

    </div>
    }
    </div>
    )
}
export default Skills;