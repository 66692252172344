import React, { useState } from "react";
import { useEffect } from "react";
import logo from '../imgs/Amari-logo.svg'
import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
export default function UploadArt(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const maxRowBased = useMediaQuery(maxRowBasedquery)

    const [modelnamesindb,setModelNamesInDB] = useState([]);

    const [artfile,setArtFile] = useState(null);


    const handleFileChange = (e) => {
      if (e.target.files) {
        let artfile =e.target.files[0]
        var bodyFormData = new FormData();
        let filename = artfile.name
        bodyFormData.append('file', artfile);
      // We will fill this out later
      //console.log(artfile)
      axios({
        method: "post",
        url: "https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/postmodel",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          let result = response.data
          if ("message" in result){
            if(!(result.message === "model already exists.")){
                setModelNamesInDB(modelnamesindb.concat([filename]))
                setArtFile(null)
                //console.log(response);
            }
    

        }

        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
      }
    };
  
 

    const getallmodelname = async () =>{
        const response = await axios.get("https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getallmodelnames")
        const result = response.data
        if ("error" in result){
            alert("no models")
        }
        else{
            const modelnames = result.modelnames.map((model) => {return(model.filename)}).filter((model) =>{return(model.includes(".png"))})
            setModelNamesInDB(modelnames)
            
        }
    }
    const deleteart = async (modelname) =>{
        if (window.confirm(`Delete ${modelname}`)) {
            const response = await axios.delete(`https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/deletemodel?filename=${modelname}`)
            const result = response.data
            if (!("error" in result)){
                let new_modelnamesindb  = modelnamesindb.filter(model => {return(model !== modelname)})
                setModelNamesInDB(new_modelnamesindb)
            }
        }
        else{

        }
    }

    //const 
    useEffect(() =>{
        getallmodelname()
    },[])
 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <div style={{position:"relative",top:"30px",left:"7.5%",width:"55%"}}>
                <a style={{cursor:"pointer"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>
            </div>
            <div style={{position:"absolute",right:"170px",top:"50px"}}>
                <a style={{cursor:"pointer"}} onClick={() =>{navigator("/uploadart")}}><FileUploadIcon style={{color:"white",fontSize:"28px"}}/></a>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <h1 style={{color:"white"}}>Upload Art</h1>
            </div>
            
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px"}}>
                <div style={{backgroundColor:"white",borderRadius:"10px"}}>
                    <div style={{padding:"10px"}}>
                        <div>
                        {modelnamesindb.length !==0 && <h4 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>Amari Art Pieces</h4>}

                        {modelnamesindb.length !==0 && modelnamesindb.map((modelpath,index) =>{return(
                        <div>
                            {maxRowBased ? 
                            <div style={{display:"flex",gap:"10px"}}>
                                <li key={index}><a href={`/amariart?filename=${modelpath}`} target="_blank" rel="noopener noreferrer" style={{cursor:"pointer",color:"black"}}>{modelpath}</a></li>
                                <a style={{cursor:"pointer"}} onClick={() =>{deleteart(modelpath)}}>x</a>
                            </div>
                            :
                            <div style={{display:"flex",gap:"10px"}}>
                            <li key={index}><a href={`/amariart?filename=${modelpath}`} style={{cursor:"pointer",color:"black"}}>{modelpath}</a></li>
                            <a style={{cursor:"pointer"}} onClick={() =>{deleteart(modelpath)}}>x</a>
                            </div>
                            }
                        </div>
                        
                        )})}
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <input type="file" id="actual-btn" accept="image/png" onChange={handleFileChange}  hidden/>
                        <label htmlFor="actual-btn" ><a style={{cursor:"pointer"}}><FileUploadIcon style={{color:"black"}}/></a></label>
                        </div>

                        </div>
      

                    </div>
                </div>

            </div>

        </div>
    )
}