import React, { useState } from "react";
import { useEffect } from "react";
import logo from '../imgs/Amari-logo.svg'
import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
export default function AmariArt(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    const [artfilename,setArtFileName] = useState(searchParams.get("filename") === null ? "" : searchParams.get("filename"))


 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <div style={{position:"fixed",top:"30px",left:"7.5%",width:"55%"}}>
                <a style={{cursor:"pointer"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>
            </div>


            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"20px":"100px"}}>
                <h1 style={{color:"white"}}>Amari Art</h1>
            </div>
            
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px",marginTop:maxRowBased?"20px" : "100px"}}>
                <div style={{backgroundColor:"white",borderRadius:"10px"}}>
                    <div style={{padding:"20px"}}>
                    <div >
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                        {artfilename !== "" && <p>{artfilename}</p>}
                        <img style={{width:maxRowBased ? "600px":"300px",height:maxRowBased ? "800px":"500px"}} src={`https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=${artfilename}`}></img>
                                    
                    </div>
                        
                        
                    </div>
                    </div>
                </div>

            </div>

        </div>
    )
}