import TechnologyOCModule from "./technologyoc"
import SoftwareEngFund from "./softwareEngFund"
import ITProjectMgM from "./itprojectmgm"
import SoftwareEngFund2 from "./softwareEngFund2"
import MonthlyPortfolio from "./monthlyportfolio"
import "./color.css"
import { useState } from "react"
import { useLocation } from "react-router"
export default function QATimeTables(){
    let location = useLocation();
    const [authed,setAuthed] = useState(location.state === null ? false: location.state.authed === "true" ? true : false)

    const qamodules = ["Technology in the Organisational Context","Software Engineering Fundamentals","IT Project Management","Software Engineering Fundamentals 2","Monthly Portfolio"]
    const modulehtml = [TechnologyOCModule(),SoftwareEngFund(),ITProjectMgM(),SoftwareEngFund2(),MonthlyPortfolio()]
    return (
    <div style={{backgroundColor:"#141212"}}>
        <div style={{position:"relative",left:"70px",top:"30px",color:"white"}}><h1>QA TimeTables {authed === true ? "" : "Unauthorized"}</h1></div>
        { authed === true ?
        qamodules.map((module,ind) =>{return(
                    <div>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center",margin:"40px",color:"white"}}><h2>{module}</h2></div>
                    <div dangerouslySetInnerHTML={{__html:modulehtml[ind]}}></div>
                    </div>
        
                )}) :
                <div></div>
                }
        

    </div>)
}