export default function SoftwareEngFund(){
    return (`
    <div style="font-family:Arial,Helvetica Neue,Helvetica,sans-serif;text-align:left;font-size:14px;line-height:17px;word-wrap:break-word;margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">
    <table width="640" border="0" align="center" style="width:640px;vertical-align:central;margin-top:30px">
      <tbody><tr>
        <td>
          <table width="600" cellpadding="0" border="0" align="center" style="width:600px;vertical-align:central;background-color:white;border-top-width:1px;border-top-style:solid;border-top-color:#d1d1d1;border-left-width:1px;border-left-style:solid;border-left-color:#d1d1d1;border-right-width:1px;border-right-style:solid;border-right-color:#d1d1d1;border-bottom-width:1px;border-bottom-style:solid;border-bottom-color:#d1d1d1;border-collapse:collapse">
            <tbody><tr>
              <td>
                <table width="600" cellpadding="0" border="0" align="center" style="width:560px;background-image:none;border-color:none;background-color:white;padding-bottom:0px;margin-left:auto;margin-right:auto;border-collapse:collapse">
                  <tbody><tr>
                    <td colspan="5">&nbsp;</td>
                  </tr>
                  <tr>
                    <td width="273">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src="https://ci6.googleusercontent.com/proxy/UY66YU7tCwfrGp-I4oIQIwkisc8AsXYVrb5dcBUD17b02ScFccj7RlWZrJMpfNjE0fKMhgI0FolyXjAqmFC2FRsZf96uIh5TndsePKY=s0-d-e1-ft#https://www.qa.com/-/media/static/qa-logo-with-tagline.jpg" alt="" width="233" height="42" class="CToWUd" data-bit="iit"></td>
                    <td width="45">&nbsp;</td>
                    <td width="42">&nbsp;</td>
                    <td width="45">&nbsp;</td>
                    <td width="183">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="5">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="5"><img src="https://ci5.googleusercontent.com/proxy/KjXVkIgM0oQNLX0i2y0q4MKiHiQERW9Gz1DdNFDOwmadNdkVv2De4UhJNQWZhuxQHGBoLdk1NixZQbN2DBGEmBij3DbelX3LrnGYo9EeaLkHCB3SCU3_JlNh9g=s0-d-e1-ft#https://www.qa.com/media/15872/we-ve-confirmed-your-module-enrolment.jpg" alt="We've confirmed your module enrolement" width="600" height="250" class="CToWUd a6T" data-bit="iit" tabindex="0"><div class="a6S" dir="ltr" style="opacity: 0.01; left: 601px; top: 326.333px;"><div id=":w4" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button" tabindex="0" aria-label="Download attachment " jslog="91252; u014N:cOuCgd,Kr2w4b,xr6bB; 4:WyIjbXNnLWY6MTc3ODAzNTk4NjA4OTQ1Nzc5NiIsbnVsbCxbXSxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxudWxsLG51bGwsbnVsbCxbXSxbXSxbXV0." data-tooltip-class="a1V" jsaction="JIbuQc:.CLIENT" data-tooltip="Download"><div class="akn"><div class="aSK J-J5-Ji aYr"></div></div></div></div></td>
                  </tr>
                  <tr>
                    <td colspan="5">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="5">&nbsp;</td>
                  </tr>
                </tbody></table>
                <table width="600" border="0" align="center" style="width:600px;background-image:none;border-color:none;background-color:white;padding-bottom:0px;margin-left:auto;margin-right:auto">
                  <tbody><tr>
                    <td width="30">&nbsp;</td>
                    <td width="532">
                      <p id="m_-8963907551513808988u140-2" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-right:0;padding-left:0;border-width:0;font-size:18px;line-height:29px;letter-spacing:1px;padding-bottom:8px;color:#616161;font-weight:bold">
                            Hello Amari,
                          </p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">We’re looking forward to welcoming you on to your new module with us as part of your Apprenticeship.</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">This email has the dates for your workshop dates and the steps you need to take to make sure you are prepared for your first session.</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">Details of how to access each session will be emailed to you, at this email address, shortly before it is due to commence.</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">We will also email you an Apprenticeship guide to online learning before your studies start – please make sure you have read through this in advance of your first scheduled session.</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">If you need to speak to our team, you can contact us via the following:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">For programme or module queries:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:0px;padding-right:0px;padding-left:0px">Email - 
                            <a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="mailto:QAADegreeAdmin@qa.com" target="_blank"><span>QAADegreeAdmin@qa.com</span></a></p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">For technical assistance setting up or accessing your online learning, please use
                            <a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="https://v2.zopim.com/widget/popout.html?key=5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://v2.zopim.com/widget/popout.html?key%3D5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj&amp;source=gmail&amp;ust=1697294318665000&amp;usg=AOvVaw2AlCVuy15Sj78vHTV-7Uzj"><span>Live Chat</span></a> or:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:0px;padding-right:0px;padding-left:0px">Email - 
                            <a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="mailto:VirtualLearning@qa.com" target="_blank"><span>VirtualLearning@qa.com</span></a></p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px">Phone - 020 3908 2376 (option 2)</p><br></td>
                    <td width="30">&nbsp;</td>
                  </tr>
                </tbody></table>
                <table width="600" border="0" align="center" style="width:600px;background-image:none;border-color:none;background-color:white;padding-bottom:0px;margin-left:auto;margin-right:auto;vertical-align:text-top">
                  <tbody><tr>
                    <td colspan="5">
                      <p id="m_-8963907551513808988u146-4" style="margin-bottom:0;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;line-height:43px;text-align:center;font-size:36px;color:#4590ce;margin-top:25px">Your course booking</p>
                      <p style="margin:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5">&nbsp;</td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="139" style="vertical-align:text-top">
                      <p id="m_-8963907551513808988u246-2" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;letter-spacing:1px;padding-top:0px;padding-right:0px;padding-left:0px;font-size:15px;line-height:24px;padding-bottom:0px;color:#4590ce">COURSE</p><br><img id="m_-8963907551513808988u254_img" src="https://ci3.googleusercontent.com/proxy/miSzfogoDyTQU5OV_zb-L3g38Byn91PKLTLW-WLBs5t816JtXr5_VwXjlw4cmgUxAS4QWXLakjwMuw5grJ1b=s0-d-e1-ft#https://www.qa.com/-/media/static/course.png" alt="" width="40" height="34" style="display:block" class="CToWUd" data-bit="iit"></td>
                    <td width="37">&nbsp;</td>
                    <td width="348" style="vertical-align:text-top">
                      <p id="m_-8963907551513808988u148-2" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-right:0;padding-left:0;border-width:0;font-size:17px;line-height:27px;padding-bottom:8px;color:#2d2d2d;font-weight:bold">Software Engineering Fundamentals (DTS 3yr Enhanced L4)</p>
                      <p id="m_-8963907551513808988u155-5" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px"><span id="m_-8963907551513808988u155">ADAXDTSSEF</span></p>
                    </td>
                    <td width="30">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="5">
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">&nbsp;</p>
                      <table width="500" align="center" style="padding-top:15px;padding-bottom:15px">
                        <tbody><tr>
                          <td style="border-top-width:1px;border-top-style:solid;border-top-color:#d1d1d1"></td>
                        </tr>
                      </tbody></table>
                    </td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="139" style="vertical-align:text-top">
                      <p id="m_-8963907551513808988u245-2" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;letter-spacing:1px;padding-top:0px;padding-right:0px;padding-left:0px;font-size:15px;line-height:24px;padding-bottom:0px;color:#4590ce">WHO'S GOING?</p><br><img id="m_-8963907551513808988u158_img" src="https://ci6.googleusercontent.com/proxy/_14pnYV8XOWPaTGuZLY8DjYDvY9tlEjHsM6bVgOjABNDTMWpAg6jpa88prWnlQLo0NQbiShg5fdtmPDXcKaCXJ_oCThtpxdA=s0-d-e1-ft#https://www.qa.com/-/media/static/group-of-people.png" alt="" width="40" height="34" style="display:block" class="CToWUd" data-bit="iit"></td>
                    <td width="37">&nbsp;</td>
                    <td width="348" style="vertical-align:text-top">
                      <p id="m_-8963907551513808988u155-5" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px"><span id="m_-8963907551513808988u148-2" style="font-size:17px;line-height:27px;padding-bottom:8px;color:#2d2d2d;font-weight:bold">Amari Lawal</span><span id="m_-8963907551513808988u155-3"><br></span><span id="m_-8963907551513808988u155">
                                  ref. 10751818</span></p>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="5">
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">&nbsp;</p>
                      <table width="500" align="center" style="padding-top:15px;padding-bottom:15px">
                        <tbody><tr>
                          <td style="border-top-width:1px;border-top-style:solid;border-top-color:#d1d1d1"></td>
                        </tr>
                      </tbody></table>
                    </td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="139" style="vertical-align:text-top">
                      <p id="m_-8963907551513808988u248-2" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;letter-spacing:1px;padding-top:0px;padding-right:0px;padding-left:0px;font-size:15px;line-height:24px;padding-bottom:0px;color:#4590ce">DATES &amp; TIMES</p><br><img id="m_-8963907551513808988u164_img" src="https://ci6.googleusercontent.com/proxy/bYbBlVQDIensHps2zab9HLDgr9HrAlMxhov87H4EioCe8GCDiRLsHGR1DX0PG0muj1e2yI6dJ7UFKfcxOQ=s0-d-e1-ft#https://www.qa.com/-/media/static/when.png" alt="" width="40" height="34" style="display:block" class="CToWUd" data-bit="iit"></td>
                    <td width="37">&nbsp;</td>
                    <td width="348" style="vertical-align:text-top">
                      <p id="m_-8963907551513808988u194-5" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px"><span id="m_-8963907551513808988u148-2" style="font-size:17px;line-height:27px;padding-bottom:8px;color:#2d2d2d;font-weight:bold">24 October 2023
                                at
                                09:30<br></span><span id="m_-8963907551513808988u194-3">QA On-Line Virtual Centre<br></span><span id="m_-8963907551513808988u194-3">0.5 day</span></p>
                      <p id="m_-8963907551513808988u194-5" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px"><span id="m_-8963907551513808988u148-2" style="font-size:17px;line-height:27px;padding-bottom:8px;color:#2d2d2d;font-weight:bold">14 November 2023
                                at
                                09:30<br></span><span id="m_-8963907551513808988u194-3">QA On-Line Virtual Centre<br></span><span id="m_-8963907551513808988u194-3">0.5 day</span></p>
                    </td>
                    <td width="30">&nbsp;</td>
                  </tr>
                </tbody></table>
                <table width="600" border="0" align="center" style="width:600px;background-image:none;border-color:none;background-color:white;padding-bottom:0px;margin-left:auto;margin-right:auto;vertical-align:text-top">
                  <tbody><tr>
                    <td colspan="5">
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">&nbsp;</p>
                      <p id="m_-8963907551513808988u260-4" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;min-height:39px;background-color:transparent;line-height:43px;text-align:center;font-size:36px;color:#71a23d">Things to check</p><br></td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="60" style="vertical-align:text-top"><img id="m_-8963907551513808988u215_img" src="https://ci4.googleusercontent.com/proxy/3abBK1899Mu9bXMvqYokjUOa6HqVEYGlPVymXm2QOAPGET87A5m0IVjInbN0cG0WjVqPHEndMkATw5yrKUz4zKnr6PP1Kr4rwGa_ljYWrIME=s0-d-e1-ft#https://www.qa.com/-/media/static/pasted%20image%20142x127.jpg" alt="" width="40" height="40" style="display:block" class="CToWUd" data-bit="iit"></td>
                    <td width="37">&nbsp;</td>
                    <td width="427" style="vertical-align:text-top">
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-bottom:8px;padding-right:10px;padding-left:0px;padding-top:15px">We have three key things for you to do before you start your online learning with us:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-bottom:8px;padding-right:10px;padding-left:0px;padding-top:15px">
                              • Please check your sound and microphone settings ahead of your first online learning session.  An easy way to do this is to set up a video call with a friend or colleague.  You may prefer to use a headset or earphones (with mic).<br></p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-bottom:8px;padding-right:10px;padding-left:0px;padding-top:15px">
                              • Read through the Apprenticeship quick guide to online learning when you receive it. This will help you understand how to access support, how the session will run and how to get help if you need it.
                          </p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-bottom:8px;padding-right:10px;padding-left:0px;padding-top:15px">
                              • Plan where you will be located during your session, this should be a place that minimises background noise (if possible) but one that you will be comfortable for a few hours.  There will be opportunities to engage with fellow learners and your tutor during the session so you may want to find a space you are free to talk from.
                          </p>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                </tbody></table>
                <table width="600" border="0" align="center" style="width:600px;background-image:none;border-color:none;background-color:white;padding-bottom:0px;margin-left:auto;margin-right:auto">
                  <tbody><tr>
                    <td width="30">&nbsp;</td>
                    <td width="532">
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">&nbsp;</p>
                      <p id="m_-8963907551513808988u185-4" align="center" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;font-size:36px;line-height:43px;color:#616161;font-weight:normal">If you need to get in touch...</p>
                      <p id="m_-8963907551513808988u185-30" align="center" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:0px;padding-left:0px;font-weight:bold;color:black">
                            Our booking reference number is 10751818</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:8px;padding-left:8px">If you need to speak to our team, you can contact us via the following:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:8px;padding-left:8px">For programme or module queries:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:0px;padding-right:8px;padding-left:8px">
                            Email -
                            <a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="mailto:QAADegreeAdmin@qa.com" target="_blank"><span>QAADegreeAdmin@qa.com</span></a></p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:8px;padding-left:8px">For technical assistance setting up or accessing your online learning, please use
                            <a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="https://v2.zopim.com/widget/popout.html?key=5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://v2.zopim.com/widget/popout.html?key%3D5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj&amp;source=gmail&amp;ust=1697294318666000&amp;usg=AOvVaw1eniyWyjBCMSuzKBpDLepN"><span>Live Chat</span></a> or:</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:0px;padding-right:8px;padding-left:8px">
                            Email -
                            <a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="mailto:VirtualLearning@qa.com" target="_blank"><span>VirtualLearning@qa.com</span></a></p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;border-width:0;color:#7f7f7f;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:14px;letter-spacing:1px;line-height:22px;padding-top:0px;padding-bottom:8px;padding-right:8px;padding-left:8px">Phone - 020 3908 2376 (option 2)</p>
                      <p style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0">&nbsp;</p>
                    </td>
                    <td width="30">&nbsp;</td>
                  </tr>
                </tbody></table>
                <table width="600" border="0" align="center" style="width:600px;background-color:#005bab;color:white">
                  <tbody><tr>
                    <td width="30">&nbsp;</td>
                    <td width="228">&nbsp;</td>
                    <td width="105">&nbsp;</td>
                    <td width="48">&nbsp;</td>
                    <td width="48">&nbsp;</td>
                    <td width="48">&nbsp;</td>
                    <td width="45">&nbsp;</td>
                    <td width="20">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><img src="https://ci6.googleusercontent.com/proxy/Y7k0Ol3YyOieAS981-nqYQhxjEk4AG5HJOfl_2TSoGxBiHAjMwX1Ffn417IVgSNHvBQdPVMr51ZKtkwr1UYuE1iNR9eWG1q4SXa4E8p87t0LwpvgpS79=s0-d-e1-ft#https://www.qa.com/-/media/static/qa-logo---with-tagline---white.png" alt="" width="228" height="54" style="display:block" class="CToWUd" data-bit="iit"></td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td><a href="http://qa.com/facebook" style="font-weight:bold;color:inherit;text-decoration:inherit;margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://qa.com/facebook&amp;source=gmail&amp;ust=1697294318666000&amp;usg=AOvVaw1FCXSXxo8G9Wz-kmmDIrDq"><img src="https://ci5.googleusercontent.com/proxy/R8QndVwgQ1wrB3oR5mayqVnJZHgC9hWdmnuZ5R2OLvYkkx-h8h5QxUHfjfGjbr3r4LoTphMFgVjoM_sWbuuXIbY=s0-d-e1-ft#https://www.qa.com/-/media/static/facebook.png" alt="" width="25" height="25" style="border-style:none;padding-left:1px;display:block" class="CToWUd" data-bit="iit"></a></td>
                    <td><a href="http://qa.com/linkedin" style="font-weight:bold;color:inherit;text-decoration:inherit;margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://qa.com/linkedin&amp;source=gmail&amp;ust=1697294318666000&amp;usg=AOvVaw2346X1aKZKLErBPR1h50VX"><img src="https://ci6.googleusercontent.com/proxy/oL21_4T_x_o6qQUM8R633bCNJnL1DnBZmZQbvSvdWXgAclAk4XQ99GE3IzEC5OfLyqrRY521-9uUCzW0kGiG-tU=s0-d-e1-ft#https://www.qa.com/-/media/static/linkedin.png" alt="" width="25" height="25" style="border-style:none;padding-left:1px;display:block" class="CToWUd" data-bit="iit"></a></td>
                    <td><a href="http://qa.com/youtube" style="font-weight:bold;color:inherit;text-decoration:inherit;margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://qa.com/youtube&amp;source=gmail&amp;ust=1697294318666000&amp;usg=AOvVaw0vPElAUCkoKlkBBjwdsW_y"><img src="https://ci6.googleusercontent.com/proxy/i0KVgIy5ctznWV3j8ZdVU4tcGVjs5MC_kyNsZ6xzmsxBBfF6zt5CUpKUjUopqfsACl8yay1eu9Q-UNnD2MVfig=s0-d-e1-ft#https://www.qa.com/-/media/static/youtube.png" alt="" width="25" height="25" style="border-style:none;padding-left:1px;display:block" class="CToWUd" data-bit="iit"></a></td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td colspan="4">&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody></table>
              </td>
            </tr>
          </tbody></table>
          <table width="600" border="0" align="center" style="width:600px;border-color:none;background-color:#f6f6f6;color:#7f7f7f;font-size:10px;line-height:14px;padding-left:5px;padding-right:5px">
            <tbody><tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                    You have been sent this email because you recently booked or were booked onto a course with us.
                    <br>
                    You can
                    <span><a style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" href="https://cp.qa.com?Param1=Wq0SVfoDWzQXYv+VjdW61xpCKkNH/XptThBj08EaCk0=&amp;Param2=pZQJ+8DccjUaXgRx1nWYTA==" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://cp.qa.com?Param1%3DWq0SVfoDWzQXYv%2BVjdW61xpCKkNH/XptThBj08EaCk0%3D%26Param2%3DpZQJ%2B8DccjUaXgRx1nWYTA%3D%3D&amp;source=gmail&amp;ust=1697294318666000&amp;usg=AOvVaw3EMJhc8eOyJy0dUBwfI0Yg"><span>manage your QA communications preferences here</span></a></span>.
                    <br><br>QA respects your rights to privacy and understand the importance of protecting personal information that we hold about you. We have set out in our privacy notice (available at
                    <span><a href="https://www.qa.com/legal/privacy-notice" style="margin-top:auto;margin-bottom:auto;margin-right:auto;margin-left:auto;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;border-width:0;color:#4590ce;font-weight:bold;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.qa.com/legal/privacy-notice&amp;source=gmail&amp;ust=1697294318666000&amp;usg=AOvVaw1N4SynpBCfs2kzyXAr3v0j"><span>https://www.qa.com/legal/<wbr>privacy-notice</span></a></span>)
                    information about what personal information we collect and how it is used and shared. Our privacy notice also includes details of who to contact if you have any questions about our privacy and data protection practices.
                    <br>
                    11QAADABC v1.5
                    <br><br>
                    All third party trademarks acknowledged.
                    <br><br>
                    QA works with organisations to transform performance through learning. We are dedicated to making our clients more successful through the provision of world-class training services.
                    <br><br>
                    The information transmitted is intended only for the person or entity to which it is addressed and may contain confidential and/or privileged material. Any review, retransmission, dissemination or other use of, or taking of any action in reliance upon, this information by persons or entities other than the intended recipient is prohibited. If you received this in error, please contact the sender and delete the material from any computer.
                    <br><br>
                    QA Ltd. 1st Floor, International House, 1 St Katharines Way, London E1W 1UN. 
                    <br>
                    QA Ltd. is a company registered in England with company registration number: 2413137
                  </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody></table>
        </td>
      </tr>
    </tbody></table><div class="yj6qo"></div><div class="adL">
  </div></div>
    `)
}