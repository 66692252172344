import axios from "axios"

import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import { moviekeys } from "../moviekeys";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { browserName, browserVersion } 
from "react-device-detect"
import { useState,useEffect } from "react";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

export default function Wish({themoviedbid,broadcasttype,wishlist,setWishlist}){
    const navigate = useNavigate();
    const [film,setFilm] = useState("")
    const media = useMediaQuery(maxRowBasedquery)
    const getvideo = async () =>{
        if (broadcasttype === "movie"){
            const config = {
                headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
            };
            const response = await axios.get(`https://api.themoviedb.org/3/movie/${themoviedbid}/external_ids`,config)
            let result = response.data
            if (browserName.includes("Safari")){
                window.location.assign(`https://vidsrc.xyz/embed/movie/${result.imdb_id}`)
            }
            else{
                window.open(`https://vidsrc.xyz/embed/movie/${result.imdb_id}`,'_blank', 'rel=noopener noreferrer')
            }
        }
        else{
            navigate({
                pathname: '/amariepisodes',
                search: `?series=${film.name.replaceAll(" ","-",)}`,
              },{state:film});
        }



    }

    const getwishdetails = async () =>{
        const config = {
            headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
        };
        const response = await axios.get(`https://api.themoviedb.org/3/${broadcasttype}/${themoviedbid}`,config)
        let result = response.data
        setFilm(result)

    }
    function removeValue(value, index, arr) {
        if (value.themoviedbid === film.id) {
            arr.splice(index, index+1);
            return true;
        }
        return false;
    }


    const removefromwishlist = async () =>{
        const response = await axios.delete(`https://amarimovieswishlist-qqbn26mgpa-nw.a.run.app/deletefromwishlist?themoviedbid=${film.id}`)
        let result = response.data
        if ("message" in result){
            let new_wishlist  = wishlist.filter(a => {console.log(a.themoviedbid,film.id);return(a.themoviedbid !== film.id)})
            setWishlist(new_wishlist);

        }

    }
    useEffect(()=>{
            getwishdetails()
    },[])

    return(
        <div>
            <div>
            </div>
            {film !== "" &&
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <h1></h1>
            
                    <img onClick={() =>{getvideo()}} src={`https://image.tmdb.org/t/p/original/${film.poster_path}`} style={{width:media ? "300px" :"150px",height:media ? "400px" :"250px",cursor:"pointer",borderRadius:"5px"}}></img>
                    <div style={{width:media ? "300px" :"100px",marginTop:media ? "0px":"30px"}}>
                        <h3 style={{color:"white",fontSize:media ? "auto":"12px"}}>{broadcasttype === "movie" ? film.title : film.name}</h3>
                        <p style={{color:"white",fontSize:media ? "auto":"12px"}}>Vote Count: {film.vote_count}</p>
                        
                        <p style={{color:"white",fontSize:media ? "auto":"12px"}}>Release Date: {broadcasttype === "movie" ? film.release_date:film.first_air_date }</p>
                        <p style={{color:"white",fontSize:media ? "auto":"12px"}}>Rating: {film.vote_average.toFixed(2)}</p>
                        <a onClick={() =>{removefromwishlist()}} style={{cursor:"pointer"}}><FavoriteIcon style={{color:"white"}}/></a>
                    </div>
        
                </div>}

    
    </div>
    )
}