import React, { useState } from "react";
import { useEffect } from "react";
import logo from '../imgs/Amari-logo.svg'
import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
export default function AmariArt(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const location = useLocation();
    const [modelnamesindb,setModelNamesInDB] = useState([]);
    const [albumnames,setAlbumNames] = useState("")
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    const [artfilename,setArtFileName] = useState(searchParams.get("filename") === null ? "" : searchParams.get("filename"))


    const getallmodelname = async () =>{
        const response = await axios.get("https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getallmodelnames")
        const result = response.data
        if ("error" in result){
            alert("no models")
        }
        else{

            const modelnames = result.modelnames.map((model) => {return(model.filename)}).filter((model) =>{return(model.includes(".png") && !(model.toLowerCase().includes("album")))})
            const albumnames = result.modelnames.map((model) => {return(model.filename)}).filter((model) =>{console.log(model.toLowerCase());return(model.includes(".png") && (model.toLowerCase().includes("album")))})
            if (albumnames.length > 0){
                setAlbumNames(albumnames)
            }
            setModelNamesInDB(modelnames)
            
        }
    }

    //const 
    useEffect(() =>{
        getallmodelname()
    },[])
 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <div style={{display:"flex"}}>
                <div style={{flex:1}}>
                <a style={{cursor:"pointer",position:"relative",top:"30px",left:"120px"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>

                </div>
                <div style={{flex:1,textAlign:"right",position:"relative",top:"65px",right:"120px"}}>
                <a style={{cursor:"pointer"}} onClick={() =>{navigate({pathname: '/amarisecurity',search: "?h=uploadart"})}}><FileUploadIcon style={{color:"white",fontSize:"28px"}}/></a>
            
                </div>

            </div>



            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"0px":"30px"}}>
                <h1 style={{color:"white"}}>Amari Gallery</h1>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"0px":"30px"}}>
                {albumnames.length > 0 && 
                    albumnames.map((album) =>{return(
                        <div onClick={() =>{navigate({pathname: '/amarialbum',search: `?album=${album}`,})}} style={{backgroundColor:"white",padding:"7px",borderRadius:"5px",cursor:"pointer"}}>
                            <p style={{color:"black",position:"relative",top:"3px"}}>{album}</p>
                        </div>
                    )})
                  }
            </div>
 
            
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"10px",marginTop:"20px"}}>
                <div style={{backgroundColor:"white",borderRadius:"10px",maxWidth:maxRowBased?"1600px":"350px"}}>
                    <div style={{padding:"20px"}}>
                    <div >
                        {modelnamesindb.length !== 0 &&
                        <div style={{display:"flex",flexWrap:"wrap",gap:"30px"}}>
                            
                               { modelnamesindb.map((model) =>{
                                    return(<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                        <p>{model}</p>
                                        <a style={{cursor:"pointer"}} onClick={() =>{    navigate({pathname: '/amariart',search: `?filename=${model}`,})}}><img style={{width:maxRowBased ? "500px":"300px",height:maxRowBased ? "700px":"500px",borderRadius:"5px"}} src={`https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=${model}`}></img></a>
                                    
                                    </div>)
                                })
    
                           
                                        }
                        </div>
                        }

                        
                        
                    </div>
                    </div>
                </div>

            </div>

        </div>
    )
}