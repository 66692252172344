import { moviekeys } from "../moviekeys";
import axios from "axios";
import { browserName } from "react-device-detect";
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import { useEffect, useState } from "react";
export default function Episode({film,episode,season_number,anime,usetorrentanime,setVideoLink}){
    const [cookie_key,setCookieKey] = useState(`${film.id}${season_number}${episode}`)
    const [haswatchedcookie,setHasWatchedCookie] = useState(read_cookie(cookie_key))
    const getepisode = async () =>{
        const config = {
            headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
        };
        const response = await axios.get(`https://api.themoviedb.org/3/tv/${film.id}/external_ids`,config)
        let result = response.data
        bake_cookie(cookie_key, 'true');
        //delete_cookie(cookie_key)"
        setHasWatchedCookie("true")
        if (browserName.includes("Safari")){
            
            if (anime === true){
                if (usetorrentanime === false){
                    setVideoLink(`https://vidsrc.xyz/embed/tv?imdb=${result.imdb_id}&ds_langs=en`)
                    }
                    else{
                        window.location.assign(`https://api.slidemovies.org/anime/?imdb_id=${result.imdb_id}&s=${season_number}&e=${episode}`)
                        
                    }
            }
            else{
                setVideoLink(`https://vidsrc.xyz/embed/tv/${result.imdb_id}/${season_number}/${episode}`)
            }
        }
        else{
            if (anime === true){
                if (usetorrentanime === false){
                    setVideoLink(`https://vidsrc.xyz/embed/tv?imdb=${result.imdb_id}&ds_langs=en`)
                    }
                    else{
                        window.open(`https://api.slidemovies.org/anime/?imdb_id=${result.imdb_id}&s=${season_number}&e=${episode}`,'_blank', 'rel=noopener noreferrer')
                    }
            }
            else{
               setVideoLink(`https://vidsrc.xyz/embed/tv/${result.imdb_id}/${season_number}/${episode}`)
            }
        }

    }

    //console.log(film.original_language)
    //console.log(haswatchedcookie,"hi")
    return(
        <div onClick={() =>{getepisode()}} style={{display:"flex",border:"1px solid black",backgroundColor:haswatchedcookie === "true" ? "blue":"white",opacity:haswatchedcookie === "true" ? "0.2":"1",borderRadius:"5px",height:"30px",width:"30px",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
            <a style={{color:haswatchedcookie === "true" ? "white":"black"}}>{episode}</a>
        </div>
    )
}