import { Navigate } from "react-router";
import { useLocation,useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import "./stripecheckoutform.css"
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const stripe = loadStripe("pk_test_51La4WnLpfbhhIhYRRq1OTZQNpXqjvPY6TY9rx7SjHOhVN5oOSBFaasDvdgAdQBKKHIoWikfkjYMn0DR3hB4sJS2q00vi7W5hLE") 
//("pk_live_51La4WnLpfbhhIhYRtajDrQN6hg1Ykin8vKIrptIm0pGoOFNgoU7x0DeTSDX6wq5JTNyRgkICNxL3Bj5d0W5uxa1w00kSscjXNz")
//

//
// https://blog.logrocket.com/integrating-stripe-react-stripe-js/
export default function ALTutorPayment(){
    const location = useLocation() //.state
    const statevalue = location.state
    const statebool = (statevalue === null) ? false : true // false if token doesnot exist
    const token = (statevalue !== null) ? statevalue.token : "" 
    //console.log(token)
    

    //console.log(price)

    //console.log(price)
    const email = (statevalue !== null) ? statevalue.altutorjson.altutor.clientdetails.guardianemail : "" 
    const altutordata = statevalue.altutorjson
    console.log(altutordata)
    const price = (statevalue !== null) ? statevalue.price : ""
    const overallprice = (statevalue !== null) ? statevalue.price * altutordata.altutor.scheduleddates.dates.length : ""  
     
    //console.log(email)

    class BillingStyles{
        constructor(){
            this.marginbot = {marginBottom:"10px"}
            this.textcolor = {color:"white"}
            this.container = {display:"flex",justifyContent: "center",textAlign:"left",alignItems:"center",gap:"30px",flexDirection:"column",width:"100%"}
            this.containerposition = {position:"relative",top:"50%"}
        }
    }
    const styles = new BillingStyles();
    

  
    //<ReactPayPal price={price} token ={token} subscription={subscription} email={email}/>
    return(
        <div style={{backgroundColor:"#1e1e1e",height:window.innerHeight}}> 
        { statebool ? 
        <div>
            <div style={{position:"relative",top:"100px"}}>
            <div style={styles.containerposition}>
                <div style={styles.container}>
                    <div>
                        <h2 style={styles.textcolor}>AlTutor. Book {altutordata.altutor.scheduleddates.subject} Session for £{overallprice}</h2> 
                    </div>
                    <div style={{textAlign:"left"}}>
                        <h2 style={styles.textcolor}>Each session £{price}. </h2> 
                    </div>
                    <Elements stripe={stripe}>
                        <CheckoutForm price={overallprice} token={token} email={email} altutordata={altutordata}/>
                    </Elements>


                    
                </div> 
            </div>
        </div>
        </div>
        
        :
        <div>
        <Navigate to="/"/>
        </div>
        }
    </div> 
    )
}


function CheckoutForm(props) {

  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const storeSubscriptionData = async (altutordata) => {
    const responseschedule = await axios.post("https://altutor-tsw4.onrender.com/altutorschedule",altutordata)
    //const responseschedule = await axios.post("https://altutor-tsw4.onrender.com/altutorschedule",{"altutor":{"clientdetails":clientdetails,"scheduleddates":isodates}})
    //console.log(responseschedule.data)
    }
  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const getClientSecret = async (price) => { 
        const json = {"price":price,"token":props.token}    
        //console.log(json)
        
        //https://revisionbankapi.herokuapp.com/revisionbankstripepayment
        const response = await axios.post(`https://altutor-tsw4.onrender.com/revisionbankstripepayment`, json); // Send login post request.
        //console.log(response.data)
        return response.data.clientsecret
        

        };
    const clientSecret = await getClientSecret(props.price);

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `RevisionBank for £${props.price}`,
        },
      },
    });
    setPaymentLoading(false);
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        //console.log("Payment Successful")
        //console.log(props.subscription,start_date,end_date,props.token)
        storeSubscriptionData(props.altutordata)
        
        navigate("/alpaid", { state:props.altutordata});
        return <div>Payment successful.!</div>;
        //alert("Success!");
      }
    }
  };

  return (
    <div
      style={{
        width:"500px",
        padding: "3rem",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}
          onSubmit = {payMoney}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white"
                  } 
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? "Loading..." : "Pay Now"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
