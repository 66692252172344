import { useLocation } from "react-router"
import AmariPlaid from "./amariplaid";
import { useState } from "react";
export default function AuthPlaid(){
    let location = useLocation();
    const [authed,setAuthed] = useState(location.state === null ? false: location.state.authed === "true" ? true : false)
    return(
        <div>
            {authed === true ? 
            <AmariPlaid/>
            :
            <div><h1>Amari Plaid Unauthorized</h1></div>}
        </div>

    )
}