import { useLocation } from "react-router"
import { useState } from "react";
import UploadArt from "./uploadart";
export default function AuthUploadArt(){
    let location = useLocation();
    const [authed,setAuthed] = useState(location.state === null ? false: location.state.authed === "true" ? true : false)
    return(
        <div>
            {authed === true ? 
            <UploadArt/>
            :
            <div><h1>Amari Upload Art Unauthorized</h1></div>}
        </div>

    )
}