
import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import { useLocation } from "react-router";
import { useState } from "react";
import { moviekeys } from "../moviekeys"
import axios from "axios";
import { useEffect } from "react";
import Wish from "./wish";
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function Wishlist(){
    const [wishlist,setWishlist] = useState([]);
    const media = useMediaQuery(maxRowBasedquery)

    const [seasons,setSeasons] = useState([])
    const [description,setDescription] = useState("");
    const [number_of_episodes,setNumOfEpisodes] = useState(0)
    const getfilmdetails = async () =>{
    
        const response = await axios.get("https://amarimovieswishlist-qqbn26mgpa-nw.a.run.app/getallwishlist")
        let result = response.data
        console.log(result)
        if ("message" in result){
            setWishlist(result.message)
        }
    }

    useEffect(() =>{
        getfilmdetails()
        
    },[])
    return(
        <div style={{backgroundColor:"#1e1e1e",height:window.innerHeight,overflow:"auto"}}>
            <div style={{position:"relative",top:"100px"}}>
            <div style={{display:"flex",borderRadius:"5px",padding:"10px",gap:"20px",justifyContent:"center",alignItems:"center",flexWrap:"wrap"}}>
                <h1 style={{color:"white"}}>Wishlist</h1>
            </div>

            <div style={{display:"flex",borderRadius:"5px",padding:"10px",gap:"20px",justifyContent:"center",alignItems:"center",flexWrap:"wrap"}}>
    
                    {wishlist.length > 0 && wishlist.map((wish,index) =>{
                        return(<Wish key={wish.themoviedbid} wishlist={wishlist}setWishlist={setWishlist}  themoviedbid={wish.themoviedbid} broadcasttype={wish.broadcasttype}/>)
                    })}

                </div>  







        </div>
            


        </div>
    )
}