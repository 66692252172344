import { useNavigate } from "react-router"
import logo from '../imgs/Amari-logo.svg'
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
export default function CaesarAIAPIRoutes({caesarairoutes}){
    const navigate =  useNavigate();
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const [showMenu,setShowMenu] = useState(false)
    return(
        <div>
            {maxRowBased ?
            
            <div style={{position:"fixed",width: "300px",minHeight:window.innerHeight,backgroundColor:"#131313"}}>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"30px"}}>
            <a style={{cursor:"pointer",marginTop:"30px"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>

            {caesarairoutes.map((routedata) =>{
                return(
                    <a style={{color:"white",fontSize:"25px",cursor:"pointer"}} onClick={() =>{navigate(routedata.route)}}>{routedata.name}</a>
                    
                )
            })}
                
            </div>


        </div>
        :
        <div>
            {showMenu === true ?            
            <div style={{position:"fixed",width: "300px",minHeight:window.innerHeight,backgroundColor:"#131313",right:maxRowBased ? "auto":"0px",zIndex:"99"}}>
            <a onClick={() =>{setShowMenu(false)}} style={{position:"fixed",right:"10px",top:"10px",cursor:"pointer"}}><CloseIcon style={{color:"white"}}/></a>
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"30px"}}>
            <a style={{cursor:"pointer",marginTop:"30px"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>

            {caesarairoutes.map((routedata) =>{
                return(
                    <a style={{color:"white",fontSize:"25px",cursor:"pointer"}} onClick={() =>{navigate(routedata.route)}}>{routedata.name}</a>
                    
                )
            })}
                
            </div>


        </div>
        :
        <div style={{position:"absolute",right:"20px",top:"20px"}} >
        <a onClick={() =>{setShowMenu(true)}}><MenuIcon style={{color:"white",cursor:"pointer"}}/></a>
        </div>}

        </div>

        
        }

    </div>
    )
}