import { useState } from "react"
import AmariWidgetIcon from "./amariwidgeticon";
import AmariWidgetPopup from "./amariwidgetpopup";
export default function AmariWidget(){
    const [widgetopen,setWidgetOpen] = useState(false);
    const [widgetwidth,setWidgetWidth] = useState("60px")
    const [widgetheight,setWidgetHeight] = useState("100px")
    const [cursorstate,setCursorState] = useState("pointer")
    const handleClick = () =>{
        setWidgetOpen(true)
        setWidgetWidth("200px")
        setWidgetHeight("330px") // Change this when changing the number of apps in widget
        setCursorState("auto")


  
    }
    return(
        <div style={{position:"fixed",width:widgetwidth,height:widgetheight,left:"0",zIndex:"300",top:"170px",cursor:cursorstate}} onClick={handleClick}>
            {widgetopen === false ?
            <AmariWidgetIcon widgetwidth={widgetwidth} widgetheight={widgetheight}/>
            :
            <AmariWidgetPopup widgetopen={widgetopen} widgetwidth={widgetwidth} widgetheight={widgetheight} setWidgetOpen={setWidgetOpen} setWidgetWidth={setWidgetWidth} setWidgetHeight={setWidgetHeight} setCursorState={setCursorState}/>
            }
      </div>
    )
}