import HeaderBar from "./headerbarmedia"
import { useState } from "react"
import axios from "axios"
export default function ALDetails(){
    const [passwdinput,setPasswdInput] = useState("")
    const [clientdetails,setClientDetails] = useState()
    const [incorrectpassword,setIncorrectPassword] = useState(false)
    const [noclients,setNoClients] = useState(false)
    const getaldetails = async (e) =>{
        setIncorrectPassword(false)
        //e.preventDefault();
        //console.log("fetching...")
        const response = await axios.post("https://altutor-tsw4.onrender.com/getclientschedules",{"altutorpasswd":passwdinput})
        if ("message" in response.data){
            setIncorrectPassword(true)

        }
        else{
            //console.log(response.data)
            if (response.data.clientdetails.length == 0){
                setNoClients(true)
            }
            else{
                setClientDetails(response.data)
            }
            
        
        }
        
        //console.log(response.data)
    }
    function DisplayClientDetails(props){
        //console.log()
        //const scheduledatesandtimes = props.scheduledclient.scheduleddatetimes
        const scheduledates = Object.keys(props.scheduledclient.scheduleddatetimes)
        const scheduledtimes = Object.values(props.scheduledclient.scheduleddatetimes)
        //console.log(scheduledates)
        function ShowDateStrong(props){
            if (props.ind % 2 == 0){
                return(
                    <div>
                        <strong>{`${props.date.slice(8,10)}/${props.date.slice(5,7)}/${props.date.slice(0,4)} ${scheduledtimes[props.ind]}`}</strong>
                    </div>
                )

            }
            else{
                return(
                    <div>
                    {`${props.date.slice(8,10)}/${props.date.slice(5,7)}/${props.date.slice(0,4)} ${scheduledtimes[props.ind]}`}
                </div>
                    
                )
            }


        }
        //console.log()
        return(
            <div style={{fontSize:"14px"}}>
            <strong>Guardian Name: {props.scheduledclient.guardianname.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, Guardian Email: {props.scheduledclient.guardianemail}</strong>
            <br></br>
            <span style={{color:"blue"}}>Student Name: {props.scheduledclient.studentname.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}, Student Email: {props.scheduledclient.studentemail}</span>
            <br></br>
            <strong>{props.scheduledclient.subject}</strong>
            <br></br>
            Scheduled Dates and Times:
            <br></br>
            {scheduledates.map((date,ind) => {return(<ShowDateStrong date={date} ind={ind}/>)})}
            


            </div>
            
        )

       
    }
    
    return(
    <div style={{height:window.innerHeight,backgroundColor:"#1e1e1e"}}>
        <HeaderBar checkaltutor={true}></HeaderBar>
        <div style={{position:"relative",top:"10%"}}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <h1 style={{color:"white"}}>ALTutor Details</h1>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}>
                    <div style={{width:"500px",height:"80px",backgroundColor:"white",borderRadius:"10px",padding:"25px"}}>
                    <input type="password" onChange={(e) => {setPasswdInput(e.target.value)}}>
                    </input>
                    <button onClick={getaldetails} style={{marginLeft:"5px",height:"40px",borderRadius:"5px",backgroundColor:"blue",color:"white"}} >Submit</button>
                    {incorrectpassword === true && <p style={{marginTop:"-8px"}}>Password incorrect.</p>}
                    {noclients === true && <p style={{marginTop:"-8px"}}>No Clients.</p>}
                    </div> 

            </div>
            
            { clientdetails === undefined ? 
            <div></div>
            : 
            <div style={{position:"relative",top:"10px"}}>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <div style={{width:"500px",backgroundColor:"white",borderRadius:"10px",padding:"25px"}}>
                    <div style={{display:"flex",gap:"30px",flexDirection:"column",alignItems:"left",justifyContent:"left"}}>
                        {clientdetails.clientdetails.map((scheduledclient,ind) => {return(<DisplayClientDetails scheduledclient={scheduledclient} ind={ind}/>)})}
                    </div>
                    </div>
                </div>
            </div>
            }

        </div>
    </div>)
}