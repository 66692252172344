import React, { useState } from "react";
import { useEffect } from "react";

import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../../../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { generateStream } from "../../getStream";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CaesarAILogo from  "../../CaesarAILogo 2.svg"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CaesarAIAPIRoutes from "../../CaesarAIAPIRoutes";
import { useParams } from "react-router";
import { formatPromptText } from "../formatPromptText";
import { CaesarAIAPIRoutesData } from "../../caesaraiapiroutedata";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import YoutubeLogo from "./youtube-1.png"
import { Download } from "@mui/icons-material";
export default function CaesarAIYoutube(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const navigate =  useNavigate();
    const location = useLocation()
    const [caesarairoutes,setCaesarAIRoutes] = useState(CaesarAIAPIRoutesData)//.filter((routedata) =>{console.log(routedata.route.replace(routedata.parentroute,""));return(routedata.route.replace(routedata.parentroute,"") !== location.pathname.split("/").slice(-1)[0])}));
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const [message,setMessage] = useState("");

    const [sendingmessage,setSendingMessage] = useState(false)
    const [getVideo,setGetVideo] = useState(true)
    const [searchquery,setSearchQuery] = useState("");
    const [videofeed,setVideoFeed] = useState([]);
    const [getSingle,setGetSingle] = useState(true)
    const [playlistfeed,setPlaylistFeed] = useState([])
    const getfeed = async () =>{
        if(searchquery !== ""){
            if( getSingle === true){
                const response = await axios.get(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/searchfeed?query=${searchquery}`)
                let videos = response.data.result
                //console.log(videos)
                setVideoFeed(videos)

                //setVideoFeed(videos)

            }
            else{
                const response = await axios.get(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/playlistsearchfeed?query=${searchquery}`)
                let videos = response.data.result
                //console.log(videos)
                setPlaylistFeed(videos)
   
            }


        }
    }
    const cleanfilename = (filename) =>{
        return filename.replace(/([^a-z0-9 ]+)/gi, '-');

    }
    const downloadvideo = async (video) =>{
        
        
        
        
        if (getVideo === true){
            savefile(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getvideo?url=${video.link}`,cleanfilename(video.title),".mp4")

        }
        else{
            savefile(`https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getaudio?url=${video.link}`,cleanfilename(video.title),".mp3")

        }
        //

    }
    const savefile = (url,title,ext=".mp4") => {
        let skillName = title + ext
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
        let urlCreator = window.URL || window.webkitURL;
        let videoUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement('a');
        tag.href = videoUrl;
        tag.target = '_blank';
        tag.download = skillName.includes(ext) ? skillName : skillName + ext;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        };


        xhr.onerror = (err) => {};
        xhr.send();
        
        

 
    };


 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <CaesarAIAPIRoutes caesarairoutes={caesarairoutes}/>
  




            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"80px":"80px"}}>
               s <h1 style={{color:"white"}}>CaesarAI Youtube</h1>
                <img style={{width:"75px",height:"50px",position:"relative",borderRadius:"10px",left:"20px"}} src={YoutubeLogo}></img>
            </div>

            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px"}}>
                <div style={{padding:"5px",display:"flex",border:"1px solid white",borderRadius:"5px",width:maxRowBased ? "700px":"300px",backgroundColor:"#1e1e1e"}}>
                    
                   
                    <form  onSubmit={(e) =>{e.preventDefault();setVideoFeed([]);setSendingMessage(false);getfeed()}}>
                        <input  onChange={(e) =>{setSearchQuery(e.target.value)}} value={searchquery} style={{border:"0px solid white",backgroundColor:"#1e1e1e",color:"white",width:maxRowBased ? "640px" :"260px",outline: "none"}} placeholder="Search"></input>
                            
                    </form>
                    {
                        sendingmessage === false ?
                        <div onClick={() =>{if(message.length > 0){setSendingMessage(true)}}} style={{width:"30px",height:"30px",backgroundColor:message.length === 0 ? "grey" : "white",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",marginLeft:"auto"}}>
                        <strong><p style={{color:"#1e1e1e",position:"relative",top:"6px",fontSize:"30px"}}><ArrowUpwardIcon/></p></strong>
                    </div>
                    :  
                    <div style={{width:"30px",height:"30px",backgroundColor:"#1e1e1e" ,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",marginLeft:"auto"}}>
                        <strong><a onClick={() =>{setMessage("");setSendingMessage(false);}} style={{color:"white"}}><StopCircleIcon/></a></strong>
                    </div>
                    }
                </div>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px",gap:"20px"}}>
                <div onClick={() =>{setGetSingle(true)}} style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:getSingle === true ? "blue": "white",borderRadius:"5px", width: maxRowBased ? "300px": "128px",height:"50px", cursor:"pointer"}}>
                    <a style={{color:getSingle  === true ? "white":"black"}} >Single</a>
                </div>
                <div  onClick={() =>{setGetSingle(false)}} style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:getSingle === true ? "white": "blue",borderRadius:"5px", width: maxRowBased ? "300px": "128px",height:"50px", cursor:"pointer"}}>
                    <a  style={{color:getSingle === true ? "black":"white"}}>Playlist</a>
                </div>
                
               
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px",gap:"20px"}}>
                <div onClick={() =>{setGetVideo(true)}} style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:getVideo === true ? "blue": "white",borderRadius:"5px", width: maxRowBased ? "300px": "128px",height:"50px", cursor:"pointer"}}>
                    <a style={{color:getVideo === true ? "white":"black"}} >Video</a>
                </div>
                <div  onClick={() =>{setGetVideo(false)}} style={{display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:getVideo === true ? "white": "blue",borderRadius:"5px", width: maxRowBased ? "300px": "128px",height:"50px", cursor:"pointer"}}>
                    <a  style={{color:getVideo === true ? "black":"white"}}>Audio</a>
                </div>
                
               
            </div>
            {/*Single Part */}
            {videofeed.length > 0 &&
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px",gap:"20px",flexDirection:"column"}}>
                 {videofeed.map((video) =>{return(
                    <div style={{display:"flex"}}>
                    <a onClick={() =>{setMessage(video.link);setSendingMessage(true);setVideoFeed([]);setSearchQuery("")}} style={{cursor:"pointer"}}>
                       <div  style={{display:"flex",gap:"10px",width:maxRowBased ? "1000px":"300px",flexDirection: maxRowBased ? "row": "column"}}>
                        <img style={{borderRadius:"5px"}} src={video.thumbnails[0].url}></img>
                        <div style={{color:"white"}}>
                            <p >{video.title}</p>
                            <p>{video.viewCount.short} &#x2022; {video.publishedTime}</p> 
                            <div style={{display:"flex",gap:"5px"}}>
                                <a href={video.channel.link}><img style={{width:"30px",height:"30px",borderRadius:"100px"}} src={video.channel.thumbnails[0].url}></img></a>
                                <a href={video.channel.link}><p style={{position:"relative",top:"5px",color:"white",textDecoration: "underline"}}>{video.channel.name}</p></a>
                                <p></p>    
                            </div> 
                        </div>
                    </div>
                    </a>
                    <div style={{color:"white",position:"relative",top:"5px"}}><a onClick={() =>{downloadvideo(video)}} style={{cursor:"pointer"}}><Download/></a></div>
                    </div>
                    


                 )})}
            </div>
            }
            {/*Playlist Part */}
            {playlistfeed.length > 0 &&
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"40px",gap:"20px",flexDirection:"column"}}>
                 {playlistfeed.map((playlist) =>{return(
                    <a onClick={() =>{    navigate({
                        pathname: '/caesarai/caesaraiyoutube/caesaraivideos',
                        search: `?list=${playlist.id}`,
                      })}} style={{cursor:"pointer"}}>
                       <div  style={{display:"flex",gap:"10px",width:maxRowBased ? "1000px":"300px",flexDirection: maxRowBased ? "row": "column"}}>
                        <img style={{borderRadius:"5px"}} src={playlist.thumbnails[0].url}></img>
                        <div style={{color:"white"}}>
                            <p >{playlist.title}</p>
                            <p>Videos: {playlist.videoCount}</p>

                            <div style={{display:"flex",gap:"5px"}}>
                                <a href={playlist.channel.link}><img style={{width:"30px",height:"30px",borderRadius:"100px"}} src={playlist.thumbnails[0].url}></img></a>
                                <a href={playlist.channel.link}><p style={{position:"relative",top:"5px",color:"white",textDecoration: "underline"}}>{playlist.channel.name}</p></a>
                                <p></p>    
                            </div> 
                        </div>
                    </div>
                    </a>


                 )})}
            </div>
            }



            {sendingmessage === true &&
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"30px"}}>
            <iframe title="Youtube Media" style={{borderRadius:"10px",width:maxRowBased ? "1300px":"300px",height:maxRowBased ? "1000px":"500px"}} 
            src={
                getVideo === true ?
                `https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getvideo?url=${message}`
                :
                `https://caesaraiyoutube-qqbn26mgpa-uc.a.run.app/getaudio?url=${message}`
            } >

            </iframe>
            </div>}


        </div>
    )
}