
import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import axios from "axios"
import { moviekeys } from "../moviekeys"
import { useEffect, useState } from "react"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SeriesCard from "./seriescard"
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import ScrollToAnchor from "../ScrollToAnchor"
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium"
export default function AmariSeries(){
    const media = useMediaQuery(maxRowBasedquery)
    const navigate = useNavigate()
    const [upcomingfilms,setUpcomingFilms] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    let [pagenum,setPageNum] = useState(searchParams.get("page") === null ? 1 : parseInt(searchParams.get("page")))
    const [moviesearchquery,setMovieSearchQuery] = useState("");
    const [pagecarousel,setPageCarousel] = useState([0,1,2,3])
    const [searchpagecarousel,setSearchPageCarousel] = useState([0,1,2,3])
    const [searchresults,setSearchResults] = useState([]);
    const [searchpagenum,setSearchPageNum] = useState(1);

    const getupcomingfilms = async () =>{
        const config = {
            headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
        };
        const response = await axios.get(`https://api.themoviedb.org/3/tv/top_rated?language=en-US&page=${pagenum}`,config)
        let result = response.data
        //console.log(result)
        
        setUpcomingFilms(result.results)
    }
    useEffect(() =>{
        getupcomingfilms()
    },[])

    const getsearchresults = async (e,usingcarousel=false) =>{
        if (e !== undefined){
            e.preventDefault();
        }
        if (moviesearchquery !== ""){
            const config = {
                headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
            };
            
            const response = await axios.get(`https://api.themoviedb.org/3/search/tv?query=${moviesearchquery}&language=en-US&page=${searchpagenum}`,config)
            let result = response.data
           
            if (result.results.length > 0){
            setSearchResults(result.results)
            if (usingcarousel === false){
                navigate({
                    pathname: '/amariseries',
                    search: `?page=${pagenum}`,
                    hash:"#search_results"
                  });
            }

            }
            
        }

    }

    const navleft = () =>{
        if (pagenum !== 1){
            setPageNum(pagenum-1)
            navigate({
                pathname: '/amariseries',
                search: `?page=${pagenum-1}`,
              });
        }

    }
    const navright = () =>{
        //console.log("hi",pagenum)
        setPageNum(pagenum+1)
        navigate({
            pathname: '/amariseries',
            search: `?page=${pagenum+1}`,
          });

    }
    const navpick = (index) =>{
        if (pagenum !== index){
            //console.log("ho",index)
            setPageNum(index)
            navigate({
                pathname: '/amariseries',
                search: `?page=${index}`,
              });
        }


    }
    const searchnavleft = () =>{
        if (searchpagenum !== 1){
            setSearchPageNum(searchpagenum -1)
        }
    }
    const searchnavright = () =>{
        setSearchPageNum(searchpagenum +1)
    }
    const seacrhnavpick = (index) =>{
        if (pagenum !== index){
            //console.log("ho",index)
            setSearchPageNum(index)
        }


    }
    

    useEffect(() =>{
        getupcomingfilms()
    },[pagenum])

    useEffect(() =>{
        getsearchresults(undefined,true)
    },[searchpagenum])

    
    return(
        <div style={{backgroundColor:"#1e1e1e",height:"100%",overflow: "auto"}}>
            <a onClick={() =>{navigate("/wishlist")}} style={{cursor:"pointer",zIndex:"2"}}><WorkspacePremiumIcon style={{color:"white",position:"absolute",right:media? "170px":"50px",top:"30px",fontSize:"40px"}}/></a>
            <div style={{position:"relative",top:"100px"}}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"30px"}}
            >

                <h1 style={{color:"white"}}>Amari Movies</h1>
                <div>
                    <button onClick={() =>{navigate("/amarimovies")}} style={{color:"white"}}>Movies</button>
                    <button onClick={() =>{navigate("/amarianime")}} style={{color:"white"}}>Anime</button>
                    <button  style={{color:"white",backgroundColor:"blue",borderRadius:"5px",width:"100px"}}>Series</button>
                </div>
                <h2 style={{color:"white"}}>Upcoming Movies</h2>
 

            </div>    

            <div style={{display:"flex",gap:"20px",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                <a onClick={()=>{navleft()}}><KeyboardArrowLeftIcon style={{color:"white",cursor:"pointer"}}/></a>
                {pagecarousel.map((index) =>{
                    return( <div style={{backgroundColor:index+ pagenum === pagenum ? "blue" :"transparent",borderRadius:"5px",padding:"5px"}}><a style={{color:"white",cursor:"pointer"}} onClick={() =>{navpick(index+ pagenum)}}>{index + pagenum}</a></div>)
                }) }
                <a onClick={()=>{navright()}}><KeyboardArrowRightIcon style={{color:"white",cursor:"pointer"}}/></a>
            </div>  

            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"10px"}}>
                
                <form onSubmit={(e)=>{getsearchresults(e)}}>
                    <input style={{width:media ? "500px":"300px"}} onChange={(e) =>{setMovieSearchQuery(e.target.value)}} value={moviesearchquery}></input>
                </form>
                <div onClick={(e) =>{getsearchresults(e)}} style={{backgroundColor:"blue",borderTopRightRadius:"2px",borderBottomRightRadius:"2px",height:"30px",cursor:"pointer"}}><KeyboardArrowRightIcon style={{color:"white"}}></KeyboardArrowRightIcon></div>
                
            </div>


            <div style={{display:"flex",flexWrap:"wrap",gap:"60px",marginLeft:media ? "150px" :"40px",marginTop:"50px"}}>
                {upcomingfilms.length > 0 && 
                upcomingfilms.map((film,index) =>{ return(
                    <SeriesCard key={index} film={film}/>


                )})
                }
            </div>
            {
                searchresults.length > 0 ? 
                <div>

            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"30px"}}
                >
    
                    <h2 id="search_results" style={{color:"white"}}>Search Results</h2>
       
    
                </div>  
                <div style={{display:"flex",gap:"20px",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                    <a onClick={()=>{searchnavleft()}}><KeyboardArrowLeftIcon style={{color:"white",cursor:"pointer"}}/></a>
                    {searchpagecarousel.map((index) =>{
                        return( <div style={{backgroundColor:index+ searchpagenum === searchpagenum ? "blue" :"transparent",borderRadius:"5px",padding:"5px"}}><a style={{color:"white",cursor:"pointer"}} onClick={() =>{seacrhnavpick(index+ searchpagenum)}}>{index + searchpagenum}</a></div>)
                    }) }
                    <a onClick={()=>{searchnavright()}}><KeyboardArrowRightIcon style={{color:"white",cursor:"pointer"}}/></a>
                </div>   
                <div style={{display:"flex",flexWrap:"wrap",gap:"60px",marginLeft:media ? "150px" :"40px",marginTop:"50px"}}>
                            {
                            searchresults.map((film,index) =>{ return(
                                <SeriesCard key={index} film={film}/>
            
            
                            )})
                            }
                </div>

                </div>

                :
                <div></div>
            }




            </div>
            <ScrollToAnchor/>

        </div>
    )
}