// React
import React from 'react';
// CSS Styling
import "bootstrap/dist/css/bootstrap.min.css";
import './scrollbar.css'

import FrameworkComponent from './frameworktechniquesmedia';
import Projects from './projectscomponentmedia';
import HeaderBar from './headerbarmedia';
import Skills from './skillscomponentmedia';
import ContactMe from './contactmecomponentsmedia';
import AmariWidget from './amariwidget/amariwidget';
import { useEffect } from 'react';
import axios from 'axios';
const FullMedia = () => {
  const wakeup = async () =>{
    const responsewakeup = await axios.get("https://caesaraiconsumet.fly.dev")
  }
    useEffect(()=>{
      wakeup()
    },[])
    return (
      <header style={{width:"100%"}}>

      <HeaderBar/>
      <FrameworkComponent/>
      <Projects/>
      <AmariWidget/> 
      <Skills/>
      <ContactMe/>

      
  
  
      
      
      </header>
      
    );
  };
  
  export default FullMedia;
  
