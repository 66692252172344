import React, { useState } from "react";
import { useEffect } from "react";

import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../../../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { generateStreamPost } from "../../getStream";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CaesarAILogo from  "../../CaesarAILogo 2.svg"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CSVLogo from "./caesaraicsv.png"
import CaesarAIAPIRoutes from "../../CaesarAIAPIRoutes";
import { useParams } from "react-router";
import { formatPromptText } from "../formatPromptText";
import { CaesarAIAPIRoutesData } from "../../caesaraiapiroutedata";
import StopCircleIcon from '@mui/icons-material/StopCircle';
export default function CaesarAICSV(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const navigate =  useNavigate();
    const location = useLocation();
    const [caesarairoutes,setCaesarAIRoutes] = useState(CaesarAIAPIRoutesData)//.filter((routedata) =>{return(routedata.route.replace(routedata.parentroute,"") !== location.pathname.split("/").slice(-1)[0])}));
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const [message,setMessage] = useState("");
    const [usermessages,setUserMessages] = useState([])
    const [caesaraisanswers,setCaesarAIAnswers] = useState([])
    const [sendingmessage,setSendingMessage] = useState(false)
    const [hideinput,setHideInput]  = useState(false)

    const sendmessage = async (e) =>{
        setSendingMessage(true)
        let file =e.target.files[0]
        const formdata = new FormData()
        if (message !== ""){
            formdata.append("file",file)
            formdata.append("message",message)
            const stream = await generateStreamPost(`https://caesaraigemini-qqbn26mgpa-uc.a.run.app/sendcsv`,formdata)
            let caesarsanswer = ""
            for await (const chunk of stream) {
                console.log()
                try{
                    if ("error" in JSON.parse(chunk)){
                        alert(JSON.parse(chunk).error)
                    }

                    }
                catch{
                    //console.log(chunk)
                    //console.log(chunk)
                    caesarsanswer += chunk
                    setUserMessages(usermessages.concat([message]))
                    setCaesarAIAnswers(caesaraisanswers.concat([caesarsanswer]))
                    
                    
                    //history[history.length]["CaesarAI"] = caesarsanswer
                    //setHistory(history)
                    }

                

            }
                    //console.log(caesarsanswer)
            //console.log("stream done");
            setMessage("")
            setSendingMessage(false)
 
        }
        





    }


 
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <CaesarAIAPIRoutes caesarairoutes={caesarairoutes}/>
  




            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"80px":"30px"}}>
               s <h1 style={{color:"white"}}>CaesarAI</h1>
                <img style={{width:"100px",height:"100px",position:"relative",bottom:"25px"}} src={CSVLogo}></img>
            </div>


            {
                usermessages.length  === 0 ?
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"70px"}}>
                <img style={{width:maxRowBased ? "auto":"200x",height: maxRowBased ? "auto":"200px"}} src={CaesarAILogo}></img>
                </div>
                :
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"70px"}}>
                    <div style={{width:"1000px",borderRadius:"5px",padding:"10px"}}>
                    {caesaraisanswers.map((session,ind) =>{return(
                        <div key={ind}>
                            <br></br>
                            <div style={{display:"flex",gap:"5px",color:"white"}}>
                            <AccountCircleIcon/><p><strong>User: </strong>{usermessages[ind]}</p>
                            </div>
                            <div style={{display:"flex",gap:"5px",color:"white"}}>
                                <img style={{width:"25px",height:"25px"}} src={CaesarAILogo}></img>
                                <p><strong>CaesarAI:</strong> {formatPromptText(session)}</p>
                            </div>
                    
                        </div>
                    )})}
                    </div>
                </div>
            }
                    
            {
                hideinput === false ?
                <div style={{  position: "absolute",bottom: "150px",width: "100%",display: "flex",justifyContent: "center"}}>
                <a onClick={() =>{setHideInput(true)}} style={{color:"white",position:"relative",right:"5px",top:"6px",cursor:"pointer"}}><VisibilityIcon/></a>
                <div style={{padding:"5px",display:"flex",border:"1px solid white",borderRadius:"5px",width:maxRowBased ? "700px":"300px",backgroundColor:"#1e1e1e"}}>
                   
                    <div >
                        {
                            message.length < 200 
                            ?
                            <input  onChange={(e) =>{setMessage(e.target.value)}} value={message} style={{border:"0px solid white",backgroundColor:"#1e1e1e",color:"white",width:maxRowBased ? "640px" :"260px",outline: "none"}} placeholder="Message CaesarAI about your CSV..."></input>
                            
                            :
                            <textarea  onChange={(e) =>{setMessage(e.target.value)}} value={message} style={{border:"0px solid white",backgroundColor:"#1e1e1e",color:"white",width:maxRowBased ? "640px" :"260px",outline: "none",height:"200px"}} placeholder="Message CaesarAI..."></textarea>
                            
                        }
                    </div>
                    {
                        sendingmessage === false ?
                        <div style={{marginLeft:"auto"}}>
                        <input accept=".csv" onChange={(e) =>{sendmessage(e)}} type="file" id="actual-btn" hidden/>

  
                        <label htmlFor="actual-btn"  style={{width:"30px",height:"30px",backgroundColor:"white",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer"}}>
                        <strong><p style={{color:"#1e1e1e",position:"relative",top:"6px",fontSize:"30px"}}><ArrowUpwardIcon/></p></strong>
                        </label>
                        </div>
     
                    :  
                    <div style={{width:"30px",height:"30px",backgroundColor:"#1e1e1e" ,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",marginLeft:"auto"}}>
                        <strong><a onClick={() =>{setHideInput(false);setMessage("");setSendingMessage(false);setUserMessages([]);setCaesarAIAnswers([])}} style={{color:"white"}}><StopCircleIcon/></a></strong>
                    </div>
                    }
                
                </div>

            </div>:
            <div style={{  position: "absolute",bottom: "50px",width: "100%",display: "flex",justifyContent: "center"}}>
                <a onClick={() =>{setHideInput(false)}} style={{color:"white",cursor:"pointer"}}><VisibilityOffIcon/></a>
            </div>
            }
            <div style={{  position: "absolute",bottom: "100px",width: "100%",display: "flex",justifyContent: "center"}}>
                <p style={{color:"white",position:"relative",left:maxRowBased ? "200px":"0px"}}>CSV column called 'statements' needed.</p>

            </div>
            <div style={{  position: "absolute",bottom: "80px",width: "100%",display: "flex",justifyContent: "center"}}>
            <p style={{color:"white",position:"relative",left:maxRowBased ? "300px":"0px"}}>max 30 rows </p>
            </div>




        </div>
    )
}