import { useNavigate,useLocation } from "react-router"
import { useState } from "react";
export default function AmariSecurity(){
    const location = useLocation();
    const [nextroute,setNextroute] = useState(location.search.replace("?h=",""))
    const [password,setPassword] = useState("");
    const navigate = useNavigate();
    const authorized = () =>{
        if (password === "kya63amari"){
            console.log("ho")
            navigate(`/${nextroute}`,{state:{"authed":"true"}})
        }
        else{
            alert("Wrong Password!")
        }
    }

    return(
    <div>
        <input placeholder="Enter Password:" type="password" onChange={(e) =>{setPassword(e.target.value)}} value={password}></input>
        <button onClick={()=>{authorized()}}>Submit</button>
    </div>)
}