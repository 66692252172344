import React, { useState } from "react";
import { useEffect } from "react";
import logo from '../imgs/Amari-logo.svg'
import axios from "axios"; 
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { generateStream } from "./getStream";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CaesarAILogo from  "./CaesarAILogo 2.svg"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import CaesarAIAPIRoutes from "./CaesarAIAPIRoutes";
import { CaesarAIAPIRoutesData } from "./caesaraiapiroutedata";
import { formatPromptText } from "./caesaraiapis/formatPromptText";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ReactMarkdown from 'react-markdown'
export default function CaesarAI(){
    // Generate Blender Model in React -> https://gltf.pmnd.rs/
    const navigate =  useNavigate();
    const [caesarairoutes,setCaesarAIRoutes] = useState(CaesarAIAPIRoutesData.filter((routedata,ind) =>{return(ind != 0)}));
    const maxRowBased = useMediaQuery(maxRowBasedquery)
    const [message,setMessage] = useState("");
    const [usermessages,setUserMessages] = useState([])
    const [caesaraisanswers,setCaesarAIAnswers] = useState([])
    const [sendingmessage,setSendingMessage] = useState(false)
    const [hideinput,setHideInput]  = useState(false)

    const sendmessage = async () =>{
        setSendingMessage(true)
        //setHistory(history.concat([{"user":"","CaesarAI":""}]))
        //history[history.length ]["user"] = message
        const stream = await generateStream(`https://caesaraigemini-qqbn26mgpa-uc.a.run.app/sendmessage?message=${message}`)
        let caesarsanswer = ""
        for await (const chunk of stream) {
            //console.log(chunk)
            caesarsanswer += chunk
            setUserMessages(usermessages.concat([message]))
            setCaesarAIAnswers(caesaraisanswers.concat([caesarsanswer]))
            
            
            //history[history.length]["CaesarAI"] = caesarsanswer
            //setHistory(history)
        }
        
        //console.log(caesarsanswer)
        //console.log("stream done");
        setMessage("")
        setSendingMessage(false)



    }

    const testHTML = (session) =>{
       return /<\/?[a-z][\s\S]*>/i.test(session)
    }
    
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <CaesarAIAPIRoutes caesarairoutes={caesarairoutes}/>





            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:maxRowBased?"80px":"30px"}}>
               s <h1 style={{color:"white"}}>CaesarAI</h1>
            </div>


            {
                usermessages.length  === 0 ?
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"70px"}}>
                <img style={{width:maxRowBased ? "auto":"200x",height: maxRowBased ? "auto":"200px"}} src={CaesarAILogo}></img>
                </div>
                :
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"70px"}}>
                    <div style={{width:"1000px",borderRadius:"5px",padding:"10px"}}>
                    {caesaraisanswers.map((session,ind) =>{return(
                        <div key={ind}>
                            <br></br>
                            <div style={{display:"flex",gap:"5px",color:"white"}}>
                            <AccountCircleIcon/><p><strong>User: </strong>{usermessages[ind]}</p>
                            </div>
                            <div style={{display:"flex",gap:"5px",color:"white"}}>
                                <img style={{width:"25px",height:"25px"}} src={CaesarAILogo}></img>
                                <p ><strong>CaesarAI:</strong> 
                                
                                <pre style={{textWrap:"wrap"}}><ReactMarkdown>{session}</ReactMarkdown></pre>
                                </p>
                                

                            </div>
                    
                        </div>
                    )})}
                    </div>
                </div>
            }
                    
            {
                hideinput === false ?
                <div style={{  position: "absolute",bottom: "150px",width: "100%",display: "flex",justifyContent: "center"}}>
                <a onClick={() =>{setHideInput(true)}} style={{color:"white",position:"relative",right:"5px",top:"6px",cursor:"pointer"}}><VisibilityIcon/></a>
                <div style={{padding:"5px",display:"flex",border:"1px solid white",borderRadius:"5px",width:maxRowBased ? "700px":"300px",backgroundColor:"#1e1e1e"}}>
                   
                    <form onSubmit={(e) =>{e.preventDefault();sendmessage()}}>
                        {
                            message.length < 200 
                            ?
                            <input  onChange={(e) =>{setMessage(e.target.value)}} value={message} style={{border:"0px solid white",backgroundColor:"#1e1e1e",color:"white",width:maxRowBased ? "640px" :"260px",outline: "none"}} placeholder="Message CaesarAI..."></input>
                            :
                            <textarea  onChange={(e) =>{setMessage(e.target.value)}} value={message} style={{border:"0px solid white",backgroundColor:"#1e1e1e",color:"white",width:maxRowBased ? "640px" :"260px",outline: "none",height:"200px"}} placeholder="Message CaesarAI..."></textarea>
                            
                        }
                    </form>
                    {
                        sendingmessage === false ?
                        <div onClick={() =>{if(message.length > 0){sendmessage()}}} style={{width:"30px",height:"30px",backgroundColor:message.length === 0 ? "grey" : "white",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",marginLeft:"auto"}}>
                        <strong><p style={{color:"#1e1e1e",position:"relative",top:"6px",fontSize:"30px"}}><ArrowUpwardIcon/></p></strong>
                    </div>
                    :  
                    <div style={{width:"30px",height:"30px",backgroundColor:"#1e1e1e" ,display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px",cursor:"pointer",marginLeft:"auto"}}>
                        <strong><a onClick={() =>{setHideInput(false);setMessage("");setSendingMessage(false);setUserMessages([]);setCaesarAIAnswers([])}} style={{color:"white"}}><StopCircleIcon/></a></strong>
                    </div>
                    }
                
                </div>
            </div>:
            <div style={{  position: "absolute",bottom: "50px",width: "100%",display: "flex",justifyContent: "center"}}>
                <a onClick={() =>{setHideInput(false)}} style={{color:"white",cursor:"pointer"}}><VisibilityOffIcon/></a>
            </div>
            }
            



        </div>
    )
}