
import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import { useLocation,useNavigate } from "react-router";
import { useState } from "react";
import { moviekeys } from "../moviekeys"
import axios from "axios";
import ReactPlayer from "react-player";
import { useEffect } from "react";
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VortexLogo from "../../imgs/AmariMovies/Vortex.png"
import VortexBlankLogo from "../../imgs/AmariMovies/VortexBlank.png"
import Episode from "./episode";
export default function AmariAnimeEpisodes(){
    const location = useLocation();
    const navigate = useNavigate()
    const media = useMediaQuery(maxRowBasedquery)
    const [searchparams,setSearchParams] = useState(location.search)
    const [season,setSeason] = useState({});
    const [episodes,setEpisodes] = useState([])
    const [video,setVideo] = useState("");
    const [currentEpisode,setCurrentEpisode] = useState("");

    const getfilmdetails = async () =>{
        let animeid = searchparams.split("=")[1]
        const response = await axios.get(`https://caesaraiconsumet.fly.dev/anime/gogoanime/info/${animeid}`)
        let result = response.data
        console.log(result)

        setEpisodes(result.episodes)

        setSeason(result)

   

        
    }

    useEffect(() =>{
        getfilmdetails()
        
    },[])
    return(
        <div style={{backgroundColor:"#1e1e1e",height:window.innerHeight,overflow:"auto",justifyContent:"center",alignItems:"center",display:"flex"}}>
            <div >
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                {currentEpisode !== "" && <p style={{color:"white"}}>Episode: {currentEpisode}</p>}
   
                {video !== "" && !media &&
                <ReactPlayer
                style={{marginTop:"300px"}}
                width={400}
                height={300}
                playing={true}
                controls={true}
                url={video}
                ></ReactPlayer>
                }
                </div>
   
                <div style={{display:"flex",gap:"0px",justifyContent:media ? "flex-start" : "center",alignItems:media ?"flex-start" :"center"}}>
                                    

                    <div style={{width:media ? "300px" :"150px"}}>
                    <img  src={season.image} style={{width:media ? "300px" :"150px",height:media ? "400px" :"250px",cursor:"pointer",borderRadius:"5px"}}></img>
                        <h3 style={{color:"white"}}>{season.title}</h3>
                        <p style={{color:"white"}}>Total Episodes:{season.totalEpisodes}</p>
                        <div style={{display:"flex",flexWrap:"wrap"}}>
                        {episodes.length !== 0 && episodes.map((episode) =>{
                            return(
                                <Episode episodeid={episode.id} number={episode.number} setVideo={setVideo} setCurrentEpisode={setCurrentEpisode}/>
                                
                            )

                        })}
                        </div>
                        <h3 style={{marginTop:"10px",color:"white"}}>Description:</h3>
                        <div style={{maxHeight:300,overflowY:"scroll"}}>
                            <p style={{color:"white"}}>{season.description}</p>
                        </div>
                        

                    </div>

        
            {video !== "" && media &&
                <ReactPlayer
                width={1500}
                height={800}
                playing={true}
                controls={true}
                url={video}
                ></ReactPlayer>
                }

                </div>
            </div>
 
            


        </div>
    )
}