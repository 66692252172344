import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";


const ModelViewer = ({ modelPath, EngineModel,style={}}) => {
  return (
    <Canvas style={style} camera={{ fov: 64, position: [-2, 2, 0] }}>
        <ambientLight intensity={5} />
        <OrbitControls enableZoom={true} />
        <Suspense fallback={null}>
        <EngineModel  modelPath={modelPath} />
        </Suspense>
    </Canvas>
  );
};

export default ModelViewer;