import ModelViewer from "./enginecanvas";
export default function ModelCard({modelcard,index,modelPath,width="760px",height="800px",title="",description=""}){
    return (
        <div key={index}>
            <div style={{width:width,height:"50px",backgroundColor:"white",border:"1px solid black",borderTopRightRadius:"10px",borderTopLeftRadius:"10px"}}>
                <div style={{padding:"10px"}}>
                    {title}
                </div>
            </div>
            
            <div style={{width:width,height:height,backgroundColor:"#141212",border:"10px #002772 solid"}}>
                <div style={{padding:"20px",width:"100%",height:"100%"}}>
                    <ModelViewer EngineModel={modelcard} modelPath={modelPath}/>
                </div>
            </div>
            <div style={{width:width,height:"100px",backgroundColor:"white",border:"1px solid black",borderBottomRightRadius:"10px",borderBottomLeftRadius:"10px"}}>
            <div style={{padding:"10px"}}>
                    {description}
                </div>
            </div>
        </div>
    )
}