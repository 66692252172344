
import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import { useLocation,useNavigate } from "react-router";
import { useState } from "react";
import { moviekeys } from "../moviekeys"
import axios from "axios";
import { useEffect } from "react";
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VortexLogo from "../../imgs/AmariMovies/Vortex.png"
import VortexBlankLogo from "../../imgs/AmariMovies/VortexBlank.png"
export default function AmariAnimeSeasons(){
    const location = useLocation();
    const navigate = useNavigate()
    const media = useMediaQuery(maxRowBasedquery)
    const [film,setFilm] = useState(location.state)
    const [seasons,setSeasons] = useState([])
    const [description,setDescription] = useState("");
    const [usetorrentanime,setUseTorrentAnime] = useState(false);

    const reorderpecials = (seasons) => {
        let specials = []
        for(var i = 0; i < seasons.length; i++) {
            if (seasons[i].name.includes("Special")){
    
                var index = seasons.indexOf(seasons[i]);
                if (index !== -1) {
                    specials.push(seasons[i])
                    seasons.splice(index, 1);
                    
                  }
            }
        }
        
        return seasons.concat(specials)

    }
    const getfilmdetails = async () =>{
        //console.log(film)
        const config = {
            headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
        };
        const response = await axios.get(`https://caesaraiconsumet.fly.dev/anime/gogoanime/${film.name}?page=1`,config)
        let result = response.data
        let seasons = result.results
        console.log(seasons)
        seasons.sort(function(a,b) {
         
            let bval = parseInt(b.releaseDate.replace(/^\D+/g, '').trim())
            let aval = parseInt(a.releaseDate.replace(/^\D+/g, '').trim())
            return aval- bval 
        })
       // console.log(seasons)
        //`https://image.tmdb.org/t/p/original/${season.poster_path}`
        setSeasons(seasons)
        //setNumOfEpisodes(result.number_of_episodes)
        //let seasons = result.seasons
        //seasons = reorderpecials(seasons)
        //setSeasons(seasons)
        setDescription(result.overview)
        
    }
    const navepisodes = async (id,url) =>{
        console.log(url)

        navigate({
            pathname: '/amarianimeepisodes',
            search: `?animeid=${id}`,
            
          });

    }
    useEffect(() =>{
        getfilmdetails()
        
    },[])
    return(
        <div style={{backgroundColor:"#1e1e1e",height:window.innerHeight,overflow:"auto"}}>
            <div style={{position:"relative",top:"100px"}}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"2px"}}
            >

                <h1 style={{color:"white"}}>{film.name}</h1>
                <img  src={`https://image.tmdb.org/t/p/original/${film.poster_path}`} style={{width:media ? "300px" :"150px",height:media ? "400px" :"250px",cursor:"pointer",borderRadius:"5px"}}></img>
                <p style={{color:"white",margin:"30px",width:media ? "700px":"auto"}}>{description}</p>
                {/*
                <div>
                    
                    <a onClick={() =>{window.open(`https://anix.to/filter?keyword=${film.name}`)}} style={{color:"white",cursor:"pointer"}}>
         
                        <VideocamIcon style={{fontSize:"30px"}}/>
                        
                        <ArrowForwardIcon/>
                    </a>
                    { usetorrentanime === false ?
                    <a onClick={() =>{setUseTorrentAnime(true)}}>
                    <img style={{width:"50px",height:"50px",cursor:"pointer",marginLeft:"30px"}} src={VortexBlankLogo}></img>
                    </a>:
                    <a onClick={() =>{setUseTorrentAnime(false)}}>
                    <img style={{width:"50px",height:"50px",cursor:"pointer",marginLeft:"30px"}} src={VortexLogo}></img>
                    </a>
                    }

                    
                </div>*/}

 

            </div>  
            <div style={{display:"flex",borderRadius:"5px",padding:"10px",gap:"20px",justifyContent:"center",alignItems:"center",flexWrap:"wrap",marginTop:film.original_language === "ja" ? "30px":"0px"}}>
                    {seasons.length > 0 && seasons.map((season) =>{
                        //console.log(season)
                        return(
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <a onClick={() =>{navepisodes(season.id,season.image)}}>
                                <img src={season.image} style={{width:media ? "300px" :"150px",height:media ? "400px" :"250px",cursor:"pointer",borderRadius:"5px"}}></img>
                                <p style={{marginTop:"4px",color:"white",width:media ? "300px" :"150px"}}>{season.title}</p>
                                <p style={{width:media ? "300px":"100px",color:"white"}}>Release Date: {season.releaseDate}</p>
                                <p style={{color:"white"}}>Sub/Dub: {season.subOrDub.toUpperCase()}</p>
 
                                </a>
 

                            </div>
                        )
                    })}
                </div>  







        </div>
            


        </div>
    )
}