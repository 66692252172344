// APP COMPONENT
// Upon rendering of App component, make a request to create and
// obtain a link token to be used in the Link component
import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
const AmariPlaid = () => {
  const [linkToken, setLinkToken] = useState(null);
  const generateToken = async () => {
    const response = await fetch('https://amarifinancepots-qqbn26mgpa-nw.a.run.app/api/create_link_token', {
      method: 'POST',
    });
    const data = await response.json();
    //console.log(data)
    setLinkToken(data.link_token);
  };
  useEffect(() => {
    generateToken();
  }, []);
  return linkToken != null ? <Link linkToken={linkToken} /> : <></>;
};
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link

const Link= (props) => {
  const [access_token, setAccessToken] = useState("");
  const onSuccess = React.useCallback(async (public_token, metadata) => {
    // send public_token to server
    const response = await fetch('https://amarifinancepots-qqbn26mgpa-nw.a.run.app/api/set_access_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_token }),
    });
    const data = await response.json();
    console.log(data)
    console.log(data.access_token)
    setAccessToken(data.access_token)

    // Handle response ...
  }, []);
  const config = {
    token: props.linkToken,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);
  return (
    <div>
          <button onClick={() => open()} disabled={!ready}>
      Link account
    </button>
      {access_token !== "" && <p>{access_token}</p>}
    </div>

  );
};
export default AmariPlaid;