export default function MonthlyPortfolio(){
    return (`
    <div align="center">
    <table border="0" cellpadding="0" width="640" style="width:480.0pt;vertical-align:central">
    <tbody>
    <tr>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <div align="center">
    <table border="1" cellspacing="0" cellpadding="0" width="600" style="width:450.0pt;background:white;border-collapse:collapse;border:none;vertical-align:central">
    <tbody>
    <tr>
    <td style="border:solid #d1d1d1 1.0pt;padding:0cm 0cm 0cm 0cm">
    <div align="center">
    <table border="0" cellspacing="0" cellpadding="0" width="560" style="width:420.0pt;background:white;border-collapse:collapse;border-color:none">
    <tbody>
    <tr>
    <td colspan="5" style="padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td width="273" style="width:204.75pt;padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="border:solid windowtext 1.0pt;padding:0cm"><img width="233" height="42" style="width:2.427in;height:.4375in" id="m_3587043361144361928_x0000_i1034" alt="Image removed by sender."></span></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="45" style="width:33.75pt;padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="42" style="width:31.5pt;padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="45" style="width:33.75pt;padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="183" style="width:137.25pt;padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black;border:solid windowtext 1.0pt;padding:0cm"><img width="600" height="250" style="width:6.25in;height:2.6041in" id="m_3587043361144361928_x0000_i1033" alt="Image removed by sender. We've confirmed your module enrolement"></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:0cm 0cm 0cm 0cm">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;display:none"><u></u>&nbsp;<u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="600" style="width:450.0pt;background:white;border-color:none">
    <tbody>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="532" style="width:399.0pt;padding:.75pt .75pt 0cm .75pt">
    <p style="line-height:21.75pt" id="m_3587043361144361928u140-2">
    <b><span style="font-size:13.5pt;font-family:&quot;Arial&quot;,sans-serif;color:#616161;letter-spacing:.75pt">Hello Amari,
    <u></u><u></u></span></b></p>
    <p class="m_3587043361144361928paragraph-style">
    We’re looking forward to welcoming you on to your new module with us as part of your Apprenticeship.<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    This email has the dates for your workshop dates and the steps you need to take to make sure you are prepared for your first session.<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Details of how to access each session will be emailed to you, at this email address, shortly before it is due to commence.<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    We will also email you an Apprenticeship guide to online learning before your studies start – please make sure you have read through this in advance of your first scheduled session.<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    If you need to speak to our team, you can contact us via the following:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    For programme or module queries:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Email - <a href="mailto:QAADegreeAdmin@qa.com" target="_blank"><b><span style="border:none windowtext 1.0pt;padding:0cm">QAADegreeAdmin@qa.com</span></b></a><u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    For technical assistance setting up or accessing your online learning, please use
    <a href="https://v2.zopim.com/widget/popout.html?key=5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://v2.zopim.com/widget/popout.html?key%3D5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj&amp;source=gmail&amp;ust=1702030154958000&amp;usg=AOvVaw18KrCQhbxEOhhdoXOf5tAv">
    <b><span style="border:none windowtext 1.0pt;padding:0cm">Live Chat</span></b></a> or:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Email - <a href="mailto:VirtualLearning@qa.com" target="_blank"><b><span style="border:none windowtext 1.0pt;padding:0cm">VirtualLearning@qa.com</span></b></a><u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Phone - 020 3908 2376 (option 2)<u></u><u></u></p>
    </td>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;display:none"><u></u>&nbsp;<u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="600" style="width:450.0pt;background:white;border-color:none">
    <tbody>
    <tr>
    <td colspan="5" style="padding:.75pt .75pt 0cm .75pt">
    <p align="center" style="margin-right:0cm;margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:32.25pt" id="m_3587043361144361928u146-4">
    <span style="font-size:27.0pt;font-family:&quot;Arial&quot;,sans-serif;color:#4590ce">Your course booking<u></u><u></u></span></p>
    <p style="margin:0cm;line-height:12.75pt">
    <span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="139" valign="top" style="width:104.25pt;padding:.75pt .75pt 0cm .75pt">
    <p class="m_3587043361144361928paragraph-style" style="line-height:18.0pt" id="m_3587043361144361928u246-2">
    <span style="font-size:11.5pt;color:#4590ce">COURSE<u></u><u></u></span></p>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black"><br>
    <span style="border:solid windowtext 1.0pt;padding:0cm"><img border="0" width="40" height="34" style="width:.4166in;height:.3541in" id="m_3587043361144361928u254_img" alt="Image removed by sender."></span></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="37" style="width:27.75pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="348" valign="top" style="width:261.0pt;padding:.75pt .75pt 0cm .75pt">
    <p style="line-height:20.25pt" id="m_3587043361144361928u148-2">
    <b><span style="font-size:13.0pt;font-family:&quot;Arial&quot;,sans-serif;color:#2d2d2d">Professional Practice &amp; Portfolio Development 1 (DTS Enhanced 3yr L4)<u></u><u></u></span></b></p>
    <p class="m_3587043361144361928paragraph-style" id="m_3587043361144361928u155-5">
    ADAXDTSPPD1<u></u><u></u></p>
    </td>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:.75pt .75pt 0cm .75pt">
    <p style="line-height:12.75pt">
    <span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="500" style="width:375.0pt">
    <tbody>
    <tr>
    <td style="border:none;border-top:solid #d1d1d1 1.0pt;padding:11.25pt .75pt 11.25pt .75pt">
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    </td>
    </tr>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="139" valign="top" style="width:104.25pt;padding:.75pt .75pt 0cm .75pt">
    <p class="m_3587043361144361928paragraph-style" style="line-height:18.0pt" id="m_3587043361144361928u245-2">
    <span style="font-size:11.5pt;color:#4590ce">WHO'S GOING?<u></u><u></u></span></p>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black"><br>
    <span style="border:solid windowtext 1.0pt;padding:0cm"><img border="0" width="40" height="34" style="width:.4166in;height:.3541in" id="m_3587043361144361928u158_img" alt="Image removed by sender."></span></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="37" style="width:27.75pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="348" valign="top" style="width:261.0pt;padding:.75pt .75pt 0cm .75pt">
    <p class="m_3587043361144361928paragraph-style" id="m_3587043361144361928u155-5">
    <b><span style="font-size:13.0pt;color:#2d2d2d">Amari Lawal</span></b><br>
    ref. 10836471<u></u><u></u></p>
    </td>
    <td style="padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td colspan="5" style="padding:.75pt .75pt 0cm .75pt">
    <p style="line-height:12.75pt">
    <span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="500" style="width:375.0pt">
    <tbody>
    <tr>
    <td style="border:none;border-top:solid #d1d1d1 1.0pt;padding:11.25pt .75pt 11.25pt .75pt">
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    </td>
    </tr>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="139" valign="top" style="width:104.25pt;padding:.75pt .75pt 0cm .75pt">
    <p class="m_3587043361144361928paragraph-style" style="line-height:18.0pt" id="m_3587043361144361928u248-2">
    <span style="font-size:11.5pt;color:#4590ce">DATES &amp; TIMES<u></u><u></u></span></p>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black"><br>
    <span style="border:solid windowtext 1.0pt;padding:0cm"><img border="0" width="40" height="34" style="width:.4166in;height:.3541in" id="m_3587043361144361928u164_img" alt="Image removed by sender."></span></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="37" style="width:27.75pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="348" valign="top" style="width:261.0pt;padding:.75pt .75pt 0cm .75pt">
    <p class="m_3587043361144361928paragraph-style" id="m_3587043361144361928u194-5">
    <b><span style="font-size:13.0pt;color:#2d2d2d">09 February 2024 at 09:30<br>
    </span></b>QA On-Line Virtual Centre<br>
    0.5 day<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style" id="m_3587043361144361928u194-5">
    <b><span style="font-size:13.0pt;color:#2d2d2d">01 March 2024 at 09:30<br>
    </span></b>QA On-Line Virtual Centre<br>
    0.5 day<u></u><u></u></p>
    </td>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;display:none"><u></u>&nbsp;<u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="600" style="width:450.0pt;background:white;border-color:none">
    <tbody>
    <tr>
    <td colspan="5" style="padding:.75pt .75pt 0cm .75pt">
    <p style="line-height:12.75pt">
    <span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    <p align="center" style="text-align:center;line-height:32.25pt;min-height:39px" id="m_3587043361144361928u260-4">
    <span style="font-size:27.0pt;font-family:&quot;Arial&quot;,sans-serif;color:#71a23d">Things to check<u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="60" valign="top" style="width:45.0pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black;border:solid windowtext 1.0pt;padding:0cm"><img border="0" width="40" height="40" style="width:.4166in;height:.4166in" id="m_3587043361144361928u215_img" alt="Image removed by sender."></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="37" style="width:27.75pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="427" valign="top" style="width:320.25pt;padding:.75pt .75pt 0cm .75pt">
    <p class="m_3587043361144361928paragraph-style">
    We have three key things for you to do before you start your online learning with us:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    • Please check your sound and microphone settings ahead of your first online learning session. An easy way to do this is to set up a video call with a friend or colleague. You may prefer to use a headset or earphones (with mic).<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    • Read through the Apprenticeship quick guide to online learning when you receive it. This will help you understand how to access support, how the session will run and how to get help if you need it.
    <u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    • Plan where you will be located during your session, this should be a place that minimises background noise (if possible) but one that you will be comfortable for a few hours. There will be opportunities to engage with fellow learners and your tutor during
     the session so you may want to find a space you are free to talk from. <u></u><u></u></p>
    <p style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt 0cm .75pt"></td>
    </tr>
    </tbody>
    </table>
    </div>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;display:none"><u></u>&nbsp;<u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="600" style="width:450.0pt;background:white;border-color:none">
    <tbody>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="532" style="width:399.0pt;padding:.75pt .75pt 0cm .75pt">
    <p style="line-height:12.75pt">
    <span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    <p align="center" style="text-align:center;line-height:32.25pt" id="m_3587043361144361928u185-4">
    <span style="font-size:27.0pt;font-family:&quot;Arial&quot;,sans-serif;color:#616161">If you need to get in touch...<u></u><u></u></span></p>
    <p class="m_3587043361144361928paragraph-style" align="center" style="text-align:center" id="m_3587043361144361928u185-30">
    <b><span style="color:black">Our booking reference number is 10836471<u></u><u></u></span></b></p>
    <p class="m_3587043361144361928paragraph-style">
    If you need to speak to our team, you can contact us via the following:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    For programme or module queries:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Email - <a href="mailto:QAADegreeAdmin@qa.com" target="_blank"><b><span style="border:none windowtext 1.0pt;padding:0cm">QAADegreeAdmin@qa.com</span></b></a><u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    For technical assistance setting up or accessing your online learning, please use
    <a href="https://v2.zopim.com/widget/popout.html?key=5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://v2.zopim.com/widget/popout.html?key%3D5bcvdcizsyQNHnBtVO7iQ36mHsQWEoZj&amp;source=gmail&amp;ust=1702030154959000&amp;usg=AOvVaw08gSuvsutcXyYiIZBeKM7U">
    <b><span style="border:none windowtext 1.0pt;padding:0cm">Live Chat</span></b></a> or:<u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Email - <a href="mailto:VirtualLearning@qa.com" target="_blank"><b><span style="border:none windowtext 1.0pt;padding:0cm">VirtualLearning@qa.com</span></b></a><u></u><u></u></p>
    <p class="m_3587043361144361928paragraph-style">
    Phone - 020 3908 2376 (option 2)<u></u><u></u></p>
    <p style="line-height:12.75pt">
    <span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt 0cm .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif"><u></u><u></u></span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;display:none"><u></u>&nbsp;<u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="600" style="width:450.0pt;background:#005bab">
    <tbody>
    <tr>
    <td width="30" style="width:22.5pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="228" style="width:171.0pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="105" style="width:78.75pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="48" style="width:36.0pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="48" style="width:36.0pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="48" style="width:36.0pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="45" style="width:33.75pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td width="20" style="width:15.0pt;padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white;border:solid windowtext 1.0pt;padding:0cm"><img border="0" width="228" height="54" style="width:2.375in;height:.5625in" id="m_3587043361144361928_x0000_i1028" alt="Image removed by sender."></span><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white"><u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white"><a href="http://qa.com/facebook" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://qa.com/facebook&amp;source=gmail&amp;ust=1702030154959000&amp;usg=AOvVaw3XCF6KHSSl6yC2qW0vNKtc"><span style="color:white"><b><span style="color:#4590ce;border:none windowtext 1.0pt;padding:0cm"><img border="0" width="25" height="25" style="width:.2604in;height:.2604in" id="m_3587043361144361928_x0000_i1027" alt="Image removed by sender."></span></b></span></a><u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white"><a href="http://qa.com/linkedin" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://qa.com/linkedin&amp;source=gmail&amp;ust=1702030154959000&amp;usg=AOvVaw0q-VNHjMmeUfWNfdE-5huJ"><span style="color:white"><b><span style="color:#4590ce;border:none windowtext 1.0pt;padding:0cm"><img border="0" width="25" height="25" style="width:.2604in;height:.2604in" id="m_3587043361144361928_x0000_i1026" alt="Image removed by sender."></span></b></span></a><u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white"><a href="http://qa.com/youtube" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://qa.com/youtube&amp;source=gmail&amp;ust=1702030154959000&amp;usg=AOvVaw1dF6GnbduIeL1oJPJwcB9M"><span style="color:white"><b><span style="color:#4590ce;border:none windowtext 1.0pt;padding:0cm"><img border="0" width="25" height="25" style="width:.2604in;height:.2604in" id="m_3587043361144361928_x0000_i1025" alt="Image removed by sender."></span></b></span></a><u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td colspan="4" style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    <td style="padding:.75pt .75pt .75pt .75pt">
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;color:white">&nbsp;<u></u><u></u></span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    <p class="MsoNormal" style="line-height:12.75pt"><span style="font-size:10.5pt;font-family:&quot;Arial&quot;,sans-serif;display:none"><u></u>&nbsp;<u></u></span></p>
    <div align="center">
    <table border="0" cellpadding="0" width="600" style="width:450.0pt;background:#f6f6f6;border-color:none">
    <tbody>
    <tr>
    <td style="padding:.75pt 3.75pt .75pt 3.75pt">
    <p class="MsoNormal" style="line-height:10.5pt"><span style="font-size:7.5pt;font-family:&quot;Arial&quot;,sans-serif;color:#7f7f7f">&nbsp;<u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td style="padding:.75pt 3.75pt .75pt 3.75pt">
    <p class="MsoNormal" style="line-height:10.5pt"><span style="font-size:7.5pt;font-family:&quot;Arial&quot;,sans-serif;color:#7f7f7f">You have been sent this email because you recently booked or were booked onto a course with us.
    <br>
    You can <span class="m_3587043361144361928blue-links"><a href="https://cp.qa.com?Param1=5i6EFOxAV9HxA70VdEU/z0JeV4xhDOX1WoFKh8uDvMg=&amp;Param2=pZQJ+8DccjUaXgRx1nWYTA==" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://cp.qa.com?Param1%3D5i6EFOxAV9HxA70VdEU/z0JeV4xhDOX1WoFKh8uDvMg%3D%26Param2%3DpZQJ%2B8DccjUaXgRx1nWYTA%3D%3D&amp;source=gmail&amp;ust=1702030154959000&amp;usg=AOvVaw2OC-J2h-fbdwKZ48xd1YYP"><b><span style="border:none windowtext 1.0pt;padding:0cm">manage your QA communications preferences here</span></b></a></span>.
    <br>
    <br>
    QA respects your rights to privacy and understand the importance of protecting personal information that we hold about you. We have set out in our privacy notice (available at
    <span class="m_3587043361144361928blue-links"><a href="https://www.qa.com/legal/privacy-notice" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.qa.com/legal/privacy-notice&amp;source=gmail&amp;ust=1702030154959000&amp;usg=AOvVaw2sjjiChJ7bvaakT9QYEI93"><b><span style="border:none windowtext 1.0pt;padding:0cm">https://www.qa.com/legal/<wbr>privacy-notice</span></b></a></span>) information about what personal
     information we collect and how it is used and shared. Our privacy notice also includes details of who to contact if you have any questions about our privacy and data protection practices.
    <br>
    11QAADABC v1.5 <br>
    <br>
    All third party trademarks acknowledged. <br>
    <br>
    QA works with organisations to transform performance through learning. We are dedicated to making our clients more successful through the provision of world-class training services.
    <br>
    <br>
    The information transmitted is intended only for the person or entity to which it is addressed and may contain confidential and/or privileged material. Any review, retransmission, dissemination or other use of, or taking of any action in reliance upon, this
     information by persons or entities other than the intended recipient is prohibited. If you received this in error, please contact the sender and delete the material from any computer.
    <br>
    <br>
    QA Ltd. 1st Floor, International House, 1 St Katharines Way, London E1W 1UN. <br>
    QA Ltd. is a company registered in England with company registration number: 2413137
    <u></u><u></u></span></p>
    </td>
    </tr>
    <tr>
    <td style="padding:.75pt 3.75pt .75pt 3.75pt">
    <p class="MsoNormal" style="line-height:10.5pt"><span style="font-size:7.5pt;font-family:&quot;Arial&quot;,sans-serif;color:#7f7f7f">&nbsp;<u></u><u></u></span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    `)
}