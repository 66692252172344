import KayinDBEnv from "./Group 8.png"
export default function KayinDB(){
    return (
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"100px",flexDirection:"column",gap:"30px"}}>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <h1 style={{color:"white"}}>KayinDB </h1>
                    <h3 style={{color:"#394da0"}}>Software Engineering Course</h3>
                </div>
                {/*Introduction */}
                <div style={{width:"700px",minheight:"700px",backgroundColor:"white",borderRadius:"5px"}}>
                    <div style={{padding:"40px"}}>
                        <div style={{display:"flex",flexDirection:"column",gap:"30px"}}>
                            <h2>Skills - Backend, Frontend, AI, Cloud</h2>
                            <p><strong>These skills will allow you to create any application, ranging from movie sites, music apps or even games.</strong></p>
                            <p><strong>These 4 things aren't the be all that will make you successful in software.</strong> </p>
                            <p style={{color:"red"}}><strong>The most important thing is to learn to forget all you know and learn <u> only what is needed</u> for what you want to create. Everytime.</strong> </p>
                            <p>It's okay not to be a genius programmer like some 5 year old kid in China</p>
                            <p>All that is important is to <u><strong>know all the things that are needed</strong></u> for the CRAZY THINGS you may want to create.</p>
                            <p><strong>Everything else can be forgotten until you feel you need it.</strong></p>
                            <p>Think of software as lego.</p>
                            <p>Netflix is not just a massive lego building but it is a <u><strong>load of small lego pieces stuck together to create something big.</strong></u></p>
                            <p>If you <u><strong>slowly learn</strong></u> what <u><strong>each simple lego piece is and what it does</strong></u>, you can slowly build a massive building.</p>
                        </div>


                    </div>

                </div>
                {/*First Flask app */}
                <div style={{width:"700px",height:"215px",backgroundColor:"white",borderRadius:"5px"}}>
                    <div style={{padding:"40px"}}>
                     <h2>Backend - Flask - Python</h2>
                     <p>By the end of this you would have <u><strong>created a movie app</strong></u> that lets you watch <u><strong>all of Dragon Ball Super</strong></u> </p>
                     <p>Sounds daunting but once you learn what <u><strong>single lego piece makes the whole app work.</strong></u> The idea of it becomes simple.</p>
                    </div>
                    

                </div>

                <div style={{width:"700px",minheight:"300px",backgroundColor:"white",borderRadius:"5px"}}>
                    <div style={{padding:"40px"}}>
                        <div style={{display:"flex",flexDirection:"column",gap:"30px"}}>
                            <h2>Backend - Flask - Python</h2>
                            <div><h4>Setting up the App: </h4><p>(Don't try to understand it at first, just follow along and I'll explain at the end.)</p></div>
                            
                            <ol>
                                <li>Install python 3.12 on windows store</li>
                                <li>Open cmd/terminal</li>
                                <li>Run - mkdir KayinProject</li>
                                <li>Run - cd KayinProject</li>
                                <li>Run - python -m venv kayinenv</li>
                                <li>Run - kayinenv\Scripts\activate.bat</li>
                                <li>pip install flask</li>
                                <li>Run - code . (Include the full stop, this will open VS Code)</li>
                            </ol>
                            <div style={{border:"1px solid black",borderRadius:"5px"}}>
                                <div style={{padding:"10px"}}>
                                    <p>What is happening here?</p>
                                    <ul>
                                        <li>&#x2022; Firstly you install python so it can be used in the cmd/terminal.</li>
                                        <li>&#x2022; Then you make your project folder using <strong>mkdir KayinProject</strong></li>
                                        <li>&#x2022; You move into the project folder with <strong>cd KayinProject</strong></li>
                                        <li>&#x2022; Then we run this: <strong>python -m venv kayinenv</strong></li>
                                        <ul>
                                            <li>&#65518; This creates an <u><strong>environment</strong></u> for your project that the python app uses.</li>
                                            <li>&#65518; Think of your laptop as a <u><strong>massive bucket</strong></u> and an environment is all your <u><strong>smaller buckets filled with water inside the larger bucket.</strong></u></li>
                                            <li>&#65518; Each smaller bucket is a python project.</li>
                                            <li>&#65518; If there were<u><strong> no buckets</strong></u>, then the water would <u><strong>from all python projects can fill the massive bucket</strong></u> and this can ruin your laptop.</li>
                                        </ul>
                                    </ul>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <img src={KayinDBEnv}></img>
                                    </div>
                                    <ul>
                                        <li>&#x2022; Then you <u><strong>install flask</strong></u> which lets use make apps in python.</li>
                                        <li>&#x2022; It is code made by someone else that we can use in our project.</li>
                                    </ul>

                                </div>

                            </div>
                            <h4>Creating the App:</h4>
                            <ol>
                                <li>In VS Code make a file and call it <u><strong>app.py</strong></u></li>
                                <li>In that file paste all the code below:</li>
                            </ol>
                            <div style={{backgroundColor:"black",borderRadius:"5px"}}>
                                <div style={{padding:"20px"}}>
                                <code style={{whiteSpace:"pre"}}>
                                        
                                        from flask import Flask<br></br><br></br>



                                        app = Flask(__name__)<br></br><br></br>



                                        @app.route('/')<br></br>
                                        def hello_world():<br></br>
                                            <ul>
                                            <li>return 'Hello World!'</li>
                                            </ul><br></br>
                                        @app.route('/page1')<br></br>
                                        def page1():<br></br>
                                            <ul>
                                            <li>return 'Page 1!'</li>
                                            </ul><br></br>
                                        @app.route('/page2')<br></br>
                                        def page2():<br></br>
                                            <ul>
                                            <li>return 'Page 2!'</li>
                                            </ul><br></br>



                                        if __name__ == '__main__':<br></br>
                                            <ul>
                                                <li>app.run()</li>
                                            </ul>

                                    </code>

                                </div>

                            </div>
                            <p>3. Then in the cmd Run - python app.py </p>
                            <p>4. Go to google chrome and copy and paste <u><strong><a href="http://127.0.0.1:5000" target="_blank" rel="noopener noreferrer">http://127.0.0.1:5000</a></strong></u> ("should see hello world") </p>
                            <p>5. Go to google chrome and copy and paste <u><strong><a href="http://127.0.0.1:5000/page1" target="_blank" rel="noopener noreferrer">http://127.0.0.1:5000/page1</a></strong></u> ("should see page1") </p>
                            <p>This is how pages for a website is made.</p>
                            <p>The backend creates the places or routes where pages can be shown. Then the <u><strong>HELLO WORLD is the Frontend</strong></u> </p>
                            <p>This creates the foundation on <u><strong>creating pages that people can see</strong></u></p>
                            <p>Next I will show you personally how you can <u><strong>make the website look more like a website with HTML and CSS</strong></u></p>
                            <p>When making apps <u><strong>you don't need to remember the code</strong></u> but it helps to understand how it works.</p>
                            <p>Anytime your unsure or can't remember just <u><strong>look up into google</strong></u> "how to make a flask app" and it will give you the code you need.</p>
                            <p><u><strong>Don't ever expect to remember this</strong></u>, as I was writing this I used google to search for the code and commands because I couldn't remember.</p>
                        </div>


                    </div>
                    

                </div>

            </div>




        </div>
    )
}