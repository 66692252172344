import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function CubeModel({modelPath = "https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=blivk.gltf"}) {
  //const groupRef = useRef() // ref={groupRef}
  const { nodes, materials } = useGLTF(modelPath)
  


  

  return (
    <group  dispose={null}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube.geometry}
            
            material={nodes.Cube.material}
            position={nodes.Cube.position}
    
          /> 
    </group>
  )
}

