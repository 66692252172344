// React
import * as React from 'react';
import { Card } from "react-bootstrap";
// CSS Styling
//import HeaderStyles from './HeaderStyles';
import "bootstrap/dist/css/bootstrap.min.css";
//import './scrollbar.css'

// Amari Logo
// Logo Cards
import numpypandaslogo from './imgs/FrameworkCards/numpy-pandas-logo.svg'
import matplotliblogo from './imgs/FrameworkCards/matplotlib-logo.svg'
import reactflasklogo from './imgs/FrameworkCards/reactflasklogo.svg'
import tensorflowscikitlogo from './imgs/FrameworkCards/tensorflowscikit-logo.svg'


// Cards
function Cards(cardtitle,styles){
switch (cardtitle) {
    case "Data Science":
    return(
        <Card bg={"dark"} style={Object.assign({},styles.card,{flex:1})}>
        <div style={styles.numpypandaslogocont}>
            <img src={numpypandaslogo} alt='' style={styles.numpypandaslogo}></img>  
        </div>
        <Card.Body>
            <Card.Title>{cardtitle}</Card.Title>
            <Card.Text style={styles.cardtext}>
            Over a span of 2 years and the <a href="https://www.summersteminstitute.org/" target='_blank' rel="noreferrer" style={{color:'white'}}>SSI data science/statistics and research bootcamp</a> I have practiced many methods and techniques in processing, calculating and interpretung data.
            Processing text; using NLP methods (i.e, calculating document similarity with cosine similarity and tfidf vectorization and sentiment analysis); Images classification (using convolutional neural networks);
            and TimeSeries analysis for stock regression (using Long-short Term neural networks).
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            <p >Check it out &rarr;<a href='https://numpy.org/' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Numpy</a>, <a href='https://pandas.pydata.org/docs/user_guide/index.html' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Pandas</a> </p>
        </Card.Footer>
    </Card>)
    case "Web Development":
        return(
        <Card bg={"dark"} style={Object.assign({},styles.card,{flex:1})}>
            <div style={styles.reactflasklogocont}>
            <img src={reactflasklogo} alt='' style={styles.reactflasklogo}></img>  
            </div>
            <Card.Body>
            <Card.Title>{cardtitle}</Card.Title>
            <Card.Text style={styles.cardtext}>
                Web development was an important step for me to be able to fully make use of my software.
                I realized that the applications I would create would ultimately be obsolete if I can't create an interface that allows it to best used.
                I can't exactly make a stock regression application and return the user a mean squared error score.
            </Card.Text>
            </Card.Body>
            <Card.Footer>
            <p >Check it out &rarr; <a href='https://reactjs.org/' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>React</a>, <a href='https://flask.palletsprojects.com/en/2.1.x/' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Flask</a></p>
            </Card.Footer>
        </Card>)
    case "Machine Learning":
    return(
    <Card bg={"dark"} style={Object.assign({},styles.card,{flex:1})}>
        <div style={styles.tensorflowscikitlogocont}>
            <img src={tensorflowscikitlogo} alt='' style={styles.tensorflowscikitlogo}></img> 
        </div>
         
        <Card.Body>
            <Card.Title>{cardtitle}</Card.Title>
            <Card.Text style={styles.cardtext}>
            There are many machine learning algorithms and method variatians I have explored. Ranging from linear regression to deep learning.
            I have learned how to process different types of data and use them in machine learning problems. Including tabular data, image data, text and datetime data. 
            Using the Tensorflow library varying between the Sequential, Function and Subclass API. 
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            <p >Check it out &rarr; <a href='https://reactjs.org/' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Tensorflow</a>, <a href='https://sklearn.org/' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Scikit-Learn</a></p>
        </Card.Footer>
    </Card>
)
    case "Data Visualization":
    return(
        <Card bg={"dark"} style={Object.assign({},styles.card,{flex:1})}>
        <div style={styles.matplotliblogocont}>
            <img src={matplotliblogo} alt='' style={styles.matplotliblogo}></img>  
        </div>
        <Card.Body>
            <Card.Title>{cardtitle}</Card.Title>
            <Card.Text style={styles.cardtext}>
            During my time doing the Summer STEM Institute course, the statistics lecturer would at the end of every lecture give us a moral for us to take away.
            One of them was that data always tells a story and they say that a picture is worth a thousand words. Visualizations allow one to convey information and send a message that is understandable and attactive to the reciever.
            </Card.Text>
        </Card.Body>
        <Card.Footer>
            <p >Check it out &rarr; <a href='https://matplotlib.org/' target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Matplotlib</a> </p>
        </Card.Footer>
    </Card>) 
    default:
    return(<p>Card does not exist</p>)
}
}

export default Cards;