import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';
import logo from './imgs/Amari-logo.svg'
//import HeaderStyles from './HeaderStyles';
import Items from './itemcomponentmedia';
import useMediaQuery from './useMedia';
import {maxRowBasedquery} from './mediamax';
import './headerhome.css'
import { useNavigate } from 'react-router-dom';
const pages = ['CV','Frameworks', 'Projects', 'Skills','Contact Me'];
class HeaderStylesBar{
    constructor(maxRowBased,checkaltutor){
      this.textcolorwhite = {color:"white"}
      this.textcolorblack = {color:"black"}
      this.cardcenteringrow = {display:"flex",justifyContent:"center"}
      // Desktop size
      // App Bar 1
      // Nav menu
      this.appBar = {backgroundColor: "#1e1e1e", height: checkaltutor === true ? "120px" :"700px",width: maxRowBased ? "100%" : "100%"};
      this.navbar = { display: "flex",flexDirection: maxRowBased ? 'row' : 'column',alignItems: "center",justifyContent: "center"};
      this.headertitletextcont = {display:"flex",justifyContent:"center",alignItems:"center",width: maxRowBased ? "100%" : "60%",height: maxRowBased ? "auto" : "15%", marginLeft: maxRowBased ? "0px" : "20%"};
      this.logocont = {marginRight: "550px",  marginLeft: "200px",marginTop: "20px"}
      this.logo = {display: "flex",width: "90px", height: "90px",alignSelf: "flex-start"};
      this.namecont = {marginTop:"10px", marginLeft: "10px"}
      
      this.logophonecont  = {display:"flex",justifyContent:"center",marginBottom:"20px"}
      this.logophone = {width: "90px", height: "90px"};
      
      this.navtextcont = {display:"flex",justifyContent: "center",rowGap: "10px", marginTop:"10px"} //,marginRight:"auto"

      this.navlink = {flex: 1,textAlign: "center",margin: "0.25em",padding: "0.25em",textDecoration:"none",color:"white",fontSize: "15px",marginRight:"auto"}


      this.navIcons = {alignItems: "center"}
      this.itemMargin = {marginRight: "20px"}

      // Header
      this.headertitle = {textAlign:"center",fontSize:"70px"}
      this.subheadingcont = {alignItems:"center",width: "500px",margin: "auto",height:"40px",marginTop: "30px"}
      this.subheading = {textAlign:"center",fontSize:"20px",display: "block"}
      this.subheadingbutton = {alignItems:"center",width: maxRowBased ? "500px" : "200px",margin: "auto",height:"40px",marginTop: "3em"}
      this.githubIcon = {marginRight:"3px"}
  }
}


function HeaderBar(props){
  const maxRowBased = useMediaQuery(maxRowBasedquery);
  let styles = new HeaderStylesBar(maxRowBased,props.checkaltutor);
  const navigate = useNavigate()
  
  //  <AppBar position="static" style={styles.appBar}> {/*Done*/}  
  return(
  <div style={styles.appBar}>
  {maxRowBased ? 
    // Large Screen
    <Box style={styles.navbar}>{/*Done*/}
              
        <Button style={styles.logocont} >
            <img onClick={()=> {navigate("/")}} style={styles.logo} src={logo} alt=''></img>

        </Button>
        {pages.map((page) => Items(page))}
    </Box>
    :
    // Small Screen <div style={{color:"white"}}>Hello</div>
    <div>
        <div className="hamburger-menu">
          <input id="menu__toggle" type="checkbox" />
          <label className="menu__btn" htmlFor="menu__toggle">
            <span></span>
          </label>

          <ul className="menu__box">
            <li><a className="menu__item" href="https://github.com/Amari-Lawal/Amari-Lawal-Portfolio/blob/main/Amari%20Hussey%20Lawal%20CV.pdf"  target='_blank' rel="noreferrer" >CV</a></li>
          </ul>
        </div>
      <div style={styles.logophonecont}>

          <img style={styles.logophone} src={logo} alt=''></img>
      </div>
    </div>
    }
  { props.checkaltutor === true ? 
  <div></div>
  :
  <div>
  <div style={styles.headertitletextcont}>
    <h2 style={styles.headertitle}><font color="3296C0">Data</font> <font color="white">Scientist</font></h2>
  </div>
  <div style={Object.assign({},styles.headertitletextcont,{height : maxRowBased ? "auto" : "2%"})}>
    <div style ={styles.subheadingcont}>
      <p style={styles.subheading}>
        <font color="white">
        Data science allows one to create innovate complex systems
        with mere zeros and ones.
        </font>
      </p>
    </div>
  </div>
  <div style={styles.headertitletextcont}>
    <hr style={{marginTop:"30px"}}></hr>
  </div>
  <div style={styles.headertitletextcont}>
    <div style ={styles.subheadingcont}>
    <p style={styles.subheading}> 
    <font color="3296C0">
      Creating such systems from the ground up to advance and improve the
      economic, academic state and digital security of society
      </font>
    </p>
    </div>
  </div>
  <div style={Object.assign({},styles.headertitletextcont,{marginTop: maxRowBased ? "auto" : "5%"})}>
    <div style={styles.subheadingbutton}>
      <Button variant='contained' style={styles.subheadingbutton} href="https://github.com/CaesarCloudSync" target="_blank" rel="noreferrer"><GitHubIcon style= {styles.githubIcon}></GitHubIcon>Github</Button>

    </div>
  </div>
  </div>
}


  
  </div>



    )
}
export default HeaderBar;