
import useMediaQuery from "../../useMedia"
import {maxRowBasedquery} from "../../mediamax"
import { useLocation,useNavigate } from "react-router";
import { useState } from "react";
import { moviekeys } from "../moviekeys"
import axios from "axios";
import { useEffect } from "react";
import Episode from "./episode";
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function AmariEpisodes(){
    const location = useLocation();
    const navigate = useNavigate();
    const media = useMediaQuery(maxRowBasedquery)
    const [film,setFilm] = useState(location.state);
    const [seasons,setSeasons] = useState([]);
    const [description,setDescription] = useState("");
    const [number_of_episodes,setNumOfEpisodes] = useState(0);
    const [videolink,setVideoLink] = useState("");



    useEffect(() => {
        // triggered on route change
        console.log('new route: ', location)
      }, [location])
    const reorderpecials = (seasons) => {
        let specials = []
        for(var i = 0; i < seasons.length; i++) {
            if (seasons[i].name.includes("Special")){
    
                var index = seasons.indexOf(seasons[i]);
                if (index !== -1) {
                    specials.push(seasons[i])
                    seasons.splice(index, 1);
                    
                  }
            }
        }
        
        return seasons.concat(specials)

    }
    const getfilmdetails = async () =>{
        const config = {
            headers: { Authorization: `Bearer ${moviekeys.read_access_token}` }
        }; 
        const response = await axios.get(`https://api.themoviedb.org/3/tv/${film.id}?language=en-US`,config)
        let result = response.data
        //console.log(result)
        setNumOfEpisodes(result.number_of_episodes)
        let seasons = result.seasons
        seasons = reorderpecials(seasons)
        setSeasons(seasons)
        setDescription(result.overview)
        
    }
    useEffect(() =>{
        getfilmdetails()
        
    },[])
    return(
        <div style={{backgroundColor:"#1e1e1e",height:window.innerHeight,overflow:"auto"}}>
            <div style={{position:"relative",top:"100px"}}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"20px"}}
            >

                <h1 style={{color:"white"}}>{film.name}</h1>
                <p style={{color:"white",margin:"30px",width:media ? "700px":"auto"}}>{description}</p>
                <p style={{color:"white"}}>Number of Episodes: {number_of_episodes}</p>
                
                {videolink !== ""&&
                <iframe width={media ? "700": "300"} height={media ? "455": "355"} src={videolink}
                title="Amari Dev Current Episode" 
                style={{borderRadius:10}}
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen="true"></iframe>}
                {film.original_language === "ja" && 
                <div>
                    
                    <a onClick={() =>{window.open(`https://aniwave.to/filter?keyword=${film.name}`)}} style={{color:"white",cursor:"pointer"}}>
                        {/*https://aniwatch.to/search?keyword=${film.name}` */}
                        <VideocamIcon style={{fontSize:"30px"}}/>
                        
                        <ArrowForwardIcon/>
                    </a>
                </div>}

 

            </div>  
            <div style={{display:"flex",borderRadius:"5px",padding:"10px",gap:"20px",justifyContent:"center",alignItems:"center",flexWrap:"wrap",marginTop:film.original_language === "ja" ? "30px":"0px"}}>

                    {seasons.length > 0 && seasons.map((season) =>{
                        return(
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <img src={`https://image.tmdb.org/t/p/original/${season.poster_path}`} style={{width:media ? "300px" :"150px",height:media ? "400px" :"250px",cursor:"pointer",borderRadius:"5px"}}></img>
                                <p style={{marginTop:"4px",color:"white"}}>{season.name}</p>
                                <p style={{width:media ? "300px":"100px",color:"white"}}>Release Date: {season.air_date}</p>
                                <p style={{color:"white"}}>Rating: {season.vote_average}</p>
                                <div style={{display:"flex",flexWrap:"wrap",gap:"8px",width:media ? "300px":"150px"}}>
                                    {[...Array(season.episode_count).keys()].map((ind)=>{return(ind +1)}).map((episode) =>{
                                        return(
                                            <Episode season_number={season.season_number} film={film} episode={episode} setVideoLink={setVideoLink}/>

                                        )
                                    })}
                                </div>

                            </div>
                        )
                    })}
                </div>  







        </div>
            


        </div>
    )
}