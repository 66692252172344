import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
//import HomePage from './components/homepage';
//import MediaTest from './components/mediatest';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import AlTutor from "./mediacomponents/altutor"
import FullMedia from './mediacomponents/fullmedia';
import ALPaid from './mediacomponents/alpaid';
import ALTutorPayment from "./mediacomponents/altutorpayment"
import ALDetails from "./mediacomponents/aldetails"
import AuthAmariFinanceGraphs from './mediacomponents/amarifinance/authamarifinancegraphs';
import QATimeTables from './mediacomponents/qatimetables/qatimetables';
import ManageCaesarAIEngineWorld from './mediacomponents/caesaraiengineworld/managecaesaraiengineworld';
import AmariSecurity from './mediacomponents/amarisecurity/amari.security';
import CaesarAIEngineWorldModel from './mediacomponents/caesaraiengineworld/caesaraiengineworldmodel';
import AuthAmariMovies from './mediacomponents/amarimovies/AmariMovies/authAmariMovies.js';

import AmariSeries from './mediacomponents/amarimovies/AmariSeries/AmariSeries.js';
import AmariEpisodes from './mediacomponents/amarimovies/AmariEpisodes/AmariEpisodes.js';
import AmariAnimeEpisodes from './mediacomponents/amarimovies/AmariAnimeEpisodes/AmariAnimeEpisodes.js';
import AmariAnime from './mediacomponents/amarimovies/AmariAnime/AmariAnime.js';
import Wishlist from './mediacomponents/amarimovies/wishlist/Wishlist.js';
import AmariArt from './mediacomponents/amariart/amariart.js';
import AmariGallery from './mediacomponents/amariart/amarigallery.js';
import AuthUploadArt from './mediacomponents/amariart/authuploadart.js';
import AmariAlbum from './mediacomponents/amariart/amarialbum.js';
import CaesarAI from './mediacomponents/caesarai/CaesarAI.js';
import CaesarAICSV from './mediacomponents/caesarai/caesaraiapis/caesaraicsv/caesaraicsv.js';
import CaesarAIYoutube from './mediacomponents/caesarai/caesaraiapis/caesaraiyoutube/caesaraiyoutube.js';
import CaesarAIVideos from './mediacomponents/caesarai/caesaraiapis/caesaraiyoutube/caesaraivideos.js';
import AmariAnimeSeasons from './mediacomponents/amarimovies/AmariAnimeSeasons/AmariAnimeSeasons.js';
import KayinDB from './mediacomponents/KayinDB/KayinDB.js';
import AuthPlaid from './mediacomponents/amariplaid/authamariplaid.js';
//import HeaderBar from './mediacomponents/headerbarmedia';
//import Projects from './mediacomponents/projectscomponentmedia';
//import FrameworkComponent from './mediacomponents/frameworktechniquesmedia';
//import Skills from './mediacomponents/skillscomponentmedia';
//import ContactMe from './mediacomponents/contactmecomponentsmedia';
ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<FullMedia/>} />
      <Route exact path="/kayindb" element={<KayinDB/>} />
      <Route exact path="/amariplaid" element={<AuthPlaid/>} />
      <Route exact path="/amarifinancegraphs" element={<AuthAmariFinanceGraphs/>} />
      <Route exact path="/amarimovies" element={<AuthAmariMovies/>} />
      <Route exact path="/amariseries" element={<AmariSeries/>} />
      <Route exact path="/amarianimeseasons" element={<AmariAnimeSeasons/>} />
      <Route exact path="/amarigallery" element={<AmariGallery/>} />
      <Route exact path="/amarialbum" element={<AmariAlbum/>} />
      <Route exact path="/amariart" element={<AmariArt/>} />
      <Route exact path="/caesarai" element={<CaesarAI/>} />
      <Route path="/caesarai/caesaraicsv" element={<CaesarAICSV/>} />
      <Route path="/caesarai/caesaraiyoutube" element={<CaesarAIYoutube/>} />
      <Route path="/caesarai/caesaraiyoutube/caesaraivideos" element={<CaesarAIVideos/>} />
      <Route exact path="/uploadart" element={<AuthUploadArt/>} />
      <Route exact path="/amarianime" element={<AmariAnime/>} />
      <Route exact path="/wishlist" element={<Wishlist/>} />
      <Route exact path="/amariepisodes" element={<AmariEpisodes/>} />
      <Route exact path="/amarianimeepisodes" element={<AmariAnimeEpisodes/>} />
      <Route exact path="/altutor" element={<AlTutor/>} />
      <Route exact path="/altutorpayment" element={<ALTutorPayment/>} />
      <Route exact path="/alpaid" element={<ALPaid/>} />
      <Route exact path="/aldetails" element={<ALDetails/>} ></Route>
      <Route exact path="/qatimetables" element={<QATimeTables/>} ></Route>
      <Route exact path="/managecaesaraiengineworld" element={<ManageCaesarAIEngineWorld/>} ></Route>
      <Route exact path="/caesaraiengineworldmodel" element={<CaesarAIEngineWorldModel/>} ></Route>
      <Route exact path="/amarisecurity" element={<AmariSecurity/>} ></Route>
      
      {/*
      <Route exact path="/fullmedia" element={<FullMedia/>} />
      <Route exact path="/headerbar" element={<HeaderBar/>} />
      <Route exact path="/frameworktechnique" element={<FrameworkComponent/>} />
      <Route exact path="/projects" element={<Projects/>} />
      <Route exact path="/skills" element={<Skills/>} />
      <Route exact path="/contactme" element={<ContactMe/>} />

      <Route exact path="/mediatest" element={<MediaTest/>} />
      <Route path="*" element={<div>Not Found</div>} />
      */}
    </Routes>
  </Router>
  ,
  document.getElementById('root')
);
