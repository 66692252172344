import React from 'react';
//import HeaderStyles from './HeaderStyles';
import useMediaQuery from './useMedia';
import {maxRowBasedquery} from './mediamax';
import herokulogo from './imgs/MyProjects/herokulogo.png';
import stockgraph from './imgs/MyProjects/stockgraph.svg'
import digitrecogizer from './imgs/MyProjects/digitrecoginzer.svg'
//import revisionbank from './imgs/MyProjects/revisionbanknap.PNG'
import revisionbank from './imgs/MyProjects/revisionbank.PNG'
import pythonlogosmall from './imgs/MyProjects/pythonlogo.svg'
import reactlogosmall from './imgs/MyProjects/reactlogo.svg'
import tensorflowlogosmall from './imgs/MyProjects/tensorflowlogo.png'
import mongdblogosmall from './imgs/MyProjects/mongodbsmall.png'
import flasklogosmall from './imgs/MyProjects/flasklogo.jfif'
import spacylogosmall from './imgs/MyProjects/spacylogo.png'
import pandaslogosmall from './imgs/MyProjects/pandaslogo.jfif'
import numpylogosmall from './imgs/MyProjects/numpylogo.png'
import seleniumlogosmall from './imgs/MyProjects/seleniumlogo.png'
import requestslogosmall from './imgs/MyProjects/requestslogo.png'
import beautifulsouplogosmall from './imgs/MyProjects/beautifulsouplogo.jfif'
import STEMRoadmap from './imgs/MyProjects/STEMRoadmap.svg'
import dockerlogo from './imgs/MyProjects/dockerlogo.svg'
import typescriptlogo from './imgs/MyProjects/typescriptlogo.png'
import aslevelsendersnap from './imgs/MyProjects/ASPhysicsLevelRevisionCard.PNG'
import NLBashsnap from './imgs/MyProjects/NLBashSnap.png'
import bashlogo from './imgs/MyProjects/bashlogosmall.png'
//let styles = new HeaderStyles();
class HeaderStylesProjects{
  constructor(maxRowBased){
    this.appBar = {backgroundColor: "#1e1e1e",height: maxRowBased ? "100%" : "110%",width: maxRowBased ? "100%" : "100%"}; //230em // 120em
    this.headertitle = {textAlign:"center",fontSize:"70px",marginBottom:"-20px"}
    this.projectstitle = {color:"white"}
    this.appBar2row = {display: "flex",marginTop:"50px", flexDirection : maxRowBased ? "row" : "column", marginLeft: maxRowBased ? "auto" : "-5em"};
    this.appBar2rowicons = {display: "flex",marginTop:"50px", flexWrap: maxRowBased ? "none" : "wrap", width : maxRowBased ? "auto" : "100%"} // // , flexDirection : maxRowBased ? "row" : "column"
    this.appBar2column = {flex: "33.33%",padding: "5px",marginLeft:"100px"}
    this.stockgraph = {width:"100%",height:"100%"}
    this.revisionbank = {width:"100%"}
    this.projectstext = {color:"white",marginLeft:"100px", margin: "2px"}
    this.projectstextcont = {marginTop:"20px"}
    this.logosmallinit = {height:"60px",width:"60px"}
    this.horizontallogosmall = {height:"60px",width:"120px",marginLeft:"20px"}
    this.logosmall =  {height:"60px",width:"60px",marginLeft:"20px"}
    this.logodocker = {width:"100px",marginLeft:"20px"}
    this.logosmallwhitebg = {borderRadius:"10px"}
    this.stemroadmapcont = {height:"100%"}
    this.stemroadmap = {width:"100%",height:"100%"}
    this.pandaend = {marginTop:"0px"}

  }
}
const StockgraphText = (props) => {
  return(
    <div className="appBar2column" style={props.styles.appBar2column}>
    <h2 style={props.styles.projectstitle}>Stock Forecasting/Regression using Machine Learning/Deep Learning LSTM's and Sentiment Analysis</h2>
    <p style={Object.assign({},props.styles.projectstitle,{fontSize:"20px"})}><font color="808080"><b>Made Using:</b></font></p>
    <div style={props.styles.appBar2rowicons}>
    <img style={props.styles.logosmallinit} src={pythonlogosmall} alt=''></img>
    <img style={props.styles.logosmall} src={reactlogosmall} alt=''></img>
    <img style={props.styles.logosmall} src={tensorflowlogosmall} alt=''></img>
    <img style={props.styles.logosmall} src={mongdblogosmall} alt=''></img>
    <img style={props.styles.horizontallogosmall} src={spacylogosmall} alt=''></img>  
    <img style={Object.assign({},props.styles.horizontallogosmall,props.styles.logosmallwhitebg)} src={pandaslogosmall} alt=''></img>  
    <img style={Object.assign({},props.styles.logosmall,props.styles.logosmallwhitebg)} src={flasklogosmall} alt=''></img>
    
    </div>
    <div style={props.styles.projectstextcont}>
    <p style={props.styles.projectstext}>
      This project was made for my initial A Level Extended Project Qualification idea. The application was a stock predictor with the goal of finding correlation using stock historical data 
      and finding causation by using sentiment analysis on Twitter posts. Then using both models to make a final prediction. I managed to make forecast predictions with LSTM's with considerable accuracy. 
      However the sentiment model was having problems. The sentiment model used an embedding layer,several LSTM Layers and an Attention Layer. Due to little knowledge in sentiment analysis at the time. 
      I had a problem that each time I try to predict after training, I would come across new voabulary that the model had never seen before so I would have to randomly initalize it which wasn't ideal in accuracy.
      After reading an article on <a href='https://towardsdatascience.com/natural-language-to-sql-from-scratch-with-tensorflow-adf0d41df0ca' target='_blank' rel="noreferrer" style={{color: "white"}}>Natural language to sql</a>, it made me realize I should use a pretrained glove model to handle the general language then train my own embeddings to allow for analysis specfic to the 
      twitter posts and headlines.
    </p>
    </div>
  </div>
  )
}
const RevisionBankText = (props) => {
  return(
    <div className="appBar2column" style={props.styles.appBar2column}>
    <h2 style={props.styles.projectstitle}>RevisionBank</h2>
    <p style={Object.assign({},props.styles.projectstitle,{fontSize:"20px"})}><font color="808080"><b>Made Using:</b></font></p>
    <div style={Object.assign({},props.styles.appBar2rowicons,{flexWrap:"wrap"})}>
      <img style={props.styles.logosmallinit} src={reactlogosmall} alt=''></img>
      <img style={props.styles.logosmall} src={pythonlogosmall} alt=''></img>
      <img style={props.styles.logosmall} src={herokulogo} alt=''></img>
      <img style={props.styles.logosmall} src={mongdblogosmall} alt=''></img>
      <img style={props.styles.logosmall} src={typescriptlogo} alt=''></img> 
      <img style={Object.assign({},props.styles.logosmall,props.styles.logosmallwhitebg)} src={seleniumlogosmall} alt=''></img>
      <img style={Object.assign({},props.styles.logosmall,props.styles.logosmallwhitebg)} src={requestslogosmall} alt=''></img>
      <img style={Object.assign({},{height:"60px",width:"120px"},props.styles.logosmallwhitebg,{marginTop:"10px"})} src={beautifulsouplogosmall} alt=''></img>
      <img style={Object.assign({},props.styles.logosmall,props.styles.logosmallwhitebg,{marginTop:"10px"})} src={flasklogosmall} alt=''></img>
    </div>
    <div style={props.styles.projectstextcont}>
      <p style={props.styles.projectstext}>
        <a href="https://revisionbank.org" target='_blank' rel="noreferrer" style={{color: "white"}}>RevisionBank</a> is a web application that provides/collects topic specific questions for A level subjects. Emailing the selected question papers to refer back to.
        Including past papers, solution banks and markschemes. The RevisionBank Tools cover: Maths, Further Maths and Physics with plans to expand into further subjects, exam boards and key stages.
        The initial goal was to tackle the tsunami of Further Maths A level homework, in an ambtious attempt at automating my A levels.
        The plan for future features is to implement a search engine that will collect the papers and decide if the papers collected from that site fall legally under copyright laws. 
        Then allow a more interactive experience with the user, with revision scheduling software, note taking and more.
       
      </p>
    </div>
    </div>

  )
}
const ASLevelSenderText = (props) => {
  return(
    <div className="appBar2column" style={props.styles.appBar2column}>
    <h2 style={props.styles.projectstitle}>AS Level Revision Card Scheduler</h2>
    <p style={Object.assign({},props.styles.projectstitle,{fontSize:"20px"})}><font color="808080"><b>Made Using:</b></font></p>
    <div style={Object.assign({},props.styles.appBar2rowicons,{flexWrap:"wrap"})}>
      <img style={props.styles.logosmallinit} src={herokulogo} alt=''></img>
      <img style={props.styles.logosmall} src={pythonlogosmall} alt=''></img>
      <img style={props.styles.logosmall} src={mongdblogosmall} alt=''></img>
      <img style={Object.assign({},props.styles.logosmall,props.styles.logosmallwhitebg)} src={requestslogosmall} alt=''></img>
      <img style={Object.assign({},props.styles.logosmall,props.styles.logosmallwhitebg)} src={flasklogosmall} alt=''></img>
    </div>
    <div style={props.styles.projectstextcont}>
      <p style={props.styles.projectstext}>
        AS Level Revision Card Scheduler is an application that takes a random revision card you make and sends one to you every hour. Allowing me to revise with whilst doing other things.
        This project started with an excrutiating 4 hour cram revision of AS Level Physics a few weeks before my exams. No matter how much I stared at my textbook I still cou;dn't remember the elementary charge of an electron.
        A defeated google search of 'how to revise AS level physics' led me with the two answers; making revision cards and exam questions. The tedious and boring nature of making paper revision cards repelled me and got me falling asleep thinking about it.
        This led to the verdict that I would create revision cards but online, this would allow me to store them in a safe place and schedule the provision of these revision cards to myself every hour and have easy access.

       
      </p>
    </div>
    </div>

  )
}
const NLBashText = (props) => {
  return(
    <div className="appBar2column" style={props.styles.appBar2column}>
    <h2 style={props.styles.projectstitle}>NLBash Attention Transformer Model</h2>
    <p style={Object.assign({},props.styles.projectstitle,{fontSize:"20px"})}><font color="808080"><b>Made Using:</b></font></p>
    <div style={Object.assign({},props.styles.appBar2rowicons,{flexWrap:"wrap"})}>
      <img style={props.styles.logosmallinit} src={tensorflowlogosmall} alt=''></img>
      <img style={props.styles.logosmall} src={pythonlogosmall} alt=''></img>
      <img style={props.styles.logosmall} src={numpylogosmall} alt=''></img>
      <img style={Object.assign({},props.styles.horizontallogosmall,props.styles.logosmallwhitebg)} src={pandaslogosmall} alt=''></img>
      <img style={Object.assign({},{height:"60px",width:"100px"},props.styles.logosmallwhitebg)} src={bashlogo} alt=''></img>
    </div>
    <div style={props.styles.projectstextcont}>
      <p style={props.styles.projectstext}>
        NLBash started with my dream of making my own smart house with all the software in there I created. Initially this started with the urge to create an A.I assistant. I wanted to provide funxtionality to the 
        A.I assistant that would make it different to things like Alexa and Siri. Then I was inspired by J.A.R.V.I.S from Iron man. J.A.R.V.I.S controls every aspect of Tony starks house and thinking on how that would be 
        implemented in a real-life standpoint. J.A.R.V.I.S would be translating what Tony Stark says and be formulated running scripts. I could create predefined scripts that can be used by the A.I assistant but where's the fun in that.
        At the moment NLBash is will simply take natural language and translate it into built-in bash commands. However, further development would lead to usecase specific arguments and non built-in commands.
       
      </p>
    </div>
    </div>

  )
}
function Projects(){
    const maxRowBased = useMediaQuery(maxRowBasedquery);
    let styles = new HeaderStylesProjects(maxRowBased);
    //<AppBar position="static" style={Object.assign({},styles.appBar,{height:"1500px"})}>
    return(
    <div style={styles.appBar}>
    <h2 id= "project" style={Object.assign({},styles.headertitle,{fontSize:"50px"})}><font color="3296C0">My</font><font color="white"> Projects</font></h2>
        {/*RevisionBank*/}
        { maxRowBased ?
    <div className='appBar2row' style={styles.appBar2row}>
        <div className="appBar2column" style={styles.appBar2column}>
          <div style={{height:"100%",marginTop:"50px"}}>
          <img src={revisionbank} style={styles.revisionbank} alt=''></img>
          </div>
        </div>
        <RevisionBankText styles={styles}/>
    </div>
    

    : 
    
    <div className='appBar2row' style={styles.appBar2row}>
      <RevisionBankText styles={styles}/>
      <div className="appBar2column" style={styles.appBar2column}>
        <img src={revisionbank} style={styles.stockgraph} alt=''></img>
      </div>
    </div>
    }
    {/*STEMRoadMap Startup Project*/}
    <div className='appBar2row' style={styles.appBar2row}>
    <div className="appBar2column" style={styles.appBar2column}>
            <h2 style={styles.projectstitle}>STEMRoadmap Startup Project</h2>
            <p style={Object.assign({},styles.projectstitle,{fontSize:"20px"})}><font color="808080"><b>Made Using:</b></font></p>
            <div style={Object.assign({},styles.appBar2rowicons,{flexWrap:"wrap"})}>
            <img style={styles.logosmallinit} src={pythonlogosmall} alt=''></img>
            <img style={styles.logosmall} src={reactlogosmall} alt=''></img>
            <img style={styles.logosmall} src={herokulogo} alt=''></img>
            <img style={styles.logosmall} src={mongdblogosmall} alt=''></img>
            <img style={styles.horizontallogosmall} src={spacylogosmall} alt=''></img>
            <img style={styles.logosmall} src={typescriptlogo} alt=''></img>  
            <img style={styles.logodocker} src={dockerlogo} alt=''></img>
            <img style={Object.assign({},{height:"60px",width:"120px"},styles.pandaend,styles.logosmallwhitebg,{marginTop:"20px"})} src={pandaslogosmall} alt=''></img>  
            <img style={Object.assign({},styles.logosmall,styles.logosmallwhitebg,{marginTop:"20px"})} src={flasklogosmall} alt=''></img>
            
            
            
            </div>

            <div style={styles.projectstextcont}>
              <p style={styles.projectstext}>
                STEMRoadMap is my first startup project attempt. With the goal to collect information from the internet and create a learning roadmap for any STEM Subject. Niching to students, undergraduates or even someone interested in a career change.
                The software implements a host of many machine learning and natural language processing methods. I managed to make the software collect the the exact data required, reducing the data collection and filter process from 7 minutes to 40 seconds each query.
                This long runtime in the wild would prove impractical so I implemented a method of precaching the data on a database. However at the time I didn't know how to deploy python scripts to be run as a long running script. After practicing with a new project RevisionBank I 
                was able to learn how to run long running scripts on heroku. Next I plan to run my scripts using AWS. Previously I was deterred from AWS due to the very small budget that I had so heroku was a good free alternative.
                However heroku still has some disadvantages. The project took 9 months to implement the main bulk of the functionality, this was due to the slow process of self teaching myself all the tools I required.
              </p>
            </div>
        </div>
      <div className="appBar2column" style={styles.appBar2column}>
        <div style={styles.stemroadmapcont}>
          <img src={STEMRoadmap} style={styles.stemroadmap} alt=''></img>
        </div>
      </div>
    </div>
    {/*AS Level Revision Card Scheduler*/}
    { maxRowBased ?
    <div className='appBar2row' style={styles.appBar2row}>
    <div className="appBar2column" style={styles.appBar2column}>
      <img src={aslevelsendersnap} style={Object.assign({},styles.revisionbank,{marginTop:"15%"})} alt=''></img>
    </div>
    <ASLevelSenderText styles={styles}/>
    </div>
    :
    <div className='appBar2row' style={styles.appBar2row}>
    <ASLevelSenderText styles={styles}/>
    <div className="appBar2column" style={styles.appBar2column}>
      <img src={aslevelsendersnap} style={styles.revisionbank} alt=''></img>
    </div>
    </div>
    } 
    { maxRowBased ?
    <div className='appBar2row' style={styles.appBar2row}>
    <NLBashText styles={styles}/>
    <div className="appBar2column" style={styles.appBar2column}>
      <img src={NLBashsnap} style={Object.assign({},styles.revisionbank,{marginTop:"15%"})} alt=''></img>
    </div>
    </div>
    :
    <div className='appBar2row' style={styles.appBar2row}>
    <NLBashText styles={styles}/>
    <div className="appBar2column" style={styles.appBar2column}>
      <img src={NLBashsnap} style={styles.revisionbank} alt=''></img>
    </div>
    </div>
    } 
    {/*Stock Forecasting/Regression using Machine Learning/Deep Learning and LSTM's*/}
    { maxRowBased ?
    <div className='appBar2row' style={styles.appBar2row}>
    
    <div className="appBar2column" style={styles.appBar2column}>
      <img src={stockgraph} style={styles.stockgraph} alt=''></img>
    </div>
    <StockgraphText styles={styles}/>
    </div>
    :
    <div className='appBar2row' style={styles.appBar2row}>
    <StockgraphText styles={styles}/>
    <div className="appBar2column" style={styles.appBar2column}>
      <img src={stockgraph} style={styles.stockgraph} alt=''></img>
    </div>
    </div>
    } 
    {/*Digit Recognizer using Tensorflow CNN's*/}
    <div className='appBar2row' style={styles.appBar2row}>

      <div className="appBar2column" style={styles.appBar2column}>
          <h2 style={styles.projectstitle}>Digit Recognizer using Tensorflow CNN's</h2>
          <p style={Object.assign({},styles.projectstitle,{fontSize:"20px"})}><font color="808080"><b>Made Using:</b></font></p>
            <div style={styles.appBar2rowicons}>
            <img style={styles.logosmallinit} src={pythonlogosmall} alt=''></img>
            <img style={styles.logosmall} src={tensorflowlogosmall} alt=''></img>
            <img style={styles.logosmall} src={numpylogosmall} alt=''></img>
            <img style={styles.logodocker} src={dockerlogo} alt=''></img>
            <img style={Object.assign({},styles.horizontallogosmall,styles.logosmallwhitebg)} src={pandaslogosmall} alt=''></img>
            
          </div>
          <div style={styles.projectstextcont}>
            <p style={styles.projectstext}>
              The Hello World of Image classification. This project was my first attempt at learning deep learning. With the goal of learning how to analyse all possible datatypes for a more all-rounded skillset. Ranging from tabular data; to images; then text and finally audio. 
              Realizing that a model is completely useless if it can't be used in a convienent and accessible, I to learned how to deploy a model using docker. I learned the basics of creating a docker image and running docker containers in order allow for deployment, but in this case only locally. 
              Seeing that I had not yet come across a platform to deploy my projects to the cloud. Later I come across Heroku and Azure which allowed me to deploy my RESTAPIS.
            </p>
          </div>
      </div>
      <div className="appBar2column" style={styles.appBar2column}>
        <img src={digitrecogizer} style={styles.stockgraph} alt=''></img>
      </div>

    </div>


    </div>

    )
}
export default Projects;