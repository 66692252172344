
import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendarstyle.css';
import Cookies from 'universal-cookie';
import { useRef } from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Select from "react-select"
import { availabletimes } from './scheduletimes';
import HeaderBar from "./headerbarmedia"
// TODO ALTutor
// Improve design of  Altutor page
// Add time bookings
// Improve design of Altutorpayment page
// Improve design of Alpaid page
// Improve Alpaid to be safer by using statebool and Navigate.
// Improve Alpaid so that it displays what days has been booked and sends a confirmation email.
// Make long running script that checks every 30 minutes sends email 30 minutes before meeting. Also sending the email to myself to book. 
const cookies = new Cookies();
function useFirstRender() {
  
  const ref = useRef(true);
  const firstRender = ref.current;
  //cookies.set('currentdate', date.toISOString(), { path: '/altutor' });
  ref.current = false;
  return firstRender;
}

export default function ALTutor() {
  const storeSubscriptionData = async (altutordata) => {
    const responseschedule = await axios.post("https://altutor-tsw4.onrender.com/altutorschedule",altutordata)
    //console.log(responseschedule.data)
    //const responseschedule = await axios.post("https://altutor-tsw4.onrender.com/altutorschedule",{"altutor":{"clientdetails":clientdetails,"scheduleddates":isodates}})
    //console.log(responseschedule.data)
    }
  function getTimes(availabletimes,date){
    function sortObj(obj) {
        return Object.keys(obj).sort().reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
      }
    function selectdatefree(x){
     if (x.includes(date.toDateString().slice(0,3)) === true){
      return(x)

     }
     else{
      return(null)
     }
      
    }
    const datetimeselected = Object.keys(availabletimes).map(selectdatefree).filter(x => x !== null)

    const timesflattened = Array.from(new Set(Object.values(availabletimes[datetimeselected[0]]).flat(1).filter(x => {if(x !== "notavailable"){return(x)}})))
    const timesoptionsobj = {}

    timesflattened.forEach((elem, i) => {
        timesoptionsobj[elem[1]] = elem
    })
    const timesoptions = sortObj(timesoptionsobj)
    
    const sortedtimesoptions = Object.values(timesoptions).map(x => {return({value:x,label:x})})

    
    /*const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
      */
    if (sortedtimesoptions.length == 0){
      return [{label:"Not Available",value:"Not Available"}]
    }
    else if (sortedtimesoptions.length > 0){
      return sortedtimesoptions
    }
    


}
  
  //console.log(availabletimes)
  const today = new Date()
  const [date, setDate] = useState(new Date(today.getFullYear(), today.getMonth(),today.getDate()+1));
  const [dates, setDates] = useState([]);
  const [pleasescheduledate,setPleaseScheduleDate] = useState(false)
  const [clientdetails,setClientDetails] = useState({guardianname:"none",guardianemail:"none",studentname:"none",studentemail:"none"})
  const [finishclientdetails,setFinishClientDetails] = useState(false)
  const [times,setTimes] = useState([])
  const navigate = useNavigate();
  const [pleaseadddatetime,setPleaseaddDatetime] = useState(false)
  const [subject,setSubject] = useState("")
  const sessionsubject = [{"label":"GCSE Maths","value":"GCSE Maths"},{"label":"GCSE Computer Science","value":"GCSE Computer Science"},{"label":"Data Science/Machine Learning Python","value":"Data Science/Machine Learning Python"},{"label":"Backend Development Python","value":"Backend Development Python"},{"label":"Frontend Development React","value":"Frontend Development React"}]
  //console.log(date)
  //console.log()
  const firstrender = useFirstRender(date)
  if (firstrender === true){cookies.set('currentdate', date.toISOString(), { path: '/altutor' });}
  function handleAdd(e) {


    if (dates.length === times.length ){
    const newList = dates.concat(e);
    const newiso = newList.map(x => x.toISOString())
    const newSet = [...new Set(newiso)]
    const newdateset = newSet.map(x => new Date(x))
    //console.log(times)
    setDates(newdateset);}

    
  }
  function handleAddTime(e) {
    //console.log(e.label)
    if (e.label === "Not Available"){
      delete dates[dates.length - 1]
      setDates(dates.filter(x=> x !== "empty"))
      setTimes(times.filter(x => x !== "empty"))
    }
    if (dates.length > times.length && e.label !== "Not Available"){
    const newList = times.concat(e);
  
    //const newiso = newList.map(x => x.toISOString())
    //const newSet = [...new Set(newiso)]
    //const newdateset = newSet.map(x => new Date(x))
    setTimes(newList);}

  }
  function handleAddSubject(e){
    setSubject(e.label)

  }
  function RemoveDate(ind){
    delete dates[ind]
    delete times[ind]
    //console.log(dates)
    setDates(dates.filter(x=> x !== "empty"))
    setTimes(times.filter(x => x !== "empty"))

  }
  function handleChange(e) {
    let copiedClientDetails = {...clientdetails};
    copiedClientDetails[e.target.name] = e.target.value
    //console.log(copiedClientDetails)
    setClientDetails(clientdetails => ({...copiedClientDetails}))
  }
  const storescheduleddates = async () =>{
    //console.log(dates)
    if (dates === []){
        setPleaseScheduleDate(true)
    }
    else if (dates !== []){
      const isodates = dates.map(x => x.toISOString())
      const isotimes = times.map(x => x.label)
      const checkclientdetails = Object.values(clientdetails).includes("none") === true ? false : true
      const checkisodates  = isodates.length > 0 ?  true : false
  
      if (checkclientdetails === false || checkisodates === false || subject === ""){
        setFinishClientDetails(false)
        console.log("please finish client details...")
        //console.log(dates,times)
      }
      else if (checkclientdetails === true && checkisodates === true && subject !== ""){

        const altutorjson = {"altutor":{"clientdetails":clientdetails,"scheduleddates":{"guardiankey":clientdetails["guardianname"],"dates":isodates,"times":isotimes,"subject":subject}}}
       // storeSubscriptionData(altutorjson)

        navigate("/altutorpayment",{state:{altutorjson,"token":"peterpiperpickedapeper","price":15}})


      }


    }

  }

  function SelectedDatesSelect(props){
    //console.log(times[times.length - 1])
    //console.log(dates)
    if (times !== [] && times !== undefined){
    return(
      <div style={{border:"1px solid black",borderRadius:"10px",backgroundColor:"white"}}>
        <div style={{margin:"10px",marginLeft:"-20px"}}>
        <ul>
          {props.dates.map((x,ind) => {const datestring = x.toLocaleString(); return(<div key={datestring} style={{display:"flex",gap:"10px",margin:"5px"}}><li style={{backgroundColor:"#335eea",color:"black",border:"1px solid #335eea",color:"white",borderRadius:"10px",padding:"5px",}} key={datestring}>{datestring.slice(0,datestring.indexOf(","))} {times[ind] !== undefined && times[ind]["value"]}</li><button onClick={(e) =>{RemoveDate(ind)}} style={{color:"white",backgroundColor:"red",border:"1px solid red",borderRadius:"10px"}}>Remove</button></div>)})}
        </ul>
        

        </div>
        <div style={{display:"flex"}}>
          <button onClick={(e) => {storescheduleddates()}} style={{margin:"10px",borderRadius:"10px",border:"1px solid #394da0",backgroundColor:"#394da0",color:"white"}}>Submit</button>
        </div>
      </div>
    )}

  }

  
  const startdate = firstrender === true ? date : new Date(cookies.get("currentdate"))
  var amarifreetimes = getTimes(availabletimes,date)
  

  return (
    <div style={{height:window.innerHeight,backgroundColor:"#1e1e1e"}}>
      <HeaderBar checkaltutor={true}/>
      <h1 className='text-center' style={{color:"white"}}>AlTutor Booking </h1>
      <div style={{position:"relative",top:"20px"}}>
      <div style={{display:"flex",justifyContent:"center"}}>
        <div style={{display:"flex",flexDirection:"column"}}>
        <div style={{border:"1px solid black",borderRadius:"10px",backgroundColor:"white",padding:"10px"}}>
              <div style={{display:"flex"}}>
                <div style={{display:"flex",flexDirection:"column"}}>
                <p >Guardian Name:</p>
                <input name='guardianname' onChange={handleChange} >
                </input>
                </div>
                <div style={{display:"flex",flexDirection:"column"}}>
                <p >Guardian Email:</p>
                <input name='guardianemail' onChange={handleChange}>
                </input>
                </div>
              </div>
          </div>
          <div style={{border:"1px solid black",borderRadius:"10px",backgroundColor:"white"}}>
              <div style={{display:"flex",flexDirection:"row"}}>
                  <div style={{margin:"10px"}}>
                      <p>Student Name:</p>
                      <input name='studentname' onChange={handleChange}>
                      </input>
                  </div>
                  <div style={{margin:"10px"}}>
                      <p>Student Email:</p>
                      <input name='studentemail'onChange={handleChange} style={{width:"160px"}}>
                      </input>
                  </div>
                </div>
          </div>
        <Calendar 
            onChange={e => {setDate(e);handleAdd(e)}}
            value={date}
            selectRange={false}

            defaultValue={date}
            minDate={startdate}
          />
        <Select menuPosition="fixed" onChange={(e) => {handleAddTime(e)}} styles={{width:"100px"}} options={amarifreetimes} />
        <Select menuPosition="fixed" onChange={(e) => {handleAddSubject(e)}} styles={{width:"100px"}} options={sessionsubject} />

        </div>

        

        <div style={{display:"flex",flexDirection:"column"}}>
          { subject !== "" &&
          <div style={{border:"1px solid black",borderRadius:"10px",backgroundColor:"white"}}>
            <div onClick={(e) => {storescheduleddates()}} style={{margin:"10px",borderRadius:"10px",border:"1px solid #394da0",backgroundColor:"#394da0",color:"white",padding:"5px"}}>{subject}</div>    
          </div>}

            {dates !== [] && times !== [] && <SelectedDatesSelect dates={dates}/>}
        </div>
        </div>
      </div>

      <div style={{display:"flex",width:"200px",justifyContent:"center",alignItems:"center"}}>

      </div>
      {date.length > 0 ? (
        <p className='text-center'>
          <span className='bold'>Start:</span>{' '}
          {date[0].toDateString()}
          &nbsp;|&nbsp;
          <span className='bold'>End:</span> {date[1].toDateString()}
        </p>
      ) : (
        <p className='text-center'>
          <span className='bold'>Default selected date:</span>{' '}
          {date.toDateString()}
        </p>
      )}

    </div>
  );
}