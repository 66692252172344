import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function LogitTechModel({modelPath}) {
  const { nodes, materials } = useGLTF(modelPath);
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={materials.Floor}
        position={[-0.03, -0.016, 0.007]}
        scale={[13.569, 10, 10]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials.Keyboard}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_1.geometry}
        material={materials["Material.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_2.geometry}
        material={materials.Silver}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_3.geometry}
        material={materials["Material.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Keycaps.geometry}
        material={materials.Keyboard}
        position={[-3.398, 0.175, -0.534]}
        scale={0.153}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Label_keys.geometry}
        material={materials["Letter Glow"]}
        position={[-2.873, 0.303, 0.742]}
        scale={0.055}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Logitech_Logo.geometry}
        material={materials["Letter Glow"]}
        position={[2.916, 0.187, -0.918]}
        scale={0.221}
      />
      <group position={[-0.233, 0.251, -0.092]} scale={0.152}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere.geometry}
          material={materials.Keyboard}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere_1.geometry}
          material={materials["Material.001"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/Logitech Keyboard.glb");