import { useState ,useEffect} from "react";
import { useLocation } from "react-router";
import ModelCard from "./modelcard";
import HomerModel from "./Models/homermodel";
import LogitTechModel from "./Models/logittechmodel";
import logo from '../imgs/Amari-logo.svg'
import { useMediaQuery } from "@mui/material";
import { maxRowBasedquery } from "../mediamax";
import CubeModel from "./Models/cubemodel";
export default function CaesarAIEngineWorldModel(){
    const location = useLocation();
    const [modelname,setModelName] = useState(location.search.replace("?h=",""))
    const maxRowBased = useMediaQuery(maxRowBasedquery)



    return (
    <div> 
        <div style={{backgroundColor:"#1e1e1e",height:"100vh",overflow: "auto"}} >
            <div style={{position:"relative",top:"30px",left:"7.5%",width:"55%"}}>
                <a style={{cursor:"pointer"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center", position:"relative",top:maxRowBased? "0px":"50px"}}> 
                <h1 style={{color:"white"}}>CaesarAIEngineWorld - {modelname.replace("%20"," ")}</h1>
            </div>

            <div style={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative",top:"50px",marginBottom:"100px"}}>
                <div style={{width:"1000px",backgroundColor:"#002772",borderRadius:"10px"}}>
                    <div style={{padding:"50px"}}>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"80px"}}>
                        {modelname.includes("Logit") && <ModelCard title={modelname.replace("%20"," ")} description="hello world" width={maxRowBased ? "900px":"400px"} modelcard={LogitTechModel} index={0} modelPath={`https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=${modelname}`}></ModelCard>}
                        {modelname.includes("homer") && <ModelCard title={modelname.replace("%20"," ")} description="hello world" width="900px" modelcard={HomerModel} index={0} modelPath={`https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=${modelname}`}></ModelCard>}
                        {modelname.includes("Paths") && <ModelCard title={modelname.replace("%20"," ")} description="hello world" width="900px" modelcard={CubeModel} index={0} modelPath={`https://caesaraiengineworld-qqbn26mgpa-uc.a.run.app/getmodel?filename=${modelname}`}></ModelCard>}
                        </div>
                       


                    </div>

                </div>

            </div>

        </div>
        
    </div>)

}